<div fxLayout="column" class="d-flex event-left-panel" style="background-color: #ffffff;" fxFlex="20%">
    <div fxLayout="row" class="d-flex flex-row" fxLayoutAlign="center">
        <mat-accordion style="width: 100%;" multi="true">
            <mat-expansion-panel style="width: 100%;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <label class="form-control-label">
                            <span jhiTranslate="report.history.report-event-type" style="font-size: 14px; color: #575c66;">Event type</span>
                        </label>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="report-type content-font">
                    <mat-form-field>
                        <input placeholder="Szukaj" [(ngModel)] = "eventFilter" matInput>
                    </mat-form-field>
                    <ng-container *ngFor="let reportTypeItem of reportTypeItems">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                      (change)="changeReportType(reportTypeItem)"
                                      [checked]="isReportTypeSelected(reportTypeItem.key)"
                                      class="d-block"
                                      color="primary">
                            {{getReportEventTypeGroupValue(reportTypeItem.key)}}
                        </mat-checkbox>
                        <div *ngFor="let reportTypeItemChild of reportTypeItem.childs">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                      *ngIf="isReportTypeVisible(reportTypeItemChild.key) | async"
                                      (change)="changeReportType(reportTypeItemChild)"
                                      [checked]="isReportTypeSelected(reportTypeItemChild.key)"
                                      color="primary"
                                      class="ml-4 d-block"
                                      >
                            {{getReportEventTypeValue(reportTypeItemChild.key)}}
                        </mat-checkbox>
                        </div>
                    </ng-container>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel style="width: 100%;" *ngIf="isAdmin">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <label class="form-control-label">
                            <span jhiTranslate="report.users-filter-title" style="font-size: 14px; color: #575c66;">Users</span>
                        </label>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div fxLayout="column" class="content-font">
                    <mat-radio-group [(ngModel)]="selectedProjectUser" (change)="changeProjectUser()" class="d-flex flex-column">
                        <mat-radio-button [value]="null" color="primary">
                            <span jhiTranslate="report.filter-none">None</span>
                        </mat-radio-button>
                        <mat-radio-button [value]="projectUser" *ngFor="let projectUser of projectUsers" color="primary">
                            {{projectUser.userLogin}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel style="width: 100%;" [disabled]="selectedProjectUser != null" #groupsExpansionPanel *ngIf="isAdmin">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <label class="form-control-label">
                            <span jhiTranslate="report.group-filter-title" style="font-size: 14px; color: #575c66;">Groups</span>
                        </label>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <jhi-group-filter [userGroups]="groups" (select)="changeGroup($event)" fxFlex class="content-font"></jhi-group-filter>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
<mat-divider [vertical]="true"></mat-divider>
<div fxLayout="column" class="d-flex" fxFlex="80%">
    <div class="d-flex flex-row button-row justify-content-between" fxLayout="row" style="background-color: #efeff2;" fxFlex="100%">
        <div fxLayout="row" fxFlexAlign="start">
            <button mat-raised-button style="min-width: 70px" [matMenuTriggerFor]="filterMenu" (click)="filterOpen()">
                <mat-icon>filter_list</mat-icon>
                <span jhiTranslate="document.filter.search">Filtr</span>
            </button>
            <button mat-raised-button style="min-width: 70px; margin-left: 0.5rem;" (click)="export()" color="primary"
                    matTooltip="{{'report.excel.button.tooltip' | translate}}">
                <mat-icon>get_app</mat-icon>
            </button>
            <div class="view-type" fxLayout="row">
                <div fxLayout="column">
                    <button mat-flat-button (click)="viewTypeChanged(ViewType.TABLE)"
                            style="margin: 0; border-bottom-right-radius: 2px !important; border-top-right-radius: 2px !important;"
                            [ngClass]="viewType == ViewType.TABLE ? 'selected' : ''">
                        <span jhiTranslate="report.history.view-type.table">Table</span>
                    </button>
                </div>
                <mat-divider [vertical]="true"></mat-divider>
                <div fxLayout="column">
                    <button mat-flat-button (click)="viewTypeChanged(ViewType.FEED)"
                            style="margin: 0; border-bottom-left-radius: 2px !important; border-top-left-radius: 2px !important;"
                            [ngClass]="viewType == ViewType.FEED ? 'selected' : ''">
                        <span jhiTranslate="report.history.view-type.feed">Feed</span>
                    </button>
                </div>
            </div>
            <mat-menu #filterMenu="matMenu" class="filter-menu">
                <div fxFlex fxLayout="column" fxLayoutAlign="center" (click)="$event.stopPropagation();">
                    <div class="filter-item" fxLayout="column">
                        <mat-form-field>
                            <input
                                name="searchDateFrom"
                                matInput
                                [matDatepicker]="searchDateFromPicker"
                                [formControl]="dateFromControl"
                                placeholder="{{'report.date-from' | translate}}"
                                (click)="$event.stopPropagation();">
                            <mat-datepicker-toggle matSuffix [for]="searchDateFromPicker"></mat-datepicker-toggle>
                            <mat-datepicker #searchDateFromPicker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field>
                            <input
                                name="searchDateTo"
                                matInput
                                [matDatepicker]="searchDateToPicker"
                                [formControl]="dateToControl"
                                placeholder="{{'report.date-to' | translate}}"
                                (click)="$event.stopPropagation();">
                            <mat-datepicker-toggle matSuffix [for]="searchDateToPicker"></mat-datepicker-toggle>
                            <mat-datepicker #searchDateToPicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="filter-item mt-2" fxLayout="column">
                        <div align="right">
                            <button mat-raised-button color="primary" (click)="search()">
                                <span class="fa fa-search"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </mat-menu>
        </div>
        <div class="project-select" *ngIf="projectChangeAvailable">
            <ng-select [(ngModel)]="selectedCar" (change)="onProjectSelectionChange($event)" style="width:300px"
                       [items]="filteredProjects | async" bindLabel="name" appearance="underline" bindValue="id">
            </ng-select>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" class="d-flex flex-row button-row" *ngIf="hasFilters()" style="background-color: #efeff2;">
            <mat-chip-list>
                <mat-chip *ngIf="reportEventCriteriaDto.dateFrom" (removed)="chipsRemoveDateFrom()">
                    <span jhiTranslate="report.date-from">Date from</span>
                    <span> {{reportEventCriteriaDto.dateFrom | date: 'yyyy-MM-dd'}}</span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip *ngIf="reportEventCriteriaDto.dateTo" (removed)="chipsRemoveDateTo()">
                    <span jhiTranslate="report.date-to">Date to</span>
                    <span> {{reportEventCriteriaDto.dateTo | date: 'yyyy-MM-dd'}}</span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <mat-chip color="primary" (removed)="chipsRemoveAll()">
                    <span jhiTranslate="report.clear-filters">Clear filters</span>
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
    </div>
    <div fxLayout="row" class="p-3 overview-content" *ngIf="viewType == ViewType.FEED">
        <div fxLayout="column" fxFlex="100%">
            <div fxLayout="row" class="d-flex flex-row">
                <div fxLayout="column" fxFlex="100%">
                    <div *ngFor="let reportEvent of reportEvents; let idx = index">
                        <div fxLayout="row" class="d-flex flex-row" *ngIf="idx == 0 || lastIsDifferentDay(idx)">
                            <span class="report-event-feed-date" [ngClass]="idx == 0 ? '' : 'mt-4'">
                                {{reportEvent.date | date: 'dd EEEE, yyyy'}}
                            </span>
                        </div>
                        <div fxLayout="row" class="d-flex flex-row">
                            <mat-card fxFlex="98%" class="report-event-feed">
                                <mat-card-header>
                                    <mat-card-title>
                                        <b>{{reportEvent.userFirstName}} {{reportEvent.userLastName}}</b>
                                        <b style="color: red;"> {{getReportEventTypeValue(reportEvent.type)}} </b>
                                        <span
                                            style="color: #666666; text-decoration: underline;">{{reportEvent.description}}</span>
                                        {{reportEvent.details}}
                                    </mat-card-title>
                                    <mat-card-subtitle class="mt-1">
                                        {{reportEvent.date | date: 'yyyy-MM-dd HH:mm:ss'}}, {{getFormattedTimezone()}}
                                    </mat-card-subtitle>
                                </mat-card-header>
                            </mat-card>
                        </div>
                    </div>
                    <div fxLayout="row" class="d-flex flex-row">
                        <mat-paginator [length]="totalItems"
                                       [pageSize]="pageable.size"
                                       [pageSizeOptions]="pageSizeOptions"
                                       (page)="pageSwitch($event)"
                                       fxFlex="98%">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="row" class="d-flex flex-row pl-3 pr-3 pb-3 overview-content" *ngIf="viewType == ViewType.TABLE">
        <div fxLayout="column" fxFlex="100%">
            <mat-table [dataSource]="dataSource" id="report-event-table">
                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef><b>Id</b>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="color: rgba(0, 0, 0, 0.54);">
                        {{ element.id}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="author">
                    <mat-header-cell *matHeaderCellDef><b jhiTranslate="report.history.author">Author</b>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="color: rgba(0, 0, 0, 0.54);">
                        <div class="block" [matTooltip]="element.userId">
                            <b style="color: black;">{{element.userFirstName}} {{element.userLastName}}</b><br/>
                            <span>{{element.userEmail}}</span>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef><b jhiTranslate="report.history.action">Action</b>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="color: rgba(0, 0, 0, 0.54);">
                        <div class="block" [matTooltip]="element.type">
                            <b style="color: black;">{{getReportEventTypeValue(element.type)}}</b><br/>
                            <span>{{getProjectName(element.projectId)}}</span>
                        </div>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef><b jhiTranslate="report.history.description">Description</b>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="color: rgba(0, 0, 0, 0.54);">
                        <b title="{{element.description}}">{{element.description}}</b></mat-cell>
                </ng-container>
                <ng-container matColumnDef="details">
                    <mat-header-cell *matHeaderCellDef>
                        <b jhiTranslate="report.history.details" style="width: 100%; text-align: center">Details</b>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="pl-4 pr-4">
                        <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%;">
                            <button
                                mat-icon-button
                                matTooltip="{{element.details}}"
                                matTooltipClass="detail-tooltip"
                                color="primary"
                                class="small-info"
                                [disabled]="!element.details">
                                <mat-icon style="font-size: 18px">more_horiz</mat-icon>
                                <!--<i class="material-icons md-18" aria-label="favorite">more_horiz</i>-->
                            </button>
                        </div>

                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef style="width: 400px;"><b jhiTranslate="report.history.date-and-time">Date and
                        time</b></mat-header-cell>
                    <mat-cell *matCellDef="let element" style="color: rgba(0, 0, 0, 0.54);">
                        <b [matTooltip]="element.date">{{element.date | date: 'yyyy-MM-dd HH:mm:ss'}}, {{getFormattedTimezone()}}</b></mat-cell>
                </ng-container>
                <ng-container matColumnDef="blockchain">
                    <mat-header-cell *matHeaderCellDef><b></b></mat-header-cell>
                    <mat-cell *matCellDef="let element" style="color: rgba(0, 0, 0, 0.54);width: 400px; text-align-right;">
                        <button style="background: white" mat-mini-fab [matMenuTriggerFor]="blockchainMenu" (click)="setSelectedBlockchainItem(element)" [disabled]="element.blockchainStatus !== BlockchainConfirmationStatus.CONFIRMED" *ngIf="element.checksum" matTooltip="{{getBlockchainDetailsTooltip(element)}}">
                            <i class="fa fa-link" aria-hidden="true" style="cursor: pointer; color: {{getBlockchainIconColor(element)}};"></i>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

            </mat-table>

            <mat-paginator [length]="totalItems"
                           [pageSize]="pageable.size"
                           [pageSizeOptions]="pageSizeOptions"
                           (page)="pageSwitch($event)"
                           style="background: #efeff2">
            </mat-paginator>
        </div>
    </div>

    <mat-menu #blockchainMenu="matMenu">
        <button mat-menu-item (click)="this.onOpenBlockchainExplorer(selectedBlockchainItem)">
            <mat-icon>open_in_new</mat-icon>
            <span jhiTranslate="report.blockchain.open-in-explorer">Otwórz w Blockchain Explorerze</span>
        </button>
        <button mat-menu-item (click)="this.onCopyBlockchainDataClick(selectedBlockchainItem)">
            <mat-icon>content_copy</mat-icon>
            <span jhiTranslate="report.blockchain.copy-identifier">Skopiuj identyfikator</span>
        </button>
    </mat-menu>
</div>
