<div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start" style="min-height: 60px">

        <div fxLayout="column" fxLayoutAlign="end" fxFlex="90">
            <div style="margin-bottom: 10px"><span style="font-size: 18px;">{{data?.title}}</span></div>
        </div>
        <div fxLayout="column" fxLayoutAlign="start" fxflex>
            <button mat-icon-button (click)="close()" style="max-width: 40px; max-height: 40px" [autofocus]="false" id="clear">
                <mat-icon class="mb-1 mr-1">clear</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
    <br/>

    <div mat-dialog-content style="padding-bottom: 20px">
        <span >{{data?.text}} {{(data?.param) ? data.param : ''}}</span>
        <span><i class="fa fa-info-circle mb-1 mr-1" matTooltip="{{confirmHint}}"
                 matTooltipPosition="below"></i></span>

    </div>
    <mat-divider></mat-divider>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" color="lightgray">
        <button mat-raised-button (click)="close()" style="padding: 0 !important; min-width: 80px !important; font-size: 13px">
            <mat-icon class="mb-1 mr-1" style="font-size: 22px">clear</mat-icon>
            <span>{{(data?.closeLabel) ? data.closeLabel : cancelText}}</span>
        </button>
        <button mat-raised-button (click)="confirm()" style="padding: 0 !important; min-width: 80px !important; font-size: 13px"
                [ngStyle]="{'background-color':  getColor(), 'color': 'white' }">
            <mat-icon class="mb-1 mr-1" style="font-size: 22px">check</mat-icon>
            <span>{{(data?.confirmLabel) ? data.confirmLabel : confirmText}} </span>
        </button>
    </div>
</div>


