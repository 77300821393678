<div fxLayout="column" class="report-container">
    <div fxFlex="row">
        <mat-tab-group style="background-color: #efeff2" (selectedTabChange)="chooseReportType($event)" *ngIf="isAdmin">
            <mat-tab label="{{ 'report.navbar.overview' | translate }}" class="custom-mat-tab">
                <jhi-report-overview style="min-height: 100vh;" *ngIf="activeTab === 0"></jhi-report-overview>
            </mat-tab>
            <mat-tab label="{{ 'report.navbar.questions' | translate }}" class="custom-mat-tab">
                <jhi-report-question *ngIf="activeTab === 1"></jhi-report-question>
            </mat-tab>
            <mat-tab label="{{ 'report.navbar.history' | translate }}" class="custom-mat-tab">
                <jhi-report-event *ngIf="activeTab === 2"></jhi-report-event>
            </mat-tab>
            <mat-tab label="{{ 'report.navbar.uploaded-data' | translate }}" class="custom-mat-tab">
                <jhi-report-uploaded *ngIf="activeTab === 3" style="min-height: 100vh;"></jhi-report-uploaded>
            </mat-tab>
            <mat-tab label="{{ 'report.navbar.processing' | translate }}" class="custom-mat-tab">
                <jhi-report-processing style="min-height: 100vh;" *ngIf="activeTab === 4"></jhi-report-processing>
            </mat-tab>
            <mat-tab label="{{ 'report.navbar.archive' | translate }}" class="custom-mat-tab">
                <jhi-report-archive style="min-height: 100vh;" *ngIf="activeTab === 5"></jhi-report-archive>
            </mat-tab>
        </mat-tab-group>

    </div>
</div>
