import {Injectable} from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from '../../app.constants';

import {File} from './file.model';
import {createRequestOption} from '../../shared';
import {map} from 'rxjs/operators';

export type EntityResponseType = HttpResponse<File>;

@Injectable()
export class FileService {

    private resourceUrl =  SERVER_API_URL + 'api/files';
    private resourceSearchUrl = SERVER_API_URL + 'api/_search/files';

    constructor(private http: HttpClient) { }

    create(file: File): Observable<EntityResponseType> {
        const copy = this.convert(file);
        return this.http.post<File>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    update(file: File): Observable<EntityResponseType> {
        const copy = this.convert(file);
        return this.http.put<File>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get<File>(`${this.resourceUrl}/${id}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    query(req?: any): Observable<HttpResponse<File[]>> {
        const options = createRequestOption(req);
        return this.http.get<File[]>(this.resourceUrl, { params: options, observe: 'response' })
            .pipe(map((res: HttpResponse<File[]>) => this.convertArrayResponse(res)));
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response'});
    }

    search(req?: any): Observable<HttpResponse<File[]>> {
        const options = createRequestOption(req);
        return this.http.get<File[]>(this.resourceSearchUrl, { params: options, observe: 'response' })
            .pipe(map((res: HttpResponse<File[]>) => this.convertArrayResponse(res)));
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: File = this.convertItemFromServer(res.body);
        return res.clone({body});
    }

    private convertArrayResponse(res: HttpResponse<File[]>): HttpResponse<File[]> {
        const jsonResponse: File[] = res.body;
        const body: File[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

    /**
     * Convert a returned JSON object to File.
     */
    private convertItemFromServer(file: File): File {
        return Object.assign({}, file);
    }

    /**
     * Convert a File to a JSON which can be sent to the server.
     */
    private convert(file: File): File {
        return Object.assign({}, file);
    }
}
