<div>
    <div class="modal-header" style="max-height: 80vh;max-height: 700px; overflow-y: scroll;">
        <div class="logo2 d-flex flex-column justify-content-between">
            <img src="../../../content/images/public/secudo_watermark2.png"/>
        </div>
        <div>
            <span jhiTranslate="global.therms.title"></span>
        </div>
        <div style="width:100px;">

        </div>
    </div>
    <div class="content p-3">
        <div class="text-center" style="margin-top:-7px;">
            <p [innerHTML]="currentProject.thermContent"></p>

        </div>

        <div class="text-right mt-3">
            <button class="btn btn-primary mr-3" (click)="acceptTherms()">Zatwierdź</button>
            <button class="btn" (click)="cancel()">Odrzuć</button>
        </div>
    </div>
</div>
