import {Injectable} from '@angular/core';
import {SERVER_API_URL} from '../../app.constants';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import {saveAs} from 'file-saver';
import {DownloadObjectDto} from './dto/download-object-dto.model';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ZipRequest} from './zip-request/request-zip-status-dialog.component';

enum ZipRequestStatus {
    test
}

export enum DownloadType {
    ENCRYPTED, PDF, PDF_PASSWORD_PROTECTED, PDF_FOR_VIEWER, ORIGINAL, CSV
}

interface ZipRequestResponse {
    id: string;
    requestId: string; // :facepalm:
    requester: number;
    createdOn: Date;
    status: ZipRequestStatus;
    totalFileCount: number;
}

@Injectable()
export class UploadDownloadService {
    private commonUrl = SERVER_API_URL + 'api/';
    private deleteFilesUrl = SERVER_API_URL + 'api/deleteFiles';
    private requestFilesAsZipPath = SERVER_API_URL + 'api/request-zip-download';
    private zipLastEvent = SERVER_API_URL + 'api/zip-download/{id}/last-event';

    static parseErrorBlob(err: HttpErrorResponse): Observable<any> {
        const reader: FileReader = new FileReader();

        const obs = new Observable((observer: any) => {
            reader.onloadend = (e) => {
                observer.error(JSON.parse(<string>reader.result));
                observer.complete();
            };
        });
        reader.readAsText(err.error);
        console.error('parseErrorBlob', err.error);
        return obs;
    }

    constructor(private http: HttpClient) {
    }

    requestFilesAsZip(ids: number[], downloadType: DownloadType) {
        const params = new HttpParams().set('downloadType', DownloadType[downloadType]);

        return this.http.post<ZipRequestResponse>(this.requestFilesAsZipPath, {
            'ids': ids,
        }, {params})
            .pipe(map((v) => {
                v.requestId = v.id;
                return v;
            }));
    }

    downloadZip(files: DownloadObjectDto[], downloadType: string): Observable<HttpResponse<Blob>> {
        const params = new HttpParams().set('downloadType', downloadType);
        return this.http.post<Blob>(this.commonUrl + 'download-files',
            files,
            {params, responseType: 'blob' as 'json', observe: 'response'}
        ).pipe(catchError(UploadDownloadService.parseErrorBlob));
    }


    deleteFiles(files: any[]): Promise<Object | undefined> {
        return this.http.post(this.deleteFilesUrl, files).toPromise();
    }

    getZipLastEvent(id: string) {
        return this.http.get<ZipRequest>(this.zipLastEvent.replace('{id}', id))
            .pipe(map((v) => {
                v.id = v.requestId;
                return v;
            }));
    }
}

export const saveFile = (blobContent: Blob, fileName: string) => {
    const blob = new Blob([blobContent], {type: 'application/octet-stream'});
    console.log('blob created:' + fileName);
    saveAs(blob, fileName);
    console.log('blob saved');
};

export const getFileNameFromResponseContentDisposition = (res: HttpResponse<any>) => {
    const contentDisposition = res.headers.get('content-disposition') || '';
    const matches = /filename=([^;]+)/ig.exec(contentDisposition);
    const fileName = (matches[1] || 'untitled').trim();
    return fileName.replace(/['"]+/g, '');
};

export const getFileNameFromResponseContentDispositionBase64 = (res: HttpResponse<any>) => {
    const contentDisposition = res.headers.get('content-disposition') || '';
    const matches = /filename=([^;]+)/ig.exec(contentDisposition);
    const fileName = (matches[1] || 'untitled').trim();

    return b64DecodeUnicode(fileName);
};


function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}


/*

export const getFileNameFromResponseContentDispositionUTF8 = (res: HttpResponse<any>) => {
    const contentDisposition = res.headers.get('content-disposition') || '';
    const matches = /filename*=([^;]+)/ig.exec(contentDisposition);
    let fileName = (matches[1] || 'untitled').trim();
    fileName = fileName.replace('UTF-8\'\'','');
    return fileName.replace(/['"]+/g, '');
};
*/

export const getFileNameFromResponseContentDispositionUTF8 = (res: HttpResponse<any>) => {
    const contentDisposition = res.headers.get('content-disposition') || '';
    console.log('contentDeposition:' + contentDisposition);
    const matches = /filename[*]=([^;]+)/ig.exec(contentDisposition);
    let fileName = (matches[1] || 'untitled').trim();
    fileName = fileName.replace('UTF-8\'\'', '');
    return fileName.replace(/['"]+/g, '');
};




