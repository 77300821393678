import {AfterViewChecked, Component, OnInit, ViewChild} from '@angular/core';

import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {JhiAlertService} from '@upside-cloud/ng-jhipster';
import {UIChart} from 'primeng/chart';
import {ReportService} from '../report.service';
import {ReportOverviewCriteriaDto} from './report-overview-criteria-dto.model';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {OverviewReportProjectDto} from './dto/overview-report-project-dto.model';
import {ReportOverviewVM} from './report-overview-vm.model';
import {formatColor} from '../../shared';
import {AlertService} from "@shared/alert/alert.service";

@Component({
    selector: 'jhi-report-overview',
    templateUrl: './report-overview.component.html',
    styleUrls: ['../report.scss']
})
export class ReportOverViewComponent implements OnInit, AfterViewChecked {
    fileTypeChartData: any;
    usersData: any;
    mostViewedQuestionsData: any;
    mostViewedDocumentsData: any;
    mostSearchedDocumentsData: any;
    overviewReportProjectDto: OverviewReportProjectDto;

    documentTypeReportItem: any[];
    usersReportItem: any[];
    mostViewedQuestionsItem: any[];
    mostViewedDocumentsItem: any[];
    mostSearchedDocumentsItem: any[];

    usersChartOptions: any;
    mostViewedQuestionsOptions: any;
    mostViewedDocumentsOptions: any;
    mostSearchedDocumentsOptions: any;

    reportOverviewCriteriaDto: ReportOverviewCriteriaDto;
    reportOverviewCriteriaDtoTmp: ReportOverviewCriteriaDto;

    @ViewChild('documentTypeChart') documentTypeChart: UIChart;
    @ViewChild('usersChart') usersChart: UIChart;
    @ViewChild('mostViewedQuestionsChart') mostViewedQuestionsChart: UIChart;
    @ViewChild('mostViewedDocumentsChart') mostViewedDocumentsChart: UIChart;
    @ViewChild('mostSearchedDocumentsChart') mostSearchedDocumentsChart: UIChart;

    visit = true;

    constructor(
        private alertService: AlertService,
        private reportService: ReportService,
        private translateService: TranslateService
    ) {
    }

    ngOnInit() {
        this.reportOverviewCriteriaDto = new ReportOverviewCriteriaDto();
        this.reportOverviewCriteriaDto.dateFrom = moment().utc();
        this.reportOverviewCriteriaDto.dateFrom = this.reportOverviewCriteriaDto.dateFrom
            .year(this.reportOverviewCriteriaDto.dateFrom.get('year') - 1);
        this.reportOverviewCriteriaDto.dateTo = moment().utc();

        this.usersChartOptions = {
            // scales: {
            //     xAxes: [{stacked: true}],
            //     yAxes: [{stacked: true}],
            // },
            showTooltips: true,
            indexAxis: 'y'
        };

        this.mostViewedQuestionsOptions = {
            indexAxis: 'y'
        };

        this.mostViewedDocumentsOptions = {
            plugins: {
                legend: {
                    display: false // Hides the legend entirely
                }
            },
            indexAxis: 'y'
        };

        this.mostSearchedDocumentsOptions = {
            plugins: {
                legend: {
                    display: false // Hides the legend entirely
                }
            },
            indexAxis: 'y'
        };

        this.usersData = {
            datasets: [
                {
                    backgroundColor: '#42A5F5',
                    borderColor: 'white'
                },
                {
                    backgroundColor: '#9CCC65',
                    borderColor: 'white'
                }
            ]
        };

        this.mostViewedQuestionsData = {
            datasets: [
                {
                    backgroundColor: '#f44336'
                },
                {
                    backgroundColor: '#9CCC65'
                },
            ]
        };

        this.mostViewedDocumentsData = {
            datasets: [
                {
                    backgroundColor: [
                        'rgba(213, 0, 0, 0.5)',
                        'rgba(197, 17, 98, 0.5)',
                        'rgba(98, 0, 234, 0.5)',
                        'rgba(41, 98, 255, 0.5)',
                        'rgba(0, 184, 212, 0.5)',
                        'rgba(0, 200, 83, 0.5)',
                        'rgba(174, 234, 0, 0.5)',
                        'rgba(255, 171, 0, 0.5)',
                        'rgba(255, 61, 0, 0.5)',
                        'rgba(46, 39, 35, 0.5)'
                    ],
                    borderColor: [
                        'rgba(213, 0, 0)',
                        'rgba(197, 17, 98)',
                        'rgba(98, 0, 234)',
                        'rgba(41, 98, 255)',
                        'rgba(0, 184, 212)',
                        'rgba(0, 200, 83)',
                        'rgba(174, 234, 0)',
                        'rgba(255, 171, 0)',
                        'rgba(255, 61, 0)',
                        'rgba(46, 39, 35)'
                    ],
                },
            ]
        };

        this.mostSearchedDocumentsData = {
            datasets: [
                {
                    backgroundColor: [
                        'rgba(213, 0, 0, 0.5)',
                        'rgba(197, 17, 98, 0.5)',
                        'rgba(170, 0, 255, 0.5)',
                        'rgba(98, 0, 234, 0.5)',
                        'rgba(48, 79, 254, 0.5)',
                        'rgba(41, 98, 255, 0.5)',
                        'rgba(0, 145, 234, 0.5)',
                        'rgba(0, 184, 212, 0.5)',
                        'rgba(0, 191, 165, 0.5)',
                        'rgba(0, 200, 83, 0.5)',
                        'rgba(100, 221, 23, 0.5)',
                        'rgba(174, 234, 0, 0.5)',
                        'rgba(255, 214, 0, 0.5)',
                        'rgba(255, 171, 0, 0.5)',
                        'rgba(255, 109, 0, 0.5)',
                        'rgba(255, 61, 0, 0.5)',
                        'rgba(62, 39, 35, 0.5)',
                        'rgba(46, 39, 35, 0.5)',
                        'rgba(30, 39, 35, 0.5)',
                        'rgba(14, 39, 35, 0.5)'
                    ],
                    borderColor: [
                        'rgba(213, 0, 0)',
                        'rgba(197, 17, 98)',
                        'rgba(170, 0, 255)',
                        'rgba(98, 0, 234)',
                        'rgba(48, 79, 254)',
                        'rgba(41, 98, 255)',
                        'rgba(0, 145, 234)',
                        'rgba(0, 184, 212)',
                        'rgba(0, 191, 165)',
                        'rgba(0, 200, 83)',
                        'rgba(100, 221, 23)',
                        'rgba(174, 234, 0)',
                        'rgba(255, 214, 0)',
                        'rgba(255, 171, 0)',
                        'rgba(255, 109, 0)',
                        'rgba(255, 61, 0)',
                        'rgba(62, 39, 35)',
                        'rgba(46, 39, 35)',
                        'rgba(30, 39, 35)',
                        'rgba(14, 39, 35)'
                    ],
                },
            ]
        };

        this.loadAll();
    }

    ngAfterViewChecked() {
        this.usersData.datasets[1].label = this.translateService.instant('report.overview.users-chart.invited');
        this.usersData.datasets[0].label = this.translateService.instant('report.overview.users-chart.logged');
        this.mostViewedQuestionsData.datasets[0].label = this.translateService.instant('report.overview.most-viewed-questions-chart.views-count');
        this.mostViewedQuestionsData.datasets[1].label = this.translateService.instant('report.overview.most-viewed-questions-chart.answers-count');
    }

    loadAll() {
        this.reportService.findOverview(this.reportOverviewCriteriaDto, this.visit).subscribe(
            (res: ReportOverviewVM) => {
                this.overviewReportProjectDto = res.overviewReportProject;
                this.documentTypeReportItem = res.overviewReportFileTypes;
                this.usersReportItem = res.overviewReportUsers;
                this.mostViewedQuestionsItem = res.overviewReportQuestionViews;
                this.mostViewedDocumentsItem = res.overviewReportDocumentViews;
                this.mostSearchedDocumentsItem = res.overviewReportDocumentLookups;

                this.loadDocumentTypeReport();
                this.loadLoggedUsersReport();
                this.loadMostViewedQuestions();
                this.loadMostViewedDocuments();
                this.loadMostSearchedDocumentsData();
                this.visit = false;
            },
            (res: HttpErrorResponse) => this.onError(res.message)
        );
    }

    clear() {
        this.reportOverviewCriteriaDto = new ReportOverviewCriteriaDto();
        this.loadAll();
    }

    loadDocumentTypeReport() {
        this.fileTypeChartData = {datasets: [{}]};

        this.fileTypeChartData.labels = this.documentTypeReportItem.map((reportItem) => reportItem.fileType);
        this.fileTypeChartData.datasets[0].data = this.documentTypeReportItem.map((reportItem) => reportItem.count);

        this.fileTypeChartData.datasets[0].backgroundColor = [];
        this.fileTypeChartData.datasets[0].borderColor = [];

        for (let i = 0; i < this.fileTypeChartData.labels.length; i++) {
            const fileFormat = this.fileTypeChartData.labels[i];
            this.fileTypeChartData.datasets[0].backgroundColor[i] = formatColor(fileFormat);
            this.fileTypeChartData.datasets[0].borderColor[i] = 'white';
        }

        this.documentTypeChart.refresh();
    }

    loadLoggedUsersReport() {
        this.usersData.labels = this.usersReportItem.map((reportItem) => reportItem.groupName);
        this.usersData.datasets[0].data = this.usersReportItem.map((reportItem) => reportItem.loggedCount);
        this.usersData.datasets[1].data = this.usersReportItem.map((reportItem) => reportItem.allCount - reportItem.loggedCount);

        this.usersChart.refresh();
    }

    loadMostViewedQuestions() {
        this.mostViewedQuestionsData.labels = this.mostViewedQuestionsItem.map((reportItem) => reportItem.title);
        this.mostViewedQuestionsData.datasets[0].data = this.mostViewedQuestionsItem.map((reportItem) => reportItem.viewsCount);
        this.mostViewedQuestionsData.datasets[1].data = this.mostViewedQuestionsItem.map((reportItem) => reportItem.answersCount);

        this.mostViewedQuestionsChart.refresh();
    }

    loadMostViewedDocuments() {
        this.mostViewedDocumentsData.labels = this.mostViewedDocumentsItem.map((reportItem) => reportItem.name);
        this.mostViewedDocumentsData.datasets[0].data = this.mostViewedDocumentsItem.map((reportItem) => reportItem.count);

        this.mostViewedDocumentsChart.refresh();
    }

    loadMostSearchedDocumentsData() {
        this.mostSearchedDocumentsData.labels = this.mostSearchedDocumentsItem.map((reportItem) => reportItem.phrase);
        this.mostSearchedDocumentsData.datasets[0].data = this.mostSearchedDocumentsItem.map((reportItem) => reportItem.count);

        this.mostSearchedDocumentsChart.refresh();
    }

    onError(error) {
        this.alertService.error(error.message, null, null);
    }

    search() {
        this.reportOverviewCriteriaDto = Object.assign(new ReportOverviewCriteriaDto(), this.reportOverviewCriteriaDtoTmp);
        this.reportOverviewCriteriaDtoTmp = null;
        this.loadAll();
    }

    filterOpen() {
        this.reportOverviewCriteriaDtoTmp = Object.assign(new ReportOverviewCriteriaDto(), this.reportOverviewCriteriaDto);
    }

    export() {
        this.reportService.exportOverview(this.reportOverviewCriteriaDto);
    }

    chipsRemoveDateFrom() {
        this.reportOverviewCriteriaDto.dateFrom = null;
        this.loadAll();
    }

    chipsRemoveDateTo() {
        this.reportOverviewCriteriaDto.dateTo = null;
        this.loadAll();
    }

    chipsRemoveAll() {
        this.reportOverviewCriteriaDto = new ReportOverviewCriteriaDto();
        this.loadAll();
    }

    hasFilters() {
        return this.reportOverviewCriteriaDto.dateFrom != null
            || this.reportOverviewCriteriaDto.dateTo != null;
    }

}
