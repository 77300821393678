<ngx-material-intl-tel-input
    [preferredCountries]="preferredCountries"
    [fieldControl]="formControl"
    hidePhoneIcon = "true"
    [autoSelectCountry]="false"
    enablePlaceholder="true"
    appearance="fill"
    [textLabels]="textLabels"
    class="phone-input"
    showMaskPlaceholder="true"
    id="phoneNumber"
>
</ngx-material-intl-tel-input>


