import {Component, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { HttpResponse } from '@angular/common/http';
import {Question, QuestionRequest} from './question.model';
import {QuestionService} from './question.service';

@Injectable()
export class QuestionPopupService {
    private ngbModalRef: NgbModalRef;

    constructor(
        private modalService: NgbModal,
        private router: Router,
        private questionService: QuestionService
    ) {
        this.ngbModalRef = null;
    }

    open(component: Component, id?: number | any): Promise<NgbModalRef> {
        return new Promise<NgbModalRef>((resolve, reject) => {
            const isOpen = this.ngbModalRef !== null;
            if (isOpen) {
                resolve(this.ngbModalRef);
            }

            if (id) {
                this.questionService.find(id)
                    .subscribe((questionResponse: HttpResponse<Question>) => {
                        const question: QuestionRequest = questionResponse.body.toQuestionRequest();
                        this.ngbModalRef = this.questionModalRef(component, question);
                        resolve(this.ngbModalRef);
                    });
            } else {
                // setTimeout used as a workaround for getting ExpressionChangedAfterItHasBeenCheckedError
                setTimeout(() => {
                    const question: QuestionRequest = new QuestionRequest();
                    this.ngbModalRef = this.questionModalRef(component, question);

                    resolve(this.ngbModalRef);
                }, 0);
            }
        });
    }

    questionModalRef(component: Component, question: QuestionRequest): NgbModalRef {
        const modalRef = this.modalService.open(component, {size: 'lg', backdrop: 'static'});
        modalRef.componentInstance.question = question;
        modalRef.result.then((result) => {
            this.router.navigate([{outlets: {popup: null}}], {replaceUrl: true, queryParamsHandling: 'merge'});
            this.ngbModalRef = null;
        }, (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {replaceUrl: true, queryParamsHandling: 'merge'});
            this.ngbModalRef = null;
        });
        return modalRef;
    }
}
