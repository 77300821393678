import {AfterViewInit, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {AccountService, Principal} from '../../shared/index';
import {SecuritySettings} from './security-settings.model';

@Component({
    selector: 'jhi-security-settings',
    templateUrl: './security-settings.component.html',
    styleUrls: ['./../settings/settings.scss']

})
export class SecuritySettingsComponent implements OnInit, AfterViewInit {

    settingsAccount: any;
    securitySettings: SecuritySettings;
    phoneNumber='';
    constructor(private accountService: AccountService,
                private principal: Principal) {
        this.securitySettings = new SecuritySettings();
        this.principal.identity(true).then((account) => {
            this.settingsAccount = account;
            this.securitySettings.securityQuestion = account.securityQuestion;
            this.securitySettings.phoneNumber = account.phoneNumber;
        });


    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        // this.renderer.selectRootElement(this.elementRef.nativeElement.querySelector('#firstNameInput')).focus();
    }

}
