<div fxLayout="column" style="background-color: #efeff2; height: 100%">
    <mat-divider></mat-divider>
    <div fxLayout="row" >
        <span class="dir-name mt-2 ml-2">{{'permission.list.for' | translate}} {{selectedObject?.itemName | listDocumentName: false : 85}}</span>
    </div>
    <mat-divider></mat-divider>

    <div class="Aligner">
        <mat-table [dataSource]="dataSource" style="width: 96%">

            <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef>
<!--                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                  [checked]="selection.hasValue() && isAllSelected()"
                                  [indeterminate]="selection.hasValue() && !isAllSelected()" color="primary" [disabled]="copyCutInProgress">
                    </mat-checkbox>-->
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="$event ? selection.toggle(row) : null; onSelection($event, row)"
                                  [checked]="selection.isSelected(row)" color="primary" [disabled]="!selectedObject">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef><span jhiTranslate="permission.list.name">GROUP NAME</span></mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span>{{element.name}}</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="role">
                <mat-header-cell *matHeaderCellDef><span jhiTranslate="permission.list.role">ROLE</span></mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span>{{element.role}}</span>
                </mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
        </mat-table>
    </div>
</div>
