import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface AlertMessage {
    id?: number;
    type: string;
    message: string;
    params?: any;
    timeout?: number;
    position?: string;
    toast?: boolean;
    scoped?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    private alertId = 0;
    private alerts: AlertMessage[] = [];
    private subject = new BehaviorSubject<AlertMessage[]>([]);

    constructor() {}

    get(): Observable<AlertMessage[]> {
        return this.subject.asObservable();
    }

    success(message: string, params?: any, position?: string): AlertMessage {
        return this.addAlert({
            type: 'success',
            message,
            params,
            timeout: 5000,
            toast: true,
            position: position || 'top right'
        }, []);
    }

    error(message: string, params?: any, position?: string): AlertMessage {
        return this.addAlert({
            type: 'danger',
            message,
            params,
            timeout: 0,
            toast: true,
            position: position || 'top right'
        }, []);
    }

    warning(message: string, params?: any, position?: string): AlertMessage {
        return this.addAlert({
            type: 'warning',
            message,
            params,
            timeout: 5000,
            toast: true,
            position: position || 'top right'
        }, []);
    }

    info(message: string, params?: any, position?: string): AlertMessage {
        return this.addAlert({
            type: 'info',
            message,
            params,
            timeout: 5000,
            toast: true,
            position: position || 'top right'
        }, []);
    }

    addAlert(alertOptions: AlertMessage, extAlerts: AlertMessage[]): AlertMessage {
        const alert = this.createAlert(alertOptions);

        if (alert.toast) {
            this.alerts.push(alert);
            if (alert.timeout && alert.timeout > 0) {
                setTimeout(() => this.closeAlert(alert), alert.timeout);
            }
        } else {
            if (extAlerts) {
                this.alerts = extAlerts;
                this.alerts.push(alert);
            }
        }

        this.emitAlerts();
        return alert;
    }

    closeAlert(alert: AlertMessage): void {
        console.log('close Alert');
        const index: number = this.alerts.indexOf(alert);
        if (index !== -1) {
            this.alerts.splice(index, 1);
            this.emitAlerts();
        }
    }

    closeAlertByIndex(index: number): void {
        this.alerts.splice(index, 1);
        this.emitAlerts();
    }

    clear(): void {
        this.alerts = [];
        this.emitAlerts();
    }

    private createAlert(alertOptions: AlertMessage): AlertMessage {
        const alert: AlertMessage = {
            type: alertOptions.type,
            message: alertOptions.message,
            timeout: alertOptions.timeout,
            toast: alertOptions.toast,
            position: alertOptions.position,
            scoped: alertOptions.scoped,
            params: alertOptions.params,
            id: this.alertId++
        };
        return alert;
    }

    private emitAlerts(): void {
        this.subject.next(this.alerts);
    }
}
