import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {RouterModule} from '@angular/router';
import {VdrSharedModule} from '../shared';
import {ReportQuestionComponent} from './report-question/report-question.component';
import {reportRoute} from './report.route';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {GroupFilter} from './group-filter';
import {ReportOverViewComponent} from './report-overview/report-overview.component';
import {ReportService} from './report.service';
import {CalendarModule} from 'primeng/calendar';
import {ReportsComponent} from './reports.component';
import {ReportUploadedComponent} from './report-uploaded/report-uploaded.component';
import {ReportEventComponent} from './report-event/report-event.component';
import {ReportDocumentViewsComponent} from './report-document-views/report-document-views.component';
import {UserFilter} from './user-filter';
import {ReportSmsComponent, ReportSmsPopupComponent} from './report-sms/report-sms.component';
import {ReportArchiveComponent} from './report-archvie/report-archive.component';
import {ReportArchiveDialog} from './report-archvie/report-archive.dialog';
import {ReportArchiveDownloadComponent} from './report-archvie/report-archive-download.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ReportProcessingComponent} from './report-processing/report-processing.component';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import {ReportUploadedDialogComponent} from './report-uploaded/dialog/report-uploaded-dialog.component';
import {MatInputModule} from "@angular/material/input";
import {CustomMaterialModule} from "../material/material.module";

@NgModule({
    imports: [
        VdrSharedModule,
        RouterModule.forChild(reportRoute),
        ChartModule,
        MatProgressBarModule,
        FormsModule,
        CheckboxModule,
        CalendarModule,
        TranslateModule,
        NgSelectModule
    ],
    declarations: [
        ReportsComponent,
        ReportQuestionComponent,
        GroupFilter,
        ReportOverViewComponent,
        ReportProcessingComponent,
        ReportUploadedComponent,
        ReportEventComponent,
        ReportDocumentViewsComponent,
        UserFilter,
        ReportSmsComponent,
        ReportSmsPopupComponent,
        ReportArchiveComponent,
        ReportArchiveDialog,
        ReportArchiveDownloadComponent,
        ReportUploadedDialogComponent
    ],
    providers: [
        ReportService
    ],
    exports: [
        ReportUploadedComponent,
        GroupFilter,
        ReportUploadedDialogComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VdrReportModule {
}
