import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DirectoryVdrService} from '../../directory-vdr';
import {JhiAlertService} from '@upside-cloud/ng-jhipster';
import {AlertService} from "@shared/alert/alert.service";

export interface DirectoryDialogData {
    directoryId: number;
}

@Component({
    selector: 'create-directory-dialog',
    templateUrl: 'directory-create-dialog.html',
    styleUrls: ['./../directory-document.scss']
})
export class DirectoryCreateDialog {
    directoryName: string;
    isBusy = false;

    constructor(
        public dialogRef: MatDialogRef<DirectoryCreateDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DirectoryDialogData,
        private directoryService: DirectoryVdrService,
        private jhiAlertService: AlertService,
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    createDirectory(): void {
        this.isBusy = true;
        this.directoryService.createDirectory(this.directoryName, this.data.directoryId).toPromise()
            .then(
                () => this.dialogRef.close(this.directoryName),
                (e) => this.jhiAlertService.error('error.http.500', null, null)
            ).then((_) => this.isBusy = false);
    }
}
