<div>
    <div class="justify-content-center">
        <div class="wid100">

            <div class="alert alert-success" *ngIf="success" jhiTranslate="settings.messages.success">
                <strong>Settings saved!</strong>
            </div>

            <jhi-alert-error></jhi-alert-error>

            <form name="form" role="form" (ngSubmit)="save()" #settingsForm="ngForm" *ngIf="settingsAccount" novalidate>
                <div class="panel">
                    <h2 jhiTranslate="settings.panel.basic">Podstawowe</h2>


                    <div class="form-group">
                        <label class="form-control-label" for="email" jhiTranslate="global.form.email">Email</label>
                        <input type="email" class="form-control" id="email" name="email"
                               placeholder="{{'global.form.email.placeholder' | translate}}"
                               [(ngModel)]="settingsAccount.email" #emailInput="ngModel" email readonly>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label" for="firstName" jhiTranslate="settings.form.firstname">First
                            Name</label>
                        <input type="text" class="form-control" id="firstName" name="firstName"
                               placeholder="{{'settings.form.firstname.placeholder' | translate}}"
                               [(ngModel)]="settingsAccount.firstName" minlength=1 maxlength=50
                               #firstNameInput="ngModel" required>
                        <div *ngIf="firstNameInput.dirty && firstNameInput.invalid">
                            <small class="form-text text-danger"
                                   *ngIf="firstNameInput.errors.required"
                                   jhiTranslate="settings.messages.validate.firstname.required">
                                Your first name is required.
                            </small>
                            <small class="form-text text-danger"
                                   *ngIf="firstNameInput.errors.minlength"
                                   jhiTranslate="settings.messages.validate.firstname.minlength">
                                Your first name is required to be at least 1 character.
                            </small>
                            <small class="form-text text-danger"
                                   *ngIf="firstNameInput.errors.maxlength"
                                   jhiTranslate="settings.messages.validate.firstname.maxlength">
                                Your first name cannot be longer than 50 characters.
                            </small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="lastName" jhiTranslate="settings.form.lastname">Last
                            Name</label>
                        <input type="text" class="form-control" id="lastName" name="lastName"
                               placeholder="{{'settings.form.lastname.placeholder' | translate}}"
                               [(ngModel)]="settingsAccount.lastName" minlength=1 maxlength=50 #lastNameInput="ngModel"
                               required>
                        <div *ngIf="lastNameInput.dirty && lastNameInput.invalid">
                            <small class="form-text text-danger"
                                   *ngIf="lastNameInput.errors.required"
                                   jhiTranslate="settings.messages.validate.lastname.required">
                                Your last name is required.
                            </small>
                            <small class="form-text text-danger"
                                   *ngIf="lastNameInput.errors.minlength"
                                   jhiTranslate="settings.messages.validate.lastname.minlength">
                                Your last name is required to be at least 1 character.
                            </small>
                            <small class="form-text text-danger"
                                   *ngIf="lastNameInput.errors.maxlength"
                                   jhiTranslate="settings.messages.validate.lastname.maxlength">
                                Your last name cannot be longer than 50 characters.
                            </small>
                        </div>
                    </div>

                </div>

                <div class="panel">
                    <h2>{{'settings.panel.extended' | translate}}</h2>

                    <div class="form-group">
                        <label class="form-control-label" for="company" jhiTranslate="settings.form.company">Company
                            Name</label>
                        <input type="text" class="form-control" id="company" name="company"
                               placeholder="{{'settings.form.company' | translate}}"
                               [(ngModel)]="settingsAccount.company" minlength=1 maxlength=50 #lastNameInput="ngModel"
                               required>
                    </div>


                    <div class="form-group">
                        <label class="form-control-label" for="companyTitle" jhiTranslate="settings.form.companyTitle">Company
                            Title</label>
                        <input type="text" class="form-control" id="companyTitle" name="companyTitle"
                               placeholder="{{'settings.form.companyTitle' | translate}}"
                               [(ngModel)]="settingsAccount.companyTitle" minlength=1 maxlength=50
                               #lastNameInput="ngModel"
                               required>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label" jhiTranslate="settings.form.phoneNumber">Last
                            Name</label>
                        <app-phone-input
                            [preferredCountries]="['pl','en','de','fr']"
                            [maxLength]="15"
                            [disabled]="false"
                            [(ngModel)]="settingsAccount.phoneNumber"

                        >
                        </app-phone-input>
                    </div>


                    <div>

                        <div class="form-group">
                            <label class="form-control-label" jhiTranslate="settings.form.avatar" for="field_bytes">Bytes</label>
                            <div>
                                <img
                                    [src]="'data:' + settingsAccount.bytesContentType + ';base64,' + settingsAccount.bytes"
                                    style="max-height: 100px;" *ngIf="settingsAccount.bytes" alt="file image"/>
                                <div *ngIf="settingsAccount.bytes" class="form-text text-danger clearfix">
                                    <span class="pull-left">{{settingsAccount.bytesContentType}}
                                        , {{byteSize(settingsAccount.bytes)}}</span>
                                    <button type="button"
                                            (click)="clearInputImage('bytes', 'bytesContentType', 'fileImage')"
                                            class="btn btn-secondary btn-xs pull-right">
                                        <span class="fa fa-times"></span>
                                    </button>
                                </div>
                                <input type="file" id="file" class="inputfile"
                                       (change)="setFileData($event, settingsAccount, 'bytes', true)" accept="image/*"
                                       jhiTranslate="entity.action.addimage"/>

                                <label class="inputfile-label" for="file" jhiTranslate="global.selectFile"></label>

                            </div>
                            <input type="hidden" class="form-control" name="bytes" id="field_bytes"
                                   [(ngModel)]="settingsAccount.bytes"/>
                            <input type="hidden" class="form-control" name="bytesContentType"
                                   id="field_bytesContentType"
                                   [(ngModel)]="settingsAccount.bytesContentType"/>
                        </div>


                    </div>
                </div>

                <div class="float-right mt-3 mb-2">
                    <button type="submit" class="btn btn-primary"
                            jhiTranslate="settings.form.button">Save
                    </button>
                </div>
            </form>
        </div>
    </div>

</div>
