import './vendor';
import { SpreadsheetModule, SpreadsheetAllModule } from '@syncfusion/ej2-angular-spreadsheet';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, ErrorHandler, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {JhiEventManager} from '@upside-cloud/ng-jhipster';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthExpiredInterceptor} from './blocks/interceptor/auth-expired.interceptor';
import {ErrorHandlerInterceptor} from './blocks/interceptor/errorhandler.interceptor';
import {NotificationInterceptor} from './blocks/interceptor/notification.interceptor';
import { NgxMaterialIntlTelInputComponent } from 'ngx-material-intl-tel-input';
import { providePrimeNG } from 'primeng/config';
import Aura from '@primeng/themes/aura';
import {
    AccountService,
    httpLoaderFactory,
    Principal,
    StateStorageService,
    UserRouteAccessService,
    VdrSharedModule,
    HasAnyAuthorityDirective,
    JhiAlertComponent, JhiAlertErrorComponent, IconService
} from './shared';
import {VdrAppRoutingModule} from './app-routing.module';
import {VdrHomeModule} from './home';
import {VdrAdminModule} from './admin/admin.module';
import {VdrAccountModule} from './account/account.module';
import {VdrReportModule} from './report/report.module';
import {VdrEntityModule} from './entities/entity.module';
import {PaginationConfig} from './blocks/config/uib-pagination.config';
import {ActiveMenuDirective,
ExcelViewerComponent, ExcelDialogRouteComponent,
WordViewerComponent, WordDialogRouteComponent,
ErrorComponent , FooterComponent,
HelpComponent, MainComponent, NavbarComponent, PageRibbonComponent, ProfileService} from './layouts';
import {NgxSpinnerModule} from 'ngx-spinner';

import { DocumentEditorAllModule, DocumentEditorContainerAllModule } from '@syncfusion/ej2-angular-documenteditor';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {TimeZoneInterceptor} from './blocks/interceptor/timezone.interceptor';
import {BlockUIModule} from 'primeng/blockui';
import {BackendUnavailableInterceptor} from './blocks/interceptor/backend-unavailable.interceptor';
import {NoCachingInterceptor} from './blocks/interceptor/no-caching-interceptor';
import {CardModule} from 'primeng/card';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {SocketIoConfig, SocketIoModule} from 'ngx-socket-io';
import {SafePipeModule} from 'safe-pipe';
import {
    CompositePropagatorModule,
    OpenTelemetryConfig,
    OpenTelemetryInterceptorModule,
    OTEL_CUSTOM_SPAN,
    OTEL_INSTRUMENTATION_PLUGINS,
    OtelColExporterModule,
    OtelWebTracerModule
} from '@jufab/opentelemetry-angular-interceptor';
import {DiagLogLevel} from '@opentelemetry/api';
import {XMLHttpRequestInstrumentation} from '@opentelemetry/instrumentation-xml-http-request';
import {DocumentLoadInstrumentation} from '@opentelemetry/instrumentation-document-load';
import {UserInteractionInstrumentation} from '@opentelemetry/instrumentation-user-interaction';
import {LongTaskInstrumentation} from '@opentelemetry/instrumentation-long-task';
import {FetchInstrumentation} from '@opentelemetry/instrumentation-fetch';
import {UserSpan} from './shared/telemetry/user-span';
import {faroInitializer} from './faro-initializer';
import {GlobalErrorHandler} from '../global-error-handler';
import {PhoneInputComponent} from "./account/settings/user-settings/phone-input/phone-input.component";


declare const IS_PRODUCTION: boolean;

// tslint:disable-next-line
export function socketIoConfigGenerator(): SocketIoConfig {
    return {url: ':7312', options: {autoConnect: false, withCredentials: true, transports: ['websocket'], reconnectionDelay: 100, reconnectionDelayMax: 500}};
}

export function initIcons(iconService: IconService) {
    return () => iconService.init();
}


const socketIoConfig: SocketIoConfig = socketIoConfigGenerator();

export function otelConfigGenerator(): OpenTelemetryConfig {
    return {
        commonConfig: {
            console: false, // Display trace on console (only in DEV env)
            production: true, // Send Trace with BatchSpanProcessor (true) or SimpleSpanProcessor (false)
            logBody: true,
            logLevel: DiagLogLevel.ALL,

            serviceName: 'VDR-Angular', // Service name send in trace
            probabilitySampler: '1',
        },
        batchSpanProcessorConfig: {
            maxExportBatchSize: '10',
            scheduledDelayMillis: '60000',
            exportTimeoutMillis: '320000'
        },
        otelcolConfig: {
            url: 'https://secudo.solutions/otel/v1/traces', // URL of opentelemetry collector
        },
    };

}

const otelConfig = otelConfigGenerator();


const PROD_IMPORTS = [
    OpenTelemetryInterceptorModule.forRoot(otelConfig),
    OtelWebTracerModule.forRoot(otelConfig),
    OtelColExporterModule,
    CompositePropagatorModule,
    SocketIoModule.forRoot(socketIoConfig),
];

const PROD_PROVIDERS =  [{
        provide: OTEL_INSTRUMENTATION_PLUGINS,
        useValue: [
            new XMLHttpRequestInstrumentation(),
            new DocumentLoadInstrumentation(),
            new UserInteractionInstrumentation(),
            new LongTaskInstrumentation(),
            new FetchInstrumentation({
                ignoreNetworkEvents: false
            })]
    }, {provide: OTEL_CUSTOM_SPAN, useClass: UserSpan},
    {
        provide: APP_INITIALIZER,
        useFactory: faroInitializer,
        deps: [Principal], // <-- Add your dependencies here
        multi: true,
    }]

@NgModule({
    imports: [
        BrowserModule,
        VdrSharedModule,
        BrowserAnimationsModule,
        VdrAppRoutingModule,
        NgxWebstorageModule.forRoot({prefix: 'jhi', separator: '-'}),
        VdrHomeModule,
        VdrAdminModule,
        VdrReportModule,
        VdrAccountModule,
        VdrEntityModule,
        SpreadsheetAllModule,
        SpreadsheetModule,
        DocumentEditorAllModule,
        DocumentEditorContainerAllModule,
        MatMomentDateModule,
        NgxSpinnerModule,
        BlockUIModule,
        CardModule,
        SafePipeModule,
        NgbModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ...(IS_PRODUCTION ? PROD_IMPORTS : [])
    ],
    declarations: [
        MainComponent,
        NavbarComponent,
        ErrorComponent,
        HelpComponent,
        PageRibbonComponent,
        ActiveMenuDirective,
        FooterComponent,
        JhiAlertComponent,
        JhiAlertErrorComponent,
        ExcelViewerComponent,
        ExcelDialogRouteComponent,
        WordViewerComponent,
        WordDialogRouteComponent,
    ],
    providers: [
        NgbActiveModal,
        ProfileService,
        PaginationConfig,
        UserRouteAccessService,
        {
            provide: APP_INITIALIZER,
            useFactory: initIcons,
            deps: [IconService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true,
            deps: [
                StateStorageService,
                Injector
            ]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true,
            deps: [
                JhiEventManager
            ]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NotificationInterceptor,
            multi: true,
            deps: [
                Injector
            ]
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: {
                useUtc: true
            }
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TimeZoneInterceptor,
            multi: true,
            deps: [
                Injector
            ]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NoCachingInterceptor,
            multi: true,
            deps: [
                Injector
            ]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BackendUnavailableInterceptor,
            multi: true,
            deps: [
                JhiEventManager,
                AccountService
            ]
        },providePrimeNG({
            theme: {
                preset: Aura,
                options: {
                    darkModeSelector: false // or 'none'
                }
            }
        }),
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        ...(IS_PRODUCTION ? PROD_PROVIDERS : [])
    ],
    bootstrap: [MainComponent]
})
export class VdrAppModule {
}

