import {Injectable} from '@angular/core';
import {JhiLanguageService} from '@upside-cloud/ng-jhipster';

import {Principal} from '../auth/principal.service';
import {AuthServerProvider} from '../auth/auth-session.service';
import {AccountService} from '../auth/account.service';
import {faro} from '@grafana/faro-web-sdk';
import {ProfileInfo, ProfileService} from "../../layouts";

@Injectable({providedIn: 'root'})
export class LoginService {

    constructor(private languageService: JhiLanguageService,
                private accountService: AccountService,
                private principal: Principal,
                private authServerProvider: AuthServerProvider,
                private profileService: ProfileService) {
    }

    async login(credentials) {
        await this.authServerProvider.login(credentials);
        const account = await this.principal.identity(true);
        this.profileService.getProfileInfo().then(profile => {
            if (profile.inProduction) {
                faro.api.setUser({
                    username: account?.login,
                });
            }
            ;
        });


        // zgodnie z DEAL DEAL-257 ZMIENIAMY język w account //na podstawie ustawień konta
        this.accountService.saveLanguageToAccount(credentials.langKey).subscribe((res) => {
                //  res.body
            }, (error) => {
                console.log(error);
            }
        );
    }

    logout() {
        this.authServerProvider.logout().subscribe();
        this.principal.authenticate(null);
        this.profileService.getProfileInfo().then(profile => {
            if (profile.inProduction) {
                faro.api.resetUser();
            }
        });

    }

}
