import {AfterViewInit, ChangeDetectorRef, Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {
    BeforeSaveEventArgs,
    MenuSelectEventArgs,
    SaveCompleteEventArgs,
    SpreadsheetComponent
} from '@syncfusion/ej2-angular-spreadsheet';
import {ActivatedRoute, Router} from '@angular/router';
import {PdfDocumentViewerService} from '../../account/pdf-document-viewer/pdf-document-viewer.service';
import {DocumentVdrService} from '@entities/document-vdr/document-vdr.service'
import {take, timer} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SaveOptions} from '@syncfusion/ej2-spreadsheet';
import {ItemModel} from '@syncfusion/ej2-angular-navigations'
import {MatSnackBar} from '@angular/material/snack-bar';
import {AlertService} from "@shared/alert/alert.service";
import {HttpCacheManager} from '@ngneat/cashew';

@Component({
    selector: 'jhi-excel-viewer',
    styleUrls: ['excel.viewer.component.scss'],
    templateUrl: 'excel.viewer.component.html'
})
export class ExcelViewerComponent implements OnInit, AfterViewInit {
    @ViewChild('spreadsheet', {static: false}) spreadsheetObj: SpreadsheetComponent;
    public  effectivePermissions = [];
    private isSpreadsheetReady = false;
    private pendingFile: File | null = null;
    private fileName: string = null;

    readonly saveLabel = 'Save';

    show = false;
    // todo, nie wykorzystane
    public customToolbar: ItemModel[] = [
        {text: 'Save', tooltipText: 'Save'},
        {text: 'CustomButton', tooltipText: 'Custom Action'}
    ];

    id: number;
    redirectUrl: string;

    constructor(
        private zone: NgZone,
        private route: ActivatedRoute,
        private pdfViewerService: PdfDocumentViewerService,
        private cdr: ChangeDetectorRef,
        private dialogRef: MatDialogRef<ExcelViewerComponent>,
        private router: Router,
        private documentVdrService: DocumentVdrService,
        private snackBar: MatSnackBar,
        private alertService: AlertService,
        private cacheManager: HttpCacheManager,
    ) {
    }

    ngOnInit() {
        this.route.queryParamMap.subscribe(params => {
            this.id = +params.get('id');
            this.redirectUrl = params.get('redirectUrl');
            if (this.id) {
                this.fetchExcel(this.id);
            }
        });
    }

    ngAfterViewInit() {
        this.cdr.detectChanges();
        this.onSpreadsheetCreated();
    }

    onSpreadsheetCreated() {
        console.log('Spreadsheet component created');
        this.isSpreadsheetReady = true;

        this.spreadsheetObj.hideRibbonTabs(['View', 'Data']);

        this.spreadsheetObj.hideFileMenuItems(['Save As', 'Open', 'New', 'Print']);

        /* this.spreadsheetObj.addFileMenuItems([
            {
              text: 'Save',
              iconCss: 'e-save e-icons',
              id: 'save'
            }
          ], 'New', true);*/


        if (this.pendingFile) {
            this.openFile(this.pendingFile);
            this.pendingFile = null;
        }


    }

    onOpenComplete(args: any) {
        console.log('Spreadsheet opened successfully');
        console.log('Open complete event args:', args);
        console.log('Number of sheets:', this.spreadsheetObj.sheets.length);
        if (this.spreadsheetObj.sheets.length > 0) {
            console.log('First cell value:', this.spreadsheetObj.sheets[0].rows[0].cells[0].value);
        }
    }

    private fetchExcel(id: number) {
        this.pdfViewerService.getPDFResponse(id).subscribe(res => {
            this.effectivePermissions = res.effectivePermissions;

            if (this.shouldEnableExcelSave()) {

                this.spreadsheetObj.addFileMenuItems([{text: this.saveLabel, iconCss: 'e-open e-icons'}], 'Open');

            }
            this.pdfViewerService.downloadOrigin(id).subscribe(
                (res: any) => {
                    console.log('Received response:', res);
                    if (res.body) {
                        this.loadExcelData(res.body);

                        const contentDisposition = res.headers.get('Content-Disposition');
                        this.fileName = this.getFileNameFromContentDisposition(contentDisposition);
                    } else {
                        console.error('Received null blob');
                    }
                },
                error => {
                    console.error('Error fetching Excel file:', error);
                }
            );
        });
    }

    private loadExcelData(blob: Blob) {
        console.log('Blob size:', blob.size, 'bytes');
        console.log('Blob type:', blob.type);

        const file = new File([blob], 'spreadsheet.xlsx', {type: blob.type});
        if (this.isSpreadsheetReady) {
            this.openFile(file);
        } else {
            this.pendingFile = file;
        }
    }

    openFile(file: File) {

        if (!this.spreadsheetObj) {
            console.error('Spreadsheet object is not initialized');
            return;
        }

        this.zone.run(() => {
            try {
                this.spreadsheetObj.height = '1000px';

                this.spreadsheetObj.open({file: file});
                console.log('open file');
                const dataUrl = this.readFileAsDataURL(file)
                    .then(result => {
                        console.log(result);  // Outputs: "Processed: Hello, World!"
                    })
                    .catch(error => {
                        console.error('An error occurred:', error);
                    });
                this.show = true;
                //  this.spreadsheetObj.refresh();
                //  this.cdr.detectChanges();
                this.cdr.detectChanges();
            } catch (err) {
                alert('error in opening');
                console.error('Error opening Excel file:', err);
            }
        });
    }

    beforeOpen(abc) {
        console.log(abc);
    }

    close() {
        this.dialogRef.close({id: this.id, redirectUrl: this.redirectUrl});

    }

    onBeforeSave(args: BeforeSaveEventArgs): void {
        args.needBlobData = true; // To trigger the saveComplete event.
        args.isFullPost = false;
    }


    saveComplete(args) {
        console.log('save complete');
        console.log(args.blobData);
        console.log('base64String:');
        // Convert blob data to base64 string.
        var reader = new FileReader();
        reader.readAsDataURL(args.blobData);
        reader.onloadend = function () {
            const base64String = reader.result;
            console.log(base64String);
        };
        this.handleSavedBlob(args.blobData);
    }


    fileMenuBeforeOpen(): void {
        // Because the file menu items are created dynamically, you need to perform the hide or show and enable/disable operations
        // under filemenu before open event.
        // Hiding the `Save As` and `Open` item.
        this.spreadsheetObj.hideFileMenuItems(['Save As', 'Open', 'New', 'Print']);
    }

    fileMenuItemSelect(args: MenuSelectEventArgs): void {
        const selectedButton = args.item.text;
        if (selectedButton === this.saveLabel) {
            console.log(args.item.text);
            this.saveSpreadsheetAsBlob();
        }
    }

    saveSpreadsheetAsBlob(): void {
        const saveOptions: SaveOptions = {
            saveType: 'Xlsx',
            fileName: this.fileName
        };

        this.spreadsheetObj.save(saveOptions);
    }

    handleSavedBlob(blob: Blob): void {
        // Example: Trigger a download
        const file = new File([blob], this.fileName, {type: blob.type});
        this.documentVdrService.pushFileToStorage(file, this.id).pipe(
            take(1) // Auto-unsubscribes after receiving one value
        ).subscribe((res:any) => {
            this.id = res.id;
            this.alertService.success('Document Saved');
            this.close();
        });
    }

    private getFileNameFromContentDisposition(contentDisposition: string | null): string {
        if (!contentDisposition) {
            return 'unknown_file';
        }

        const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = fileNameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
            return matches[1].replace(/['"]/g, '');
        }
        return 'unknown_file';
    }

    private readFileAsDataURL(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const result = reader.result as string;
                console.log('Full Data URL:', result);

                const base64 = result.split(',')[1];
                console.log('Base64 data:', base64);

                console.log('Base64 preview:', base64.substring(0, 100) + '...');

                resolve(result);
            };
            reader.onerror = (error) => {
                console.error('Error reading file:', error);
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    }

    shouldEnableExcelSave() {
        return this.effectivePermissions.includes('UPLOAD');
    }

}
