
<div fxLayout="column" class="d-flex event-left-panel" style="background-color: #ffffff;" fxFlex="20%">
    <div fxLayout="row" class="d-flex flex-row" fxLayoutAlign="center">
        <mat-expansion-panel style="width: 100%;" #groupsExpansionPanel expanded="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <label class="form-control-label">
                        <span jhiTranslate="report.group-filter-title" style="font-size: 14px; color: #575c66;">Groups</span>
                    </label>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <jhi-group-filter [userGroups]="groups" (select)="changeGroup($event)" [reportType]="'question'"
                              (selectedUserGroup)="selectedGroups" fxFlex class="content-font"></jhi-group-filter>
        </mat-expansion-panel>

    </div>
</div>
<mat-divider [vertical]="true"></mat-divider>
<div fxLayout="column" class="d-flex" fxFlex="80%">
    <div class="d-flex flex-row button-row" fxLayout="row" style="background-color: #efeff2;">
        <div fxLayout="row" fxFlexAlign="start" fxFlex="30%">
            <button mat-raised-button style="min-width: 70px" [matMenuTriggerFor]="filterMenu" (click)="filterOpen()">
                <mat-icon>filter_list</mat-icon>
                <span jhiTranslate="document.filter.search">Filtr</span>
            </button>
            <button mat-raised-button style="min-width: 70px; margin-left: 0.5rem;" (click)="export()" color="primary"  matTooltip="{{'report.excel.button.tooltip' | translate}}">
                <mat-icon>get_app</mat-icon>
            </button>
            <mat-menu #filterMenu="matMenu" class="filter-menu">
                <div fxFlex fxLayout="column" fxLayoutAlign="center" (click)="$event.stopPropagation();">
                    <!-- Add a form with ngModel -->
                    <form #filterForm="ngForm">
                        <div class="filter-item" fxLayout="column" *ngIf="reportQuestionsCriteriaDtoTmp">
                            <mat-form-field>
                                <input
                                    name="searchDateFrom"
                                    matInput
                                    [matDatepicker]="searchDateFromPicker"
                                    [(ngModel)]="reportQuestionsCriteriaDtoTmp.createdFrom"
                                    placeholder="{{'report.date-from' | translate}}"
                                    (click)="$event.stopPropagation();">
                                <mat-datepicker-toggle matSuffix [for]="searchDateFromPicker"></mat-datepicker-toggle>
                                <mat-datepicker #searchDateFromPicker></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field>
                                <input
                                    name="searchDateTo"
                                    matInput
                                    [matDatepicker]="searchDateToPicker"
                                    [(ngModel)]="reportQuestionsCriteriaDtoTmp.createdTo"
                                    placeholder="{{'report.date-to' | translate}}"
                                    (click)="$event.stopPropagation();">
                                <mat-datepicker-toggle matSuffix [for]="searchDateToPicker"></mat-datepicker-toggle>
                                <mat-datepicker #searchDateToPicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="filter-item mt-2" fxLayout="column">
                            <div align="right">
                                <button mat-raised-button color="primary" (click)="search()">
                                    <span class="fa fa-search"></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </mat-menu>
        </div>

    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" class="d-flex flex-row button-row" *ngIf="hasFilters()" style="background-color: #efeff2;">
        <!-- Remove mat-form-field -->
        <mat-chip-list #chipList fxFlex="98%">
            <mat-chip *ngIf="reportQuestionsCriteriaDto.createdFrom" (removed)="chipsRemoveCreatedFrom()">
                <span jhiTranslate="report.date-from">Date from</span>
                <span> {{reportQuestionsCriteriaDto.createdFrom | date: 'yyyy-MM-dd'}}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip *ngIf="reportQuestionsCriteriaDto.createdTo" (removed)="chipsRemoveCreatedTo()">
                <span jhiTranslate="report.date-to">Date to</span>
                <span> {{reportQuestionsCriteriaDto.createdTo | date: 'yyyy-MM-dd'}}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip color="primary" (removed)="chipsRemoveAll()">
                <span jhiTranslate="report.clear-filters">Clear filters</span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </div>
    <div class=" p-3 overview-docs">
        <div fxLayout="row" class="d-flex flex-row" style="background-color: #ffffff">
            <div fxLayout="column" fxFlex="98%">
                <p-chart #chart type="bar" [data]="data"></p-chart>
            </div>
        </div>
    </div>

</div>
