import {Component, EventEmitter, Injectable, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable, of as observableOf} from 'rxjs';
import {DirectoryDocumentTreeDto, DocumentSimpleDto, FlatDirectoryDocumentTree} from '../dto/directory-tree-dto.model';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {JhiEventManager}from '@upside-cloud/ng-jhipster';
import {DirectoryDocumentPermissionService} from './directory-document-permission.service';
import getIconForExtension from 'font-awesome-filetypes';
import {NgxSpinnerService} from 'ngx-spinner';
import {EventManager} from "@shared/alert/event-manager.service";

@Injectable()
export class DirectoryDocumentDatabase {
    dataChange = new BehaviorSubject<DirectoryDocumentTreeDto[]>([]);

    get data(): DirectoryDocumentTreeDto[] {
        return this.dataChange.value;
    }

    constructor(private permissionService: DirectoryDocumentPermissionService) {
    }

    public initialize() {
        this.permissionService.directoryDocumentTree().subscribe((value) => {
            this.dataChange.next(value);
        });
    }
}

export class SelectedItemDto {
    itemId: number;
    itemName: string;
    directory: boolean;

    constructor(itemId: number, itemName: string, directory: boolean) {
        this.itemId = itemId;
        this.itemName = itemName;
        this.directory = directory;
    }
}

@Component({
    selector: 'directory-document-tree',
    templateUrl: 'directory-document-tree.component.html',
    providers: [DirectoryDocumentDatabase],
    styleUrls: ['../directory-document.scss']
})
export class DirectoryDocumentTreeComponent implements OnInit{
    @Output() selectedItem = new EventEmitter<SelectedItemDto>();
    treeControl: FlatTreeControl<FlatDirectoryDocumentTree>;
    treeFlattener: MatTreeFlattener<DirectoryDocumentTreeDto, FlatDirectoryDocumentTree>;
    dataSource: MatTreeFlatDataSource<DirectoryDocumentTreeDto, FlatDirectoryDocumentTree>;
    activeNode: any;
    activeDirectory: any;

    constructor(
        public database: DirectoryDocumentDatabase,
        private eventManager: EventManager,
        private spinnerService: NgxSpinnerService
    ) {
        this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel,
            this._isExpandable, this._getChildren);
        this.treeControl = new FlatTreeControl<FlatDirectoryDocumentTree>(this._getLevel, this._isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        this.database.dataChange.subscribe((data) => {
            this.dataSource.data = data;
            this.treeControl.dataNodes.forEach((x) => {
                if (x.level === 0) {
                    this.action(x);
                    this.treeControl.expand(x);
                }
            });
            this.spinnerService.hide();
        });
    }

    ngOnInit(): void {
        this.spinnerService.show();
        this.database.initialize();
    }

    transformer = (node: DirectoryDocumentTreeDto, level: number) => {
        return new FlatDirectoryDocumentTree(!!node.children || !!node.documents, level, node.id, node.name, node.parentId, node.documents);
    }

    private _getLevel = (node: FlatDirectoryDocumentTree) => node.level;

    private _isExpandable = (node: FlatDirectoryDocumentTree) => node.expandable;

    private _getChildren = (node: DirectoryDocumentTreeDto): Observable<DirectoryDocumentTreeDto[]> => observableOf(node.children);

    hasChild = (_: number, _nodeData: FlatDirectoryDocumentTree) => _nodeData.expandable;

    action(data) {
        this.selectedItem.emit(new SelectedItemDto(data.id, data.name, true));
        this.activeNode = data;
        this.activeDirectory = data;
    }

    actionFile(data: DocumentSimpleDto) {
        this.selectedItem.emit(new SelectedItemDto(data.id, data.name, false));
        this.activeNode = data;
    }

    getMargin(level: number): string {
        return (level * 10) + 10 + 'px';
    }

    getMarginFlat(level: number): string {
        return (level * 10) + 50 + 'px';
    }

    getMarginFile(): string {
        return 10 + 'px';
    }

    getMarginFileFl(): string {
        return 50 + 'px';
    }

    getIcon(fileName: string): string {
        return 'fa ' + getIconForExtension(fileName.split('.').pop());
    }
}
