import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {VdrSharedModule} from '../../shared';
import {
    projectRoute,
    ProjectVdrComponent,
    ProjectVdrDeleteDialogComponent,
    ProjectVdrDialogComponent,
    ProjectVdrPopupComponent,
    ProjectVdrPopupService,
    ProjectVdrResolvePagingParams,
    ProjectVdrService,
} from './';
import {RadioButtonModule} from 'primeng/radiobutton';
import {VdrAccountModule} from '@admin/../account/account.module';
import {TranslateModule} from '@ngx-translate/core';
import {SafePipeModule} from 'safe-pipe';
import {ClientModule} from '@entities/client/client.module';
import {PhoneInputComponent} from "../../account/settings/user-settings/phone-input/phone-input.component";

@NgModule({
    imports: [
        VdrSharedModule,
        RouterModule.forChild(projectRoute),
        RadioButtonModule,
        VdrAccountModule,
        TranslateModule,
        SafePipeModule,
        ClientModule,
        PhoneInputComponent,
    ],
    declarations: [
        ProjectVdrComponent,
        ProjectVdrDialogComponent,
        ProjectVdrDeleteDialogComponent,
        ProjectVdrPopupComponent,

    ],
    providers: [
        ProjectVdrService,
        ProjectVdrPopupService,
        ProjectVdrResolvePagingParams,
    ],
    exports: [
            ProjectVdrComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VdrProjectVdrModule {}




