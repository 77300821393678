import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import { ClipboardModule } from 'ngx-clipboard';
import {VdrSharedModule} from '../shared';
import {VdrReportModule} from "@entities/../report/report.module";


import {
    adminState,
    AuditsComponent,
    UserMgmtComponent,
    AuditsService,
    JhiConfigurationComponent,
    JhiConfigurationService,
    JhiDocsComponent,
    JhiHealthCheckComponent,
    JhiHealthModalComponent,
    JhiHealthService,
    LogsComponent,
    LogsService,
    UserDialogComponent,
    User2Component,
    UserMgmtDetailComponent,
    UserMgmtDialogComponent,
    UserModalService,
    UserResolve,
    UserResolvePagingParams
} from './';
import {GroupDialogComponent} from './user-management/group/group-dialog.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {projectRoute} from './project-vdr';
import {VdrProjectVdrModule} from '@admin/project-vdr/project-vdr.module';

import {MonitoringComponent} from './monitoring/monitoring.component';
import {CheckboxModule} from 'primeng/checkbox';
import { QaPermissionInfoComponent } from './user-management/qa-permission-info/qa-permission-info.component';
import {UserLoggedPipe} from './user-management/user-logged-pipe';
import {TranslateModule} from '@ngx-translate/core';
import {EditorModule} from "primeng/editor";
import {ChartModule} from "primeng/chart";

@NgModule({
    imports: [
        ClipboardModule,
        VdrSharedModule,
        CheckboxModule,
        TranslateModule,
       VdrProjectVdrModule,
        EditorModule,
        ChartModule,
        VdrReportModule,
         RouterModule.forChild([...adminState, ...projectRoute])

    ],
    declarations: [
        User2Component,
        DashboardComponent,
        AuditsComponent,
        UserMgmtComponent,
        UserDialogComponent,
        GroupDialogComponent,
        UserMgmtDetailComponent,
        UserMgmtDialogComponent,
        LogsComponent,
        JhiConfigurationComponent,
        JhiHealthCheckComponent,
        JhiHealthModalComponent,
        JhiDocsComponent,
        QaPermissionInfoComponent,
        UserLoggedPipe,
        MonitoringComponent,
    ],
    providers: [
        AuditsService,
        JhiConfigurationService,
        JhiHealthService,
        LogsService,
        UserResolvePagingParams,
        UserResolve,
        UserModalService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VdrAdminModule {}
