import {Component, OnDestroy, OnInit} from '@angular/core';

import {JhiAlertService} from '@upside-cloud/ng-jhipster';
import {ReportService} from '../report.service';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {BehaviorSubject, interval, Observable, Subscription, of} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {delay} from 'rxjs/operators';
import {AlertService} from "@shared/alert/alert.service";

@Component({
    selector: 'jhi-report-processing',
    templateUrl: './report-processing.component.html',
    styleUrls: ['../report.scss', 'report-processing.scss']
})
export class ReportProcessingComponent implements OnInit, OnDestroy {
    list: ReportProcessingDTO[] = [];
    filteredList: ReportProcessingDTO[] = [];
    progressbarValue = 100;
    refreshSeconds = 60;
    criteria: {active: boolean, index: boolean} = {active: false, index: false};
    timer: Subscription;
    findProcessingProgressSubscription: Subscription;
    intervalSubscription: any;

    constructor(
        private alertService: AlertService,
        private reportService: ReportService,
        private translateService: TranslateService,
        private spinner: NgxSpinnerService,
    ) {
    }

    ngOnDestroy(): void {
        this.findProcessingProgressSubscription.unsubscribe();
        this.timer.unsubscribe();
        clearInterval(this.intervalSubscription);
    }

    ngOnInit() {
        this.fetchList();
        this.intervalSubscription = setInterval(() => this.fetchList(), this.refreshSeconds * 1000);
    }

    private fetchList() {
        this.spinner.show();
        console.log('fetchList');

        this.findProcessingProgressSubscription = this.reportService.findProcessingProgress().subscribe((result: ReportProcessingDTO[]) => {
            this.list = result;
            result.forEach((i: ReportProcessingDTO) => {
                i.startDate = moment(i.startDate);
                i.lastDate = moment(i.lastDate);
                i.duration = i.lastDate.diff(i.startDate, 'seconds');
                i.durationString = toHHMMSS(i.duration);
               // this.mockResults(i);

                });
                this.filter();
            });
    }

    private mockResults(i) {
        i.countAll += Math.floor(Math.random() * 100);
    }

    startTimer(periodSec: number) {
        console.log('seconds:' + periodSec);
        if (this.timer){
            this.timer.unsubscribe();
        }

        this.timer = interval(100).subscribe((int) => {
            const sec = int / 10;
            this.progressbarValue = 100 - (sec % periodSec) * 100 / periodSec;
        });
    }

    filter() {
        this.spinner.show();
        this.filteredList = this.list.map<ReportProcessingDTO>((item) => {
            const filterItem = Object.assign({}, item);
            if (this.criteria.index){
                filterItem.countCompleted = item.countIndexed;
            }
            return filterItem;
        }).filter((item) => item.countCompleted !== item.countAll || !this.criteria.active);
        this.startTimer(this.refreshSeconds);
        of(true).pipe(delay(1000)).subscribe(() =>   this.spinner.hide());
    }
}

export class ReportProcessingDTO {
    public id: string;
    public countAll: number;
    public countCompleted: number;
    public countIndexed: number;
    public createdBy: string;
    public startDate: Moment;
    public lastDate: Moment;
    public duration: number;
    durationString: string;
}

function toHHMMSS(seconds: number) {
    const date = new Date(0);
    date.setSeconds(seconds); // specify value for SECONDS here
    return date.toISOString().substring(11, 19);
}
