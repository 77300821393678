export interface LanguageStrings {
    save: string;
    undo: string;
    redo: string;
    image: string;
    table: string;
    hyperlink: string;
    bookmark: string;
    toc: string;
    header: string;
    footer: string;
    pageSetup: string;
    pageNumber: string;
    break: string;
    find: string;
    comments: string;
    trackChanges: string;
    localClipboard: string;
    formFields: string;
    updateFields: string;
    errors: {
        nullBlob: string;
        fetchError: string;
        openError: string;
        processError: string;
    };
}
export const translations: Record<string, LanguageStrings> = {
    'es': {
        save: 'Guardar',
        undo: 'Deshacer',
        redo: 'Rehacer',
        image: 'Imagen',
        table: 'Tabla',
        hyperlink: 'Hipervínculo',
        bookmark: 'Marcador',
        toc: 'Índice',
        header: 'Encabezado',
        footer: 'Pie de página',
        pageSetup: 'Configuración de página',
        pageNumber: 'Número de página',
        break: 'Salto2',
        find: 'Buscar',
        comments: 'Comentarios',
        trackChanges: 'Control de cambios',
        localClipboard: 'Portapapeles local',
        formFields: 'Campos de formulario',
        updateFields: 'Actualizar campos',
        errors: {
            nullBlob: 'Blob nulo recibido',
            fetchError: 'Error al obtener el archivo Word:',
            openError: 'Error al abrir el documento:',
            processError: 'Error al procesar el documento:'
        }
    },
    'fr': {
        save: 'Enregistrer',
        undo: 'Annuler',
        redo: 'Rétablir',
        image: 'Image',
        table: 'Tableau',
        hyperlink: 'Lien hypertexte',
        bookmark: 'Signet',
        toc: 'Table des matières',
        header: 'En-tête',
        footer: 'Pied de page',
        pageSetup: 'Mise en page',
        pageNumber: 'Numéro de page',
        break: 'Saut',
        find: 'Rechercher',
        comments: 'Commentaires',
        trackChanges: 'Suivi des modifications',
        localClipboard: 'Presse-papiers local',
        formFields: 'Champs de formulaire',
        updateFields: 'Mettre à jour les champs',
        errors: {
            nullBlob: 'Blob nul reçu',
            fetchError: 'Erreur lors de la récupération du fichier Word:',
            openError: 'Erreur lors de l\'ouverture du document:',
            processError: 'Erreur lors du traitement du document:'
        }
    },
    'de': {
        save: 'Speichern',
        undo: 'Rückgängig',
        redo: 'Wiederholen',
        image: 'Bild',
        table: 'Tabelle',
        hyperlink: 'Hyperlink',
        bookmark: 'Lesezeichen',
        toc: 'Inhaltsverzeichnis',
        header: 'Kopfzeile',
        footer: 'Fußzeile',
        pageSetup: 'Seiteneinrichtung',
        pageNumber: 'Seitenzahl',
        break: 'Umbruch',
        find: 'Suchen',
        comments: 'Kommentare',
        trackChanges: 'Änderungen nachverfolgen',
        localClipboard: 'Lokale Zwischenablage',
        formFields: 'Formularfelder',
        updateFields: 'Felder aktualisieren',
        errors: {
            nullBlob: 'Null-Blob empfangen',
            fetchError: 'Fehler beim Abrufen der Word-Datei:',
            openError: 'Fehler beim Öffnen des Dokuments:',
            processError: 'Fehler bei der Dokumentverarbeitung:'
        }
    },
    'pl': {
        save: 'Zapisz',
        undo: 'Cofnij',
        redo: 'Ponów',
        image: 'Obraz',
        table: 'Tabela',
        hyperlink: 'Hiperłącze',
        bookmark: 'Zakładka',
        toc: 'Spis treści',
        header: 'Nagłówek',
        footer: 'Stopka',
        pageSetup: 'Ustawienia strony',
        pageNumber: 'Numer strony',
        break: 'Podział',
        find: 'Znajdź',
        comments: 'Komentarze',
        trackChanges: 'Śledź zmiany',
        localClipboard: 'Lokalny schowek',
        formFields: 'Pola formularza',
        updateFields: 'Aktualizuj pola',
        errors: {
            nullBlob: 'Otrzymano pusty blob',
            fetchError: 'Błąd podczas pobierania pliku Word:',
            openError: 'Błąd podczas otwierania dokumentu:',
            processError: 'Błąd podczas przetwarzania dokumentu:'
        }
    },
    'cs': {
        save: 'Uložit',
        undo: 'Zpět',
        redo: 'Znovu',
        image: 'Obrázek',
        table: 'Tabulka',
        hyperlink: 'Hypertextový odkaz',
        bookmark: 'Záložka',
        toc: 'Obsah',
        header: 'Záhlaví',
        footer: 'Zápatí',
        pageSetup: 'Nastavení stránky',
        pageNumber: 'Číslo stránky',
        break: 'Zalomení',
        find: 'Najít',
        comments: 'Komentáře',
        trackChanges: 'Sledovat změny',
        localClipboard: 'Místní schránka',
        formFields: 'Formulářová pole',
        updateFields: 'Aktualizovat pole',
        errors: {
            nullBlob: 'Přijat prázdný blob',
            fetchError: 'Chyba při načítání souboru Word:',
            openError: 'Chyba při otevírání dokumentu:',
            processError: 'Chyba při zpracování dokumentu:'
        }
    }
};

