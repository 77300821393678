import {Component, OnInit} from '@angular/core';
import {Client} from './client.model';
import {ClientService} from './client.service';
import {MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {Observable} from 'rxjs';
import { HttpResponse } from '@angular/common/http';


@Component({
    templateUrl: 'clients-dialog.html',
   styleUrl:'client-dialog.scss'
})
export class ClientsDialog implements OnInit {

    dataSource = new MatTableDataSource<Client>();
    displayedColumns = ['name', 'active', 'projects'];
    clients: Client[] = [];

    constructor(private clientService: ClientService,
                public dialogRef: MatDialogRef<ClientsDialog>) {

    }

    ngOnInit() {
        this.getClients();
    }

    private getClients(): void {
        this.clientService.findAll().subscribe((response) => {
            this.clients = response.body;
            this.dataSource.data = response.body;
        });
    }

    setName(client: Client): void {
        this.clientService.updateClient(client).subscribe((response) => {

        });
    }

    setActive(client: Client, active: boolean): void {
        client.active = active;
        this.clientService.updateClient(client).subscribe((response) => {

        });
    }

    searchBy(event: any): void {
        this.dataSource.data = this.clients.filter(item => item.name.toLowerCase().includes(event.target.value)
            || item.projectNames.toLowerCase().includes(event.target.value));
    }

    close(): void {
        this.dialogRef.close();
    }
}
