import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';

import {VdrProjectVdrModule} from '@admin/project-vdr/project-vdr.module';
import {VdrDirectoryVdrModule} from './directory-vdr/directory-vdr.module';
import {VdrDocumentVdrModule} from './document-vdr/document-vdr.module';
import {VdrQuestionModule} from './question/question.module';
import {VdrAnswerModule} from './answer/answer.module';

import { VdrDocumentNoteVdrModule } from './document-note-vdr/document-note-vdr.module';
import {VdrDirectoryDocumentModule} from './directory-document/directory-document.module';

import { VdrFileModule } from './file/file.module';


@NgModule({
    imports: [
        VdrDirectoryVdrModule,
        VdrDocumentVdrModule,
        VdrQuestionModule,
        VdrAnswerModule,
        VdrDocumentNoteVdrModule,
        VdrDirectoryDocumentModule,
        VdrFileModule,
    ],
    declarations: [],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VdrEntityModule {
}
