import {Component, OnInit} from '@angular/core';
import {ReportService} from '../report.service';
import * as moment from 'moment';
import {MatTableDataSource} from '@angular/material/table';
import {ReportSmsDto} from './dto/report-sms.dto.model';
import {ReportSmsCriteriaDto} from './dto/report-sms-criteria-dto.model';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'jhi-report-sms',
    templateUrl: './report-sms.component.html',
    styleUrls: ['../report.scss']
})
export class ReportSmsComponent implements OnInit {
    reportSmsCriteriaDto: ReportSmsCriteriaDto;
    reportSmsCriteriaDtoTmp: ReportSmsCriteriaDto;

    dataSource = new MatTableDataSource<ReportSmsDto>();
    displayedColumns: string[];

    constructor(
        public activeModal: NgbActiveModal,
        private reportService: ReportService,
    ) {
    }

    ngOnInit() {
        this.reportSmsCriteriaDto = new ReportSmsCriteriaDto();
        this.reportSmsCriteriaDto.dateFrom = moment().utc();
        this.reportSmsCriteriaDto.dateFrom = this.reportSmsCriteriaDto.dateFrom.year(this.reportSmsCriteriaDto.dateFrom.get('year') - 1);
        this.reportSmsCriteriaDto.dateTo = moment().utc();

        this.displayedColumns = ['projectName', 'lastMonth', 'filtered', 'total'];

        this.dataSource.data = [];

        this.loadAll();
    }

    loadAll() {
        this.reportService.findSmsOperations(this.reportSmsCriteriaDto).subscribe((res: ReportSmsDto[]) => this.dataSource.data = res);
    }

    close() {
        this.activeModal.close();
    }

    clear() {
        this.reportSmsCriteriaDto = new ReportSmsCriteriaDto();
        this.loadAll();
    }

    search() {
        this.reportSmsCriteriaDto = Object.assign(new ReportSmsCriteriaDto(), this.reportSmsCriteriaDtoTmp);
        this.reportSmsCriteriaDtoTmp = null;
        this.loadAll();
    }

    filterOpen() {
        this.reportSmsCriteriaDtoTmp = Object.assign(new ReportSmsCriteriaDto(), this.reportSmsCriteriaDto);
    }

    chipsRemoveDateFrom() {
        this.reportSmsCriteriaDto.dateFrom = null;
        this.loadAll();
    }

    chipsRemoveDateTo() {
        this.reportSmsCriteriaDto.dateTo = null;
        this.loadAll();
    }

    chipsRemoveAll() {
        this.reportSmsCriteriaDto = new ReportSmsCriteriaDto();
        this.loadAll();
    }

    hasFilters() {
        return this.reportSmsCriteriaDto.dateFrom != null
            || this.reportSmsCriteriaDto.dateTo != null;
    }
}

@Component({
    selector: 'jhi-report-sms-popup',
    template: ''
})
export class ReportSmsPopupComponent implements OnInit {

    private ngbModalRef: NgbModalRef;

    constructor(
        private router: Router,
        private modalService: NgbModal,
    ) {
        this.ngbModalRef = null;
    }

    ngOnInit() {
        if (this.ngbModalRef) {
            return;
        }

        const modalRef = this.modalService.open(ReportSmsComponent, {size: 'lg', backdrop: 'static'});
        modalRef.result.then((result) => {
            this.router.navigate([{outlets: {popup: null}}], {replaceUrl: true, queryParamsHandling: 'merge'});
            this.ngbModalRef = null;
        }, (reason) => {
            this.router.navigate([{outlets: {popup: null}}], {replaceUrl: true, queryParamsHandling: 'merge'});
            this.ngbModalRef = null;
        });
        this.ngbModalRef = modalRef;
    }
}
