<div style="height: 100vh !important; display: flex; flex-direction: column">

    <button mat-button style="position: absolute; right: 30px; top: 0px; z-index: 100"
            matTooltip="{{ 'vdrApp.document.viewer.close' | translate }}"
            class="button-square"
            (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
    <ejs-spreadsheet #spreadsheet
                     (beforeSave)="onBeforeSave($event)"
                     (saveComplete)="saveComplete($event)"
                     (created)="onSpreadsheetCreated()"
                     (fileMenuBeforeOpen)="fileMenuBeforeOpen()"
                     (fileMenuItemSelect)="fileMenuItemSelect($event)"
                     (beforeOpen)='beforeOpen($event)'
                     openUrl='https://services.syncfusion.com/angular/production/api/spreadsheet/open'
                     saveUrl="https://services.syncfusion.com/react/production/api/spreadsheet/save"
                     allowOpen='true'
                     (openComplete)="onOpenComplete($event)"
                     height= "100vh"
                     style="flex-grow: 1; min-height: 1000px !important; width: 100%;">
    </ejs-spreadsheet>
</div>
