import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, EventEmitter, Injectable, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {BehaviorSubject, Observable, of as observableOf, ReplaySubject} from 'rxjs';
import {DirectoryTreeDto, FlatDirectoryTree} from './dto/directory-tree-dto.model';
import {DirectoryVdrService} from '../directory-vdr';
import {DirectoryDocumentViewSettingsDto} from './dto/directory-document-view-settings-dto.model';
import {DirectoryChange} from './document-list.component';
import {JhiAlertService, JhiEventManager} from '@upside-cloud/ng-jhipster';
import {DropEvent} from 'angular-draggable-droppable';
import {DownloadObjectDto} from './dto/download-object-dto.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {DirectoryDocumentService} from './directory-document.service';
import {ActivatedRoute, Router, UrlSegment} from '@angular/router';
import {EventManager} from "@shared/alert/event-manager.service";
import {AlertService} from "@shared/alert/alert.service";

@Injectable()
export class DirectoryDatabase {
    dataChange = new ReplaySubject<DirectoryTreeDto[]>();

    constructor(private directoryService: DirectoryVdrService) {
        this.initialize();
    }

    initialize() {
        this.directoryService.directoryTree().subscribe((value) => {
            this.dataChange.next(value);
        });
    }
}

export class DirDTO {
    directoryId: number;
    directoryName: string;
    indexCumulate: string;
    nodeType: string;

    constructor(directoryId: number, directoryName: string, indexCumulate: string, nodeType: string) {
        this.directoryId = directoryId;
        this.directoryName = directoryName;
        this.indexCumulate = indexCumulate;
        this.nodeType = nodeType;
    }
}

/**
 * @title Tree with flat nodes
 */
@Component({
    selector: 'jhi-directory-tree',
    templateUrl: 'directory-tree.component.html',
    providers: [DirectoryDatabase],
    styleUrls: ['directory-document.scss']
})
export class DirectoryTreeComponent implements OnChanges {
    @Output() selectedDictionary = new EventEmitter<DirDTO>();
    @Output() refreshDocuments = new EventEmitter<number>();
    @Input() refresh: number;
    @Input() ddvSettings: DirectoryDocumentViewSettingsDto;
    @Input() listDirectorySelection: number;
    treeControl: FlatTreeControl<FlatDirectoryTree>;
    treeFlattener: MatTreeFlattener<DirectoryTreeDto, FlatDirectoryTree>;
    dataSource: MatTreeFlatDataSource<DirectoryTreeDto, FlatDirectoryTree>;
    activeNode: any;
    refreshDirId: number;

    constructor(
        public database: DirectoryDatabase,
        private eventManager: EventManager,
        private alertService: AlertService,
        private spinner: NgxSpinnerService,
        private directoryDocumentService: DirectoryDocumentService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel,
            this._isExpandable, this._getChildren);
        this.treeControl = new FlatTreeControl<FlatDirectoryTree>(this._getLevel, this._isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        database.dataChange.subscribe((data) => {
            this.dataSource.data = data;
            if (!this.isNumeric(this.refreshDirId)) {
                this.treeControl.dataNodes.forEach((node) => {
                    if (node.level === 0 && node.nodeType === 'DIRECTORY') {
                        this.selectNode(node);
                    }
                });
                return;
            }

            let dirToExpand = this.refreshDirId;
            let securityCounter = 30;
            while (this.isNumeric(dirToExpand) && dirToExpand >= 0 && securityCounter > 0) {
                this.treeControl.dataNodes.forEach((x) => {
                    if (dirToExpand === x.id) {
                        this.treeControl.expand(x);
                        dirToExpand = x.parentId;
                        if (this.refreshDirId === x.id) {
                            this.activeNode = x;
                        }
                    }
                });
                securityCounter--;
            }
        });
        this.registerOnProjectChangeRefreshEvent();
        this.route.url
            .pipe() // todo delay when waiting for data
            .subscribe((segments) => this.onUrlChange(segments));
    }

    private onUrlChange(segments: UrlSegment[]) {
        const url = '/' + decodeURI(segments.join('/'));
        const matchingNodes = this.treeControl.dataNodes.filter((node) => node.fullPath === url);
        if (!matchingNodes.length) {
            return;
        }

        this.selectNode(matchingNodes[0]);
    }

    private isNumeric(value: any) {
        return Number.isFinite(value);
    }


    registerOnProjectChangeRefreshEvent() {
        this.eventManager.subscribe('directoryViewRefresh', (response) => {
            this.database.initialize();
        });
    }


    private _getLevel = (node: FlatDirectoryTree) => node.level;

    private _isExpandable = (node: FlatDirectoryTree) => node.expandable;

    private _getChildren = (node: DirectoryTreeDto): Observable<DirectoryTreeDto[]> => observableOf(node.children);

    hasChild = (_: number, _nodeData: FlatDirectoryTree) => _nodeData.expandable;

    transformer = (node: DirectoryTreeDto, level: number) => {
        return new FlatDirectoryTree(!!node.children, level, node.id, node.name, node.parentId, node.index, node.indexCumulate, node.nodeType, node.fullPath);
    };


    selectNode(node: FlatDirectoryTree) {
        this.treeControl.expand(node);
        this.activeNode = node;
    }

    navigateTo(element: FlatDirectoryTree) {
        if (!element.fullPath) {
            this.router.navigateByUrl('/files/error-no-path');
            return;
        }

        this.router.navigateByUrl('/files' + element.fullPath);
    }

    getMargin(level: number): string {
        if (level === 0) {
            return '0px';
        }
        return ((level - 1) * 10) + 10 + 'px';
    }

    getMarginFlat(level: number): string {
        if (level === 0) {
            return '0px';
        }
        return (level * 10) + 25 + 'px';
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes.listDirectorySelection) {
            const listDirSelected = changes.listDirectorySelection.currentValue;
            let listDirSelectedNode;
            this.treeControl.dataNodes.forEach((x) => {
                if (listDirSelected === x.id) {
                    listDirSelectedNode = x;
                }
            });
            this.activeNode = listDirSelectedNode;
            this.treeControl.expand(listDirSelectedNode);
        }
        if (changes.refresh) {
            const dirChange: DirectoryChange = changes.refresh.currentValue;
            if (dirChange && this.isNumeric(dirChange.id)) {
                this.refreshDirId = dirChange.id;
            } else {
                this.refreshDirId = null;
            }
            this.database.initialize();
        }
    }

    dropped({dropData}: DropEvent<any>, node) {
        if (this.activeNode && this.activeNode.id === dropData.id) {
            this.alertService.info('error.moveDocumentsError');
        } else {
            const obj = new DownloadObjectDto(dropData.id, (dropData.type === 'directory') ? 'directory' : 'document', (dropData.id === node.id));
            this.spinner.show();
            this.directoryDocumentService.copyCutDocuments([obj], false, node.id)
                .subscribe(() => {
                    this.refreshDocuments.emit(Date.now());
                    if (dropData.type === 'directory') {
                        const active = this.activeNode;
                        this.database.initialize();
                        this.selectedDictionary.emit(new DirDTO(active.id, active.name, active.indexCumulate, active.nodeType));
                        this.treeControl.expand(active);
                        this.activeNode = active;
                    }
                    this.spinner.hide();
                }, () => {
                    this.spinner.hide();
                });
        }

    }
}
