import {AfterViewChecked, Component, OnInit, ViewChild} from '@angular/core';

import {QuestionReport} from './report-question.model';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {UIChart} from 'primeng/chart';
import {Group} from '../../shared';
import {getFileNameFromResponseContentDisposition, ReportService} from '../report.service';
import {TranslateService} from '@ngx-translate/core';
import {ReportQuestionsCriteriaDto} from './dto/report-questions-criteria.dto.model';
import * as moment from 'moment';
import {saveFile} from '../../entities/directory-document/upload-download.service';
import {AlertService} from "@shared/alert/alert.service";

@Component({
    selector: 'jhi-report-question',
    templateUrl: './report-question.component.html',
    styleUrls: ['../report.scss']
})
export class ReportQuestionComponent implements OnInit, AfterViewChecked {

    reports: QuestionReport[];
    data: any;
    groups: Group[];
    selectedGroups: Group[];
    reportQuestionsCriteriaDto: ReportQuestionsCriteriaDto;
    reportQuestionsCriteriaDtoTmp: ReportQuestionsCriteriaDto;

    @ViewChild('chart') chart: UIChart;

    visit = true;

    constructor(
        private reportService: ReportService,
        private alertService: AlertService,
        private translateService: TranslateService,
    ) {
    }

    ngOnInit() {
        // Initialize both DTOs to avoid null issues
        this.reportQuestionsCriteriaDto = new ReportQuestionsCriteriaDto();
        this.reportQuestionsCriteriaDto.createdFrom = moment().utc().subtract(1, 'year');
        this.reportQuestionsCriteriaDto.createdTo = moment().utc();

        this.reportQuestionsCriteriaDtoTmp = new ReportQuestionsCriteriaDto(); // Ensure tmp is initialized
        this.reportQuestionsCriteriaDtoTmp.createdFrom = this.reportQuestionsCriteriaDto.createdFrom.clone();
        this.reportQuestionsCriteriaDtoTmp.createdTo = this.reportQuestionsCriteriaDto.createdTo.clone();

        this.data = {
            datasets: [
                {backgroundColor: '#42A5F5'},
                {backgroundColor: '#9CCC65'},
                {backgroundColor: '#f44336'}
            ]
        };
    }


    ngAfterViewChecked() {
        this.data.datasets[0].label = this.translateService.instant('report.questions.topics');
        this.data.datasets[1].label = this.translateService.instant('report.questions.answers');
        this.data.datasets[2].label = this.translateService.instant('report.questions.attachments');
    }

    loadAll() {
        this.reportQuestionsCriteriaDto.groupIds = [];
        this.selectedGroups.forEach((group) => this.reportQuestionsCriteriaDto.groupIds.push(group.id));
        this.reportService.findQuestionReport(this.reportQuestionsCriteriaDto, this.visit).subscribe(
            (res: HttpResponse<QuestionReport[]>) => {
                this.reports = res.body;
                this.data.labels = this.reports.map((reportItem) => reportItem.groupName);
                this.data.datasets[0].data = this.reports.map((reportItem) => reportItem.threads);
                this.data.datasets[1].data = this.reports.map((reportItem) => reportItem.posts);
                this.data.datasets[2].data = this.reports.map((reportItem) => reportItem.attachments);
                this.chart.refresh();
                this.visit = false;
            },
            (res: HttpErrorResponse) => this.onError(res.message)
        );
    }

    changeGroup(event) {
        this.selectedGroups = event;
        this.loadAll();
    }

    private onError(error) {
        this.alertService.error(error.message, null, null);
    }

    filterOpen() {
        // Ensure tmp is always a valid object when the menu opens
        if (!this.reportQuestionsCriteriaDtoTmp) {
            this.reportQuestionsCriteriaDtoTmp = new ReportQuestionsCriteriaDto();
        }
        this.reportQuestionsCriteriaDtoTmp = Object.assign(
            new ReportQuestionsCriteriaDto(),
            this.reportQuestionsCriteriaDto
        );
    }

    search() {
        this.reportQuestionsCriteriaDto = Object.assign(
            new ReportQuestionsCriteriaDto(),
            this.reportQuestionsCriteriaDtoTmp
        );
        this.reportQuestionsCriteriaDtoTmp = new ReportQuestionsCriteriaDto(); // Reset to a new object, not null
        this.loadAll();
    }


    export() {
        this.reportService.exportQuestions(this.reportQuestionsCriteriaDto)
            .subscribe((response: HttpResponse<Blob>) => {
                const fileName = getFileNameFromResponseContentDisposition(response);
                saveFile(response.body, fileName);
            });
    }

    chipsRemoveCreatedFrom() {
        this.reportQuestionsCriteriaDto.createdFrom = null;
        this.loadAll();
    }

    chipsRemoveCreatedTo() {
        this.reportQuestionsCriteriaDto.createdTo = null;
        this.loadAll();
    }

    chipsRemoveAll() {
        this.reportQuestionsCriteriaDto = new ReportQuestionsCriteriaDto();
        this.loadAll();
    }

    hasFilters() {
        return this.reportQuestionsCriteriaDto.createdFrom != null
            || this.reportQuestionsCriteriaDto.createdTo != null;
    }
}
