import {Injectable} from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import {SERVER_API_URL} from "../../app.constants";
import {Category} from "./category/category.model";
import {Observable} from "rxjs";

@Injectable()
export class CategoryService {

    private resourceUrl = SERVER_API_URL + 'api/category/';

    constructor(private http: HttpClient) {
    }

    addCategory(category: Category): Observable<HttpResponse<Category>> {
        return this.http.post<Category>(this.resourceUrl + 'add', category, { observe: 'response'}).pipe();
    }

    getCategories(onlyActive: boolean): Observable<HttpResponse<Category[]>> {
        return this.http.post<Category[]>(`${this.resourceUrl}find/${onlyActive}`, false, {observe: 'response'}).pipe();
    }
}
