import {Component, OnDestroy, OnInit} from '@angular/core';
import {trigger, sequence, state, animate, transition, style} from '@angular/animations';
import {AlertMessage, AlertService} from "@shared/alert/alert.service";
import { BehaviorSubject, Observable } from 'rxjs';
@Component({
    selector: 'jhi-alert',
    template: `
        <div class="alerts new-alerts" role="alert">
            <div @items *ngFor="let alert of alerts" [ngClass]="{\'alert.position\': true, \'toast\': alert.toast}">
                <ngb-alert *ngIf="alert && alert.type && alert.message" [type]="alert.type" (close)="close(alert)">
                    <div style="display: flex !important; max-height: 100%">
                        <!--
                                                <mat-icon style="color: green; line-height: 24px;">check_circle</mat-icon>
                        -->
                        <div>
                            <pre [innerHTML]="alert.message | translate:alert.params" style="line-height: 24px;font-weight: bold"></pre>
                        </div>
                    </div>
                </ngb-alert>
            </div>
        </div>`,
    animations: [
        trigger('items', [
            transition(':enter', [
                style({transform: 'scale(0.3)'}),  // initial
                animate('250ms cubic-bezier(.8, -0.6, 0.2, 1.5)',
                    style({transform: 'scale(1)', opacity: 1,

                    }))  // final
            ])
        ])
    ]
})
export class JhiAlertComponent implements OnInit, OnDestroy {
    alerts: AlertMessage[];

    constructor(private alertService: AlertService) {
    }

    ngOnInit() {
        this.alertService.get().subscribe((alerts) => {
            this.alerts = alerts;
            console.log('alerts:'+this.alerts.length);
        });
    }

    ngOnDestroy() {
        this.alertService.clear();
    }

    protected  close(alert: AlertMessage) {
        this.alertService.closeAlert(alert);
    }

}
