import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {UIChart} from "primeng/chart";
import {ReportService} from "../../report";
import {TranslateService} from "@ngx-translate/core";
import {ProjectVdrComponent} from "@admin/project-vdr"
@Component({
    selector: 'jhi-monitoring',
    templateUrl: './monitoring.component.html',
    styleUrls: ['./monitoring.scss']
})
export class MonitoringComponent implements OnInit{
    type: number;
    fileTypeChartData = {
        labels :[this.translateService.instant('report.projects.active'),
            this.translateService.instant('report.projects.completed')],
        datasets: [
            {
                data: [2,1],
                backgroundColor: ['#42A5F5','#9CCC65']
            }        ]
    };

    options = {
        responsive: false,
        maintainAspectRatio: false
    };


    @ViewChild('projectChart') projectChart: UIChart;

    constructor(private reportService: ReportService,
                private translateService: TranslateService) {

        this.reportService.findProjects().subscribe(res => {
            this.fileTypeChartData.datasets[0].data[0] = res.activeProjects;
            this.fileTypeChartData.datasets[0].data[1] = res.closedProjects;
            if (this.projectChart) {
                this.projectChart.refresh();
            }
        })

    }

    ngOnInit(): void {

    this.type = 0;
        this.translateService.onLangChange.subscribe(() => {
            this.fileTypeChartData.labels = [this.translateService.instant('report.projects.active'),
                this.translateService.instant('report.projects.completed')];

            if (this.projectChart) {
                this.projectChart.refresh();
            }


        });
    }


    chooseType(idx) {
        this.type = idx;
    }

/* @ViewChild(ExcelViewerComponent) excelViewer: ExcelViewerComponent;

  async onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      await this.excelViewer.loadExcelFromBlob(file);
    }
  }*/

}
