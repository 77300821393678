<div id="pdf-viewer-bar" class="pdf-viewer-bar">
    <div class="btn-primary" style="height:4px"></div>
    <div class="button-row">
        <div class="document-info">
            <button mat-button disabled class="pdf-name">
                <i class="{{getIcon()}}" style="font-size: 18px"></i>
                <div class="dir-name" style="font-size:13px;display:inline"
                     matTooltip="{{documentName | listDocumentName: true}}">
                    {{ documentName | listDocumentName: false }}
                </div>
            </button>
            <button mat-button disabled class="pdf-name">
                <span *ngIf="!this.loading" class="page">Strona {{ page }} / {{ pagesCount }}</span>
            </button>
        </div>

        <div class="document-actions">
            <button *ngIf="effectivePermissions.includes('DOWNLOAD_ORIGINAL')" [disabled]="!readyToDownload"
                    matTooltip="{{ 'vdrApp.document.viewer.download.original' | translate }}"
                    mat-raised-button class="button-square"
                    (click)="download()">
                <i class="{{getIcon()}}" style="font-size: 18px"></i>
            </button>
            <button *ngIf="effectivePermissions.includes('DOWNLOAD_PDF')" [disabled]="!readyToDownload"
                    matTooltip="{{ 'vdrApp.document.viewer.download.pdf' | translate }}"
                    mat-raised-button class="button-square"
                    (click)="downloadPDF()">
                <i class="fa fa-file-pdf-o ng-star-inserted" style="font-size: 18px"></i>
            </button>
            <button *ngIf="effectivePermissions.includes('DOWNLOAD_ENCRYPTED')" [disabled]="!readyToDownload"
                    matTooltip="{{ 'vdrApp.document.viewer.download.pdfPasswordProtected' | translate }}"
                    mat-raised-button class="button-square"
                    (click)="downloadPasswordProtected()">
                <i class="fa fa-lock ng-star-inserted" style="font-size: 18px"></i>
            </button>
            <button *ngIf="effectivePermissions.includes('PRINT')" [disabled]="loading"
                    matTooltip="{{ 'vdrApp.document.viewer.print' | translate }}"
                    mat-raised-button class="button-square"
                    (click)="print()">
                <mat-icon>print</mat-icon>
            </button>
            <div class="search-input">
                <form class="search-form">
                    <div>
                        <input #searchInput name="searchPhrase" (focus)="setSearchFocus(false)"
                               matTooltipPosition="below"
                               matTooltip="{{ 'vdrApp.document.viewer.searchHelp2' | translate }}"
                               [disabled]="loading"
                               type="text"
                               placeholder="{{ 'vdrApp.document.viewer.search' | translate }}"
                               style="border: 0; height: 31px; width: 160px; border-bottom: 1px solid"
                               (keydown)="onSearchKeyDown($event, searchInput)"
                               (change)="onCriteriaChange(searchInput.value)"
                        >
                    </div>
                    <button mat-icon-button
                            type="button"
                            style="margin: -5px 0 0 -25px"
                            (click)="searchInput.value = ''">
                        <mat-icon style="font-size:14px;">close</mat-icon>
                    </button>

                    <button mat-icon-button
                            type="submit"
                            style="margin: -5px 0 0 0px"
                            [disabled]="loading"

                            (click)="searchInDocument(searchInput.value)">
                        <mat-icon matTooltip="{{ 'vdrApp.document.viewer.search' | translate }}"
                                  style="font-size:14px;">search
                        </mat-icon>
                    </button>
                    <div style="display: none; width:320px;">
                        <div class="search-hint" *ngIf="searchFocus">
                            <div>{{ 'vdrApp.document.viewer.searchHelp2' | translate }}</div>
                            <mat-icon class="search-hint-icon">search</mat-icon>


                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="pdf-manipulation">

            <button
                    *ngIf="shouldEnableExcelViewer()"
                    (click)="navigateExcel()"
                    [disabled]="loading"
                    matTooltip="Podgląd Formuł"
                    mat-raised-button class="button-square">
                <mat-icon svgIcon="excel" style="padding: 0px"></mat-icon>
            </button>

            <button
                *ngIf="shouldEnableWordViewer()"
                (click)="navigateWord()"
                [disabled]="loading"
                matTooltip="Podgląd Dokumentu"
                mat-raised-button class="button-square">
                <mat-icon svgIcon="excel" style="padding: 0px"></mat-icon>
            </button>

            <button #annotateMenuTrigger="matMenuTrigger" *ngIf=" isRedactModeSupported() && !isInRedactMode() && effectivePermissions.includes('UPLOAD')" [matMenuTriggerFor]="redactionMenu"
                    (click)="onAnnotateButtonClick($event, annotateMenuTrigger)"
                    [disabled]="loading"
                    matTooltip="{{ 'viewer.redaction.startRedaction' | translate }}"
                    mat-raised-button class="button-square">
                <mat-icon>mode_comment</mat-icon>
            </button>

            <!-- has to be mouse down, otherwise it deselects text before we grab it -->
            <button *ngIf="isRedactModeSupported() && isInRedactMode() && effectivePermissions.includes('UPLOAD')" [matMenuTriggerFor]="redactionMenu"
                    (mousedown)="onRedactFinishClick($event)"
                    [disabled]="loading"
                    matTooltip="{{ 'viewer.redaction.finishRedaction' | translate }}"
                    mat-raised-button class="button-square">
                <mat-icon>approval</mat-icon>
            </button>

            <button *ngIf="effectivePermissions.includes('VIEW')"
                    [disabled]="loading"
                    matTooltip="{{ 'vdrApp.document.viewer.fence' | translate }}"
                    mat-raised-button class="button-square" (click)="toggleFenceView()">
                <mat-icon>visibility</mat-icon>
            </button>
            <button mat-raised-button
                    [disabled]="loading || isInEditMode()"
                    matTooltip="{{ 'vdrApp.document.viewer.zoomIn' | translate }}"
                    class="button-square"
                    (click)="zoomIn()">
                <mat-icon>zoom_in</mat-icon>
            </button>
            <button mat-raised-button
                    matTooltip="{{ 'vdrApp.document.viewer.zoomOut' | translate }}"
                    [disabled]="loading || isInEditMode()"
                    class="button-square"
                    (click)="zoomOut()">
                <mat-icon>zoom_out</mat-icon>
            </button>
            <button mat-raised-button
                    matTooltip="{{ 'vdrApp.document.viewer.rotateLeft' | translate }}"
                    [disabled]="loading || isInEditMode()"
                    class="button-square"
                    (click)="rotateLeft()">
                <mat-icon>rotate_left</mat-icon>
            </button>
            <button mat-raised-button
                    matTooltip="{{ 'vdrApp.document.viewer.rotateRight' | translate }}"
                    [disabled]="loading || isInEditMode()"
                    class="button-square"
                    (click)="rotateRight()">
                <mat-icon>rotate_right</mat-icon>
            </button>
            <button mat-raised-button
                    *ngIf="isRedactModeSupported() && isInEditMode()"
                    matTooltip="{{ 'vdrApp.document.viewer.save' | translate }}"
                    [disabled]="!shouldSaveButtonBeActive()"
                    class="button-square"
                    (click)="saveAnnotations()">
                <mat-icon>save</mat-icon>
            </button>
            <button mat-raised-button
                    matTooltip="{{ 'vdrApp.document.viewer.close' | translate }}"
                    class="button-square"
                    (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>

    </div>
    <mat-divider></mat-divider>
</div>
<div id="pdf-container-id" class="pdf-container" (scroll)="onPageScroll()">
    <p-progressBar [value]="progressBarValue" showValue="true" unit="%" *ngIf="loading"
                   [mode]="progressBarMode"></p-progressBar>

    <div *ngIf="loading" class="row h-100 justify-content-center align-items-center">
        <div *ngIf="loadingText !== null && loading">
            <h2>{{ loadingText }}</h2>
        </div>

        <div *ngIf="error" class="message">
            <h2>Nie udało się odtworzyć podglądu</h2>
        </div>
    </div>

    <div *ngIf="fenceView" id="fenceDiv" align="center">
        <section class="container" position="absolute">
            <h5 id="top-half"></h5>
            <h5 id="middle"></h5>
            <h5 id="bottom-half"></h5>
        </section>
    </div>

    <ngx-extended-pdf-viewer [src]="pdfSrc" [textLayer]="true" [showToolbar]="false" [showHandToolButton]="true"
                             [handTool]="!isInRedactMode()" [contextMenuAllowed]="false"
                             [zoom]="zoom" [(page)]="page" [rotation]="rotation"
                             (pdfLoadingFailed)="onError($event)"
                             (error)="onError($event)"
                             (progress)="onPdfLoadProgress($event)"
                             (pageChange)="onPageChange($event)"
                             (annotationLayerRendered)="onAnnotationLayerRendered($event)"
                             (select)="onSelect($event)" (selectionchange)="onSelectionChange($event)"
                             (pagesLoaded)="afterLoadComplete($event)"
    ></ngx-extended-pdf-viewer>
</div>


<mat-menu #redactionMenu="matMenu">
    <button mat-menu-item (click)="onRedactSelectionClick()">
<!--        <mat-icon>text_select_start</mat-icon>-->
        <span jhiTranslate="viewer.redaction.redactText">Zaczernij zaznaczenie</span>
    </button>
        <button mat-menu-item (click)="onRedactRectangleClick()">
<!--            <mat-icon>notifications_off</mat-icon>-->
            <span jhiTranslate="viewer.redaction.redactRectangle">Zaczernij obszar</span>
        </button>
    <button mat-menu-item (click)="onRemoveRedactionClick()">
<!--        <mat-icon>variable_remove</mat-icon>-->
        <span jhiTranslate="viewer.redaction.deleteAnnotation">Usuń zaczernienie</span>
    </button>
</mat-menu>


