import {AfterViewInit, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {NgbActiveModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {JhiEventManager, JhiLanguageService}from '@upside-cloud/ng-jhipster';

import {Invitation} from './invitation.service';
import {EMAIL_ALREADY_USED_TYPE, GroupService, LoginModalService} from '../../shared';
import {getFileNameFromResponseContentDisposition, saveFile} from '../../entities/directory-document/upload-download.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Group} from '../../shared/group/group.model';
import {Router} from '@angular/router';
import {MatDialogRef} from "@angular/material/dialog";
import {EventManager} from "@shared/alert/event-manager.service";

@Component({
    selector: 'jhi-invitation',
    templateUrl: './invitation-dialog.component.html',
    styleUrls: ['./invitation.scss', '../../entities/question/question.scss']
})
export class InvitationDialogComponent implements OnInit, AfterViewInit {

    error: string;
    errorEmailExists: string;
    emails: any;
    success: boolean;
    modalRef: NgbModalRef;
    isShowMasowe: boolean;
    isUsers: boolean;
    uploadedFile: any;
    groupId: number;
    groups: Group[];

    constructor(private languageService: JhiLanguageService,
                private invitationService: Invitation,
                private elementRef: ElementRef,
                private spinner: NgxSpinnerService,
                private renderer: Renderer2,
                private groupService: GroupService,
                private router: Router,
                private eventManager: EventManager,
                public activeModal: NgbActiveModal,
    ) {
    }

    ngOnInit() {
        this.success = false;
        this.isShowMasowe = false;
        this.isUsers = true;
        this.groupId = null;
        this.loadProjectGroups();
    }

    toggleMasowe() {
        this.isShowMasowe = !this.isShowMasowe;
    }

    ngAfterViewInit() {
        // this.renderer.invokeElementMethod(this.elementRef.nativeElement.querySelector('#email'), 'focus', []);
        this.renderer.selectRootElement(this.elementRef.nativeElement.querySelector('#emails')).focus();
    }

    checkUsers(isUsers: boolean) {
        this.isUsers = isUsers;
    }

    invitation() {
        this.invitationService.save(this.emails, this.uploadedFile, this.groupId, this.languageService.getCurrentLanguage()).subscribe(() => {
            this.activeModal.close();
            this.router.navigate(['user-management']);
            this.eventManager.broadcast({ name: 'userListModification', content: 'OK' });

            this.errorEmailExists = null;
        }, (response) => this.processError(response));
    }

    loadProjectGroups() {
        this.groupService.getProjectGroups().subscribe(
            (res: HttpResponse<Group[]>) => {
                this.groups = res.body;
            },
            (res: HttpErrorResponse) => {
            }
        );
    }

    private processError(response) {
        if (response.status === 400 && response.error.type === EMAIL_ALREADY_USED_TYPE) {
            this.errorEmailExists = 'ERROR';
        } else {
            this.error = 'ERROR';
        }
    }

    downloadInvitationTemplate() {
        this.spinner.show();
        this.invitationService.downloadTemplate('invitation').subscribe((res) => {
            const fileName = getFileNameFromResponseContentDisposition(res);
            saveFile(res.body, fileName);
            this.spinner.hide();
        }, () => this.spinner.hide());
    }

    myUploader(event) {
        if (event && event.files) {
            for (const file of event.files) {
                this.uploadedFile = (file);
            }
        }
    }

    dismiss(): void {
        this.activeModal.dismiss();
    }
}
