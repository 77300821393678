import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {ReportService} from '../report.service';
import * as moment from 'moment';
import {ReportUploadedCriteriaDto} from './dto/report-uploaded-criteria-dto.model';
import {ReportUploadedDto} from './dto/report-uploaded-dto.model';
import {MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from "@shared/alert/alert.service";

@Component({
    selector: 'jhi-report-uploaded',
    templateUrl: './report-uploaded.component.html',
    styleUrls: ['../report.scss']
})
export class ReportUploadedComponent implements OnInit, AfterViewInit {
    @Input() global = false;
    reportUploadedCriteriaDto: ReportUploadedCriteriaDto;
    reportUploadedCriteriaDtoTmp: ReportUploadedCriteriaDto;

    dataSource = new MatTableDataSource<ReportUploadedDataView>();
    displayedColumns: string[];

    visit = true;

    constructor(
        private alertService: AlertService,
        private reportService: ReportService,
        private translateService: TranslateService,
    ) {
        this.onLangChange();
    }

    ngOnInit() {

    this.reportUploadedCriteriaDtoTmp = {
        dateFrom: null,
        dateTo: null,
        maxReport: false,
        global: false
      };

        this.reportUploadedCriteriaDto = new ReportUploadedCriteriaDto();
        this.reportUploadedCriteriaDto.dateFrom = moment().utc();
        this.reportUploadedCriteriaDto.dateFrom = this.reportUploadedCriteriaDto.dateFrom.add(-1, 'M');
        this.reportUploadedCriteriaDto.dateTo = moment().utc();
        this.reportUploadedCriteriaDto.global = this.global;

        this.dataSource.data = [new ReportUploadedDataView(), new ReportUploadedDataView(), new ReportUploadedDataView()];
        this.loadAll();
    }

    getDisplayColumns(){
        const columns = ['data', 'filtered', 'sum' ];
        if (this.reportUploadedCriteriaDto.maxReport){
           columns.push('max');
           columns.push('total');
        }
        return columns;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            const tmpData = this.dataSource.data;
            const tmpPages = Object.assign(new ReportUploadedDataView(), tmpData[0]);
            const tmpUploaded = Object.assign(new ReportUploadedDataView(), tmpData[1]);
            const tmpLoaded = Object.assign(new ReportUploadedDataView(), tmpData[2]);

            tmpPages.name = this.translateService.instant('report.uploaded.pages');
            tmpUploaded.name = this.translateService.instant('report.uploaded.uploaded-capacity');
            tmpLoaded.name = this.translateService.instant('report.uploaded.loaded-files');

            this.dataSource.data = [tmpPages, tmpUploaded, tmpLoaded];
        });
    }

    loadAll() {
        this.reportService.findUploaded(this.reportUploadedCriteriaDto, this.visit).subscribe(
            (res: ReportUploadedDto) => {
                const tmpData = this.dataSource.data;
                const tmpPages = Object.assign(new ReportUploadedDataView(), tmpData[0]);
                const tmpUploaded = Object.assign(new ReportUploadedDataView(), tmpData[1]);
                const tmpLoaded = Object.assign(new ReportUploadedDataView(), tmpData[2]);

                tmpPages.filtered = res.filtered !== undefined ? '' + res.filtered.pagesCount : '-';
                tmpPages.max = res.max !== undefined ? '' + res.max.pagesCount : '-';
                tmpPages.sum = res.sum !== undefined ? '' + res.sum.pagesCount : '-';
                tmpPages.total = res.total !== undefined ? '' + res.total.pagesCount : '0';

                tmpUploaded.filtered = res.filtered !== undefined ? Math.ceil(res.filtered.uploadedBytes / 1024 / 1024).toString() + ' MB' : '-';
                tmpUploaded.max = res.max !== undefined ? Math.ceil(res.max.uploadedBytes / 1024 / 1024).toString() + ' MB' : '-';
                tmpUploaded.sum = res.sum !== undefined ? Math.ceil(res.sum.uploadedBytes / 1024 / 1024).toString() + ' MB' : '-';
                tmpUploaded.total = res.total !== undefined ? Math.ceil(res.total.uploadedBytes / 1024 / 1024).toString() + ' MB' : '0';

                tmpLoaded.filtered = res.filtered !== undefined ? '' + res.filtered.documentsCount : '-';
                tmpLoaded.max = res.max !== undefined ? '' + res.max.documentsCount : '-';
                tmpLoaded.sum = res.sum !== undefined ? '' + res.sum.documentsCount : '-';
                tmpLoaded.total = res.total !== undefined ? '' + res.total.documentsCount : '0';


                // tmpPages.total = res.total.documentsCount == null ? '0' : res.total.documentsCount.toString();
                // tmpPages.max = '';
                // tmpUploaded.total = res.total.uploadedB == null ? '0' : Math.ceil(res.total.uploadedB / 1024 / 1024).toString() + ' MB';
                // tmpUploaded.max = res.maxUploadedB == null ? '0' : Math.ceil(res.maxUploadedB
                //     / 1024 / 1024).toString() + ' MB';
                //
                // if (res.filtered != null) {
                //     tmpPages.filtered = res.filtered.documentsCount == null ? '0' : res.filtered.documentsCount.toString();
                //     tmpUploaded.filtered = res.filtered.uploadedB == null ? '0' : Math.ceil(res.filtered.uploadedB / 1024 / 1024).toString() + ' MB';
                // } else {
                //     tmpPages.filtered = '-';
                //     tmpUploaded.filtered = '-';
                // }

                this.dataSource.data = [tmpPages, tmpUploaded, tmpLoaded];
                this.visit = false;
            }
        );
    }

    clear() {
        this.reportUploadedCriteriaDto = new ReportUploadedCriteriaDto();
        this.loadAll();
    }

    search() {
        this.reportUploadedCriteriaDto = Object.assign(new ReportUploadedCriteriaDto(), this.reportUploadedCriteriaDtoTmp);
        this.reportUploadedCriteriaDtoTmp = null;
        this.loadAll();
    }

    filterOpen() {
        this.reportUploadedCriteriaDtoTmp = Object.assign(new ReportUploadedCriteriaDto(), this.reportUploadedCriteriaDto);
    }

    chipsRemoveDateFrom() {
        this.reportUploadedCriteriaDto.dateFrom = null;
        this.loadAll();
    }

    chipsRemoveDateTo() {
        this.reportUploadedCriteriaDto.dateTo = null;
        this.loadAll();
    }

    chipsRemoveAll() {
        this.reportUploadedCriteriaDto = new ReportUploadedCriteriaDto();
        this.loadAll();
    }

    hasFilters() {
        return this.reportUploadedCriteriaDto.dateFrom != null
            || this.reportUploadedCriteriaDto.dateTo != null;
    }

    export() {
        this.reportUploadedCriteriaDto.deleted = true;
        this.reportService.exportUploaded(this.reportUploadedCriteriaDto);
    }

    onLangChange(): void {
        this.translateService.onLangChange.subscribe(() => {
            this.dataSource.data[0].name = this.translateService.instant('report.uploaded.pages');
            this.dataSource.data[1].name = this.translateService.instant('report.uploaded.uploaded-capacity');
            this.dataSource.data[2].name = this.translateService.instant('report.uploaded.loaded-files');
        });
    }
}

export class ReportUploadedDataView {
    public name: string;
    public filtered: string;
    public sum: string;
    public max: string;
    public total: string;
}
