<form name="editForm" role="form" novalidate (ngSubmit)="save()" #editForm="ngForm">
    <div class="new-question-card">
        <div class="modal-header">
            <h4 class="modal-title" id="myQuestionLabel" jhiTranslate="vdrApp.question.home.createOrEditLabel">Create or
                edit a Question</h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                    (click)="clear()">&times;
            </button>
        </div>
        <div class="modal-body new-question">
            <jhi-alert-error></jhi-alert-error>
            <div class="recipients-group" *ngIf="false">
                <div class="to">
                    <span class="question-recipient-label" jhiTranslate="vdrApp.question.recipient.to">DO:</span>
                    <div class="single-recipient" jhiTranslate="vdrApp.question.moderators">Moderatorzy</div>
                </div>
            </div>
            <div class="recipients-group" *jhiHasAnyAuthority="['QA_DELETE','QA_SHARE','QA_WRITE']">
                <div class="to">
                    <span class="question-recipient-label" jhiTranslate="vdrApp.question.recipient.to">DO:</span>

                    <p-autoComplete
                        name="multiple"
                        placeholder="{{ 'vdrApp.question.recipients' | translate }}"
                        field="recipientName"
                        class="recipients-autocomplete"
                        [(ngModel)]="question.recipientsTO"
                        [multiple]="true"
                        [forceSelection]="true"
                        [suggestions]="recipients"
                        [dropdown]="true"
                        (completeMethod)="suggestRecipients($event,'TO')"
                        [minLength]="1"
                        [showTransitionOptions]="'500ms'"
                        [hideTransitionOptions]="'200ms'"
                    >
                        <ng-template let-recipient pTemplate="item">
                            <div class="ui-helper-clearfix template-border">
                                <div *ngIf="recipient.groupId" class="group-recipient">
                                    <i class="fa fa-users"></i>
                                    {{recipient.recipientName}}
                                </div>
                                <div *ngIf="recipient.userId" class="user-recipient">
                                    <i class="fa fa-user"></i>
                                    {{recipient.recipientName}}
                                    <small>(<i class="fa fa-users"></i>&nbsp;{{recipient.groupName}})</small>
                                </div>
                            </div>
                        </ng-template>
                    </p-autoComplete>

                </div>


                <div class="to">
                    <span class="question-recipient-label" jhiTranslate="vdrApp.question.recipient.cc">DW:</span>

                    <p-autoComplete [(ngModel)]="question.recipientsDW"
                                    name="multiple2"
                                    class="recipients-autocomplete"
                                    field="recipientName"
                                    [suggestions]="recipients"
                                    (completeMethod)="suggestRecipients($event,'CC')"
                                    [minLength]="1"
                                    [multiple]="true"
                                    [forceSelection]="true"
                                    [dropdown]="true"
                                    [showTransitionOptions]="'500ms'"
                                    [hideTransitionOptions]="'200ms'"
                    >
                        <ng-template let-recipient pTemplate="item">
                            <div class=" ui-helper-clearfix template-border">
                                <div *ngIf="recipient.groupId" class="group-recipient">
                                    <i class="fa fa-users"></i>
                                    {{recipient.recipientName}}
                                </div>
                                <div *ngIf="recipient.userId" class="user-recipient">
                                    <i class="fa fa-user"></i>
                                    {{recipient.recipientName}}
                                    <small>(<i class="fa fa-users"></i>&nbsp;{{recipient.groupName}})</small>
                                </div>
                            </div>
                        </ng-template>
                    </p-autoComplete>
                </div>
            </div>

            <div class="document-group" style="display:flex;flex-direction: row;justify-content:space-between">
                <span class="question-recipient-label"
                      jhiTranslate="vdrApp.question.selectedDocumentLabel">Dotyczy:</span>

                <p-autoComplete [(ngModel)]="question.document"
                                name="documentsSelect"
                                field="fileName"
                                class="recipients-autocomplete"
                                style="width: 100% !important"
                                placeholder="{{ 'vdrApp.question.selectDocument' | translate }}"
                                [suggestions]="documents"
                                (completeMethod)="suggestDocument($event)"
                                [minLength]="1"
                                [multiple]="false"
                                [forceSelection]="true">
                    <ng-template let-document pTemplate="item">
                        <div class=" ui-helper-clearfix template-border">
                            <!--<div class="document-path">-->
                                {{document.directoryPath}}{{document.fileName}}
                            <!--</div>-->
                        </div>
                    </ng-template>
                </p-autoComplete>
            </div>
            <div class="document-group" style="display:flex;flex-direction: row;justify-content:space-between">
                <div>
                    <mat-form-field class="no-line category-field">
                        <mat-label *ngIf="question.categories.length === 0" style="font-weight: bold; text-transform: none;">{{ 'vdrApp.question.category' | translate }}</mat-label>
                        <mat-select [(ngModel)]="question.categories"
                                    name="categories"
                                    multiple
                                    matTooltip="{{ 'vdrApp.question.category' | translate }}" class="category-select">
                            <mat-option *ngFor="let category of categories" [value]="category">{{category.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="priority-container">
                    <div *ngIf="question.isHighPriority()" class="priority"><i class="priority fa fa-exclamation"></i>
                    </div>
                    <mat-form-field class="no-line priority-field">
                        <mat-select [(ngModel)]="question.priority"
                                    name="priority"
                                    matTooltip="{{ 'vdrApp.question.priority' | translate }}"
                        >
                            <mat-option value="LOW">{{'vdrApp.QuestionPriority.LOW' | translate}}</mat-option>
                            <mat-option value="MEDIUM">{{'vdrApp.QuestionPriority.MEDIUM' | translate}}</mat-option>
                            <mat-option value="HIGH">
                                {{'vdrApp.QuestionPriority.HIGH' | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="form-group content-group">
                <div class="title-group">
                    <input type="text" class="form-control title" name="title" id="field_title"
                           placeholder="{{ 'vdrApp.question.title' | translate }}"
                           [(ngModel)]="question.title" required minlength="3" maxlength="218"/>
                    <span style="color: var(--prime-color); float: right; margin-top: -10px">{{question.title.length}}/218</span>
                </div>
                <span class="form-text text-danger ml-2" *ngIf="question?.title?.length < 1">Minimalna ilość znaków to 1</span>

                <p-editor class="form-control content" name="content" id="field_content"
                          placeholder="{{ 'vdrApp.question.content' | translate }}"
                          [(ngModel)]="question.content" [style]="{'height':'250px'}"
                          required (onTextChange)="checkContent($event)">
                    <p-header>
                        <span class="ql-formats">
                            <select class="ql-size">
                              <option value="small">{{ 'vdrApp.question.text-size.small' | translate }}</option>
                              <option selected>{{ 'vdrApp.question.text-size.normal' | translate }}</option>
                              <option value="large">{{ 'vdrApp.question.text-size.large' | translate }}</option>
                              <option value="huge">{{ 'vdrApp.question.text-size.huge' | translate }}</option>
                            </select>
                          </span>
                        <span class="ql-formats">
                            <button class="ql-bold" aria-label="Bold"></button>
                            <button class="ql-italic" aria-label="Italic"></button>
                            <button class="ql-underline" aria-label="Underline"></button>
                            <button class="ql-strike" aria-label="Strike"></button>
                        </span>
                        <span class="ql-formats">
                            <button class="ql-list" value="ordered"></button>
                            <button class="ql-list" value="bullet"></button>
                            <select class="ql-align" >
                                <option selected></option>
                                <option value="center" label="Center"></option>
                                <option value="right" label="Right"></option>
                                <option value="justify" label="Justify"></option>
                            </select>
                        </span>
                        <span class="ql-formats">
                            <select class="ql-color"></select>
                            <select class="ql-background"></select>
                        </span>
                    </p-header>
                </p-editor>


            </div>
            <div style="padding-top: 10px">
                <span style="color: var(--prime-color);float: right">{{contentLength}}/9948</span>
                <span class="form-text text-danger" *ngIf="contentLength < 1">Minimalna ilość znaków to 1</span>
                <span class="form-text text-danger" *ngIf="contentLength > 9948">Maksymalna ilość znaków to 9948</span>
            </div>

        </div>
        <div class="modal-footer" style="width:100%">

            <div style="display:flex; flex-direction: row;justify-content: end;width:100%;">
                <!--<p-fileUpload #fileInput
                              multiple="false"
                              name="demo[]"
                              [customUpload]="true"
                              auto="true"
                              (uploadHandler)="myUploader($event)"
                              (onSelect)="mySelect($event)"
                              previewWidth="0"
                              showUploadButton="false"
                >
                </p-fileUpload>-->
                <div style="display:flex; flex-direction: row;justify-content: flex-start; align-items: center;width:100%; font-size: 13px;">
                    <input type="file" class="file-input" (change)="myUploader($event)" #fileUpload>
                    {{fileName || ""}}
                    <mat-icon *ngIf="fileName !== ''" (click)="cancelUpload()" class="icon-click">cancel</mat-icon>
                </div>
                <div style="display:flex; flex-direction: row;justify-content: space-between; height:38px">
                    <button mat-raised-button
                        type="button" style="min-width:50px;border: 1px solid lightgray;margin-right:10px"
                        (click)="fileUpload.click()">
                        <i class="fa fa-paperclip" style="font-size: 20px"></i>
                    </button>
                    <button
                        mat-raised-button
                        color="primary"
                        type="submit"
                        class="ask-question-button"
                        [disabled]="editForm.form.invalid || question.recipientsTO.length == 0 || isSaving "
                    >
                        <span jhiTranslate="vdrApp.question.home.createLabel">New question</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
