import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {VdrSharedModule} from '../shared';
import { ReactiveFormsModule } from '@angular/forms';

import {
    accountState,
    ActivateComponent,
    ActivateService,
    InfoComponent,
    InfoService,
    Invitation,
    InvitationDialogComponent,
    PasswordResetModalService,
    PasswordResetService,
    PasswordResetFinishComponent,
    PasswordResetFinishService,
    PasswordResetInitComponent,
    PasswordResetInitService,
    PdfDocumentViewerComponent,
    PdfDocumentViewerService,
    PhoneResetComponent,
    PhoneResetService,
    QuestionResetComponent,
    QuestionResetService,
    RegistrationService,
    RegisterComponent,
    SecuritySettingsComponent,
    SessionsComponent,
    SessionsService,
    UserSettingsComponent,

} from './';
import {PasswordResetComponent} from './security/password-reset/password-reset.component';
import {PasswordStrengthBarComponent} from './security/password-reset/password-reset-strength-bar.component';
import {Project2Component} from './project2/project2.component';
import {FileUploadModule} from 'primeng/fileupload';
import {EditorModule} from 'primeng/editor';
import {SharedModule} from 'primeng/api';
import {ColorPickerModule} from 'ngx-color-picker';
import {SettingsComponent} from './settings/settings.component';
import {TranslateModule} from '@ngx-translate/core';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {TooltipModule} from 'primeng/tooltip';
import {CommonModule} from '@angular/common';
import {PhoneInputComponent} from "./settings/user-settings/phone-input/phone-input.component";


@NgModule({
    imports: [
        ReactiveFormsModule,
        VdrSharedModule,
        FileUploadModule,
        RouterModule.forChild(accountState),
        [EditorModule],
        CommonModule,
        SharedModule,
        ColorPickerModule,
        TranslateModule,
        NgxExtendedPdfViewerModule,
        TooltipModule,
        PhoneInputComponent,

    ],
    declarations: [
        ActivateComponent,
        RegisterComponent,
        InvitationDialogComponent,
        PasswordResetComponent,
        PdfDocumentViewerComponent,
        PasswordStrengthBarComponent,
        PasswordResetInitComponent,
        PasswordResetFinishComponent,
        InfoComponent,
        SessionsComponent,
        UserSettingsComponent,
        SettingsComponent,
        QuestionResetComponent,
        PhoneResetComponent,
        SecuritySettingsComponent,
        Project2Component,
    ],
    providers: [
        SessionsService,
        RegistrationService,
        Invitation,
        ActivateService,
        PasswordResetService,
        PdfDocumentViewerService,
        PasswordResetInitService,
        PasswordResetFinishService,
        InfoService,
        PasswordResetModalService,
        QuestionResetService,
        PhoneResetService
    ],

    exports: [
        FileUploadModule,
        InvitationDialogComponent
    ],

    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VdrAccountModule {
}
