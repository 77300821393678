import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {Observable} from 'rxjs';
import {SERVER_API_URL} from '../../app.constants';

import {Recipient} from './recipient.model';

export type EntityResponseType = HttpResponse<Recipient>;

@Injectable()
export class RecipientService {

    private resourceSearchUrl = SERVER_API_URL + 'api/_search/recipients';

    constructor(private http: HttpClient) {
    }

    suggestRecipients(req?: any): Observable<HttpResponse<Recipient[]>> {
        return this.http.post<Recipient[]>(this.resourceSearchUrl, req, {observe: 'response'}).pipe(
            map((res: HttpResponse<Recipient[]>) => this.convertArrayResponse(res)));
    }

    private convertArrayResponse(res: HttpResponse<Recipient[]>): HttpResponse<Recipient[]> {
        const jsonResponse: Recipient[] = res.body;
        const body: Recipient[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

    /**
     * Convert a returned JSON object to Recipient.
     */
    private convertItemFromServer(recipient: Recipient): Recipient {
        const copy: Recipient = Object.assign({}, recipient);
        return copy;
    }
}
