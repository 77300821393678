<div class="d-flex flex-row button-row" fxLayout="row" fxFlexAlign="start center">
    <div fxLayout="row" fxFlexAlign="start" fxFlex="100%">
        <button mat-raised-button style="min-width: 70px; margin-left: 0.5rem;" (click)="generate()" color="primary"  matTooltip="{{ 'report.archive.generate.archive' | translate }}">
            <span jhiTranslate="report.archive.generate.archive"></span>
        </button>

    </div>
</div>
<div class="overview-content" (scroll)="onTableScroll($event)">
    <div style="background-color: white;" fxLayout="column">
        <mat-table [dataSource]="dataSource" fxFlex="90%">
            <ng-container matColumnDef="additionDate">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b><span jhiTranslate="report.archive.addition.date">Addition date</span></b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font content-icon">
                    {{ element.additionDate | date: 'yyyy/MM/dd HH:mm:ss' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="additionBy">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b><span jhiTranslate="report.archive.addition.by">Addition by</span></b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font">
                    {{ element.additionBy.firstName + ' ' + element.additionBy.lastName }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="fromDate">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b><span jhiTranslate="report.date-from">Addition date</span></b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font content-icon">
                    {{ element.fromDate | date: 'yyyy/MM/dd' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="untilDate">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b><span jhiTranslate="report.date-to">Addition date</span></b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font content-icon">
                    {{ element.untilDate | date: 'yyyy/MM/dd' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="entitlements">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b><span jhiTranslate="report.archive.entitlements">Entitlements</span></b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-icon">
                    <span class="{{ getIconBySelectedOptions(element, reportArchiveOption.ENTITLEMENTS) }}"></span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="documents">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b><span jhiTranslate="report.archive.documents">Documents</span></b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-icon">
                    <span class="{{ getIconBySelectedOptions(element, reportArchiveOption.DOCUMENTS) }}"
                        matTooltip="{{getDirectoriesInfo(element)}}"></span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="users">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b><span jhiTranslate="report.archive.users">Users</span></b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-icon">
                    <span class="{{ getIconBySelectedOptions(element, reportArchiveOption.USERS) }}"></span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="qa">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b><span jhiTranslate="report.archive.qa">Q&A</span></b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-icon">
                    <span class="{{ getIconBySelectedOptions(element, reportArchiveOption.QA) }}"></span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="history">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b><span jhiTranslate="report.archive.history">History</span></b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-icon">
                    <span class="{{ getIconBySelectedOptions(element, reportArchiveOption.HISTORY) }}"></span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="uploadedData">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b><span jhiTranslate="report.archive.uploaded.data">Uploded data</span></b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-icon">
                    <span class="{{ getIconBySelectedOptions(element, reportArchiveOption.UPLOADED_DATA) }}"></span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="overview">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b><span jhiTranslate="report.archive.overview">Overview</span></b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-icon">
                    <span class="{{ getIconBySelectedOptions(element, reportArchiveOption.OVERVIEW) }}"></span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="lastReportArchiveStateDate">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b><span jhiTranslate="report.archive.last.state.date">Last state date</span></b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font content-icon">
                    {{ element.lastReportArchiveStateDate | date: 'yyyy/MM/dd HH:mm:ss' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="state">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b><span jhiTranslate="report.archive.state">Status</span></b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font content-icon">
                    {{ 'report.archive.state.' + element.reportArchiveState | translate }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="generate">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font"></mat-header-cell>
                    <mat-cell *matCellDef="let element" class="content-font">
                        <button type="button"
                                class="btn btn-primary btn-sm ml-2"
                                (click)="downloadArchive(element)"
                                [disabled]="isNotFinishedOrDownloading(element)">
                            <span class="{{ getIconForDownloadButton(element) }}"></span>
                        </button>

                    </mat-cell>

            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
    </div>
</div>
