import {AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {JhiEventManager, JhiLanguageService}from '@upside-cloud/ng-jhipster';

import {StateStorageService} from '../auth/state-storage.service';
import {JhiLanguageHelper} from './../language/language.helper';
import {LoginService} from '../login/login.service';
import {AccountService, Principal} from '..';
import {ProjectVdr} from '@admin/project-vdr/project-vdr.model';
import {ProjectVdrService} from '@admin/project-vdr';
import { HttpResponse } from '@angular/common/http';
import {EventManager} from "@shared/alert/event-manager.service";

@Component({
    selector: 'jhi-therms-modal',
    templateUrl: './therms.component.html',
    styleUrls: ['./therms.scss']

})
export class ThermsModalComponent implements OnInit {
    blockedAccount: boolean;
    authenticationError: boolean;
    password: string;
    rememberMe: boolean;
    username: string;
    verificationCode: string;
    credentials: any;
    twoFactorMode = false;
    languages: any[];
    language: any;
    currentLanguage: any;
    currentProject: ProjectVdr;

    private ACCOUNT_BLOCKED = 'locked';
    private CREDENTIALS_EXPIRED = 'credentialsExpired';
    private VERIFICATION_CODE_REQUIRED = '2FA';

    constructor(private eventManager: EventManager,
                private loginService: LoginService,
                private stateStorageService: StateStorageService,
                private elementRef: ElementRef,
                private renderer: Renderer2,
                private router: Router,
                public activeModal: NgbActiveModal,
                private languageHelper: JhiLanguageHelper,
                private languageService: JhiLanguageService,
                private    accountService: AccountService,
                private     principal: Principal,
                private projectService: ProjectVdrService,
    ) {
        this.credentials = {};
    }

    ngOnInit() {
        this.currentProject = this.principal.getUserIdentity().project;
        this.projectService.find(this.currentProject.id)
            .subscribe((projectResponse: HttpResponse<ProjectVdr>) => {
                this.currentProject = projectResponse.body;
            });
    }

    cancel() {

        this.activeModal.dismiss('cancel');
        this.loginService.logout();
        this.router.navigate(['']);
    }

    acceptTherms() {

        this.accountService.acceptTherms().subscribe((response) => this.onThermsAcceptanseSuccess(response), (response) => this.onThermsAcceptanseError(response));

    }

    private onThermsAcceptanseSuccess(response) {
        this.activeModal.close('success');

        // this.principal.identity(true);
    }

    private onThermsAcceptanseError(response) {
       this.activeModal.dismiss(response);
    }

}
