import {Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {JhiAlertService, JhiParseLinks} from '@upside-cloud/ng-jhipster';
import {
    AccountService,
    enumKeyValueFilter,
    enumToKeyValue,
    getFormattedTimezone,
    Group,
    ITEMS_PER_PAGE,
    Pageable,
    PageableSort,
    Principal,
    ProjectUser,
    SortDirection,
    UserService,
} from '../../shared';
import {ReportService} from '../report.service';
import {BlockchainConfirmationStatus, ReportEventDto} from './dto/report-event-dto.model';
import {ReportEventCriteriaDto} from './dto/report-event-criteria-dto.model';
import * as moment from 'moment';
import {Moment} from 'moment'; // Import Moment type
import {MatExpansionPanel} from '@angular/material/expansion';
import {getGroupTypes, ReportEventType} from './report-event-type.enum';
import {ReportEventTypeGroup} from './report-event-type-group.enum';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {FormControl} from '@angular/forms';
import {ProjectVdr} from '@admin/project-vdr';
import {map, startWith} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {ClipboardService} from 'ngx-clipboard';
import {ReportBlockchain} from './report-blockchain.enum';
import {EventManager} from "@shared/alert/event-manager.service";
import {AlertService} from "@shared/alert/alert.service";

@Component({
    selector: 'jhi-report-event',
    templateUrl: './report-event.component.html',
    styleUrls: ['../report.scss']
})
export class ReportEventComponent implements OnInit, OnDestroy {
    reportEvents: ReportEventDto[] = [];
    reportEventCriteriaDto: ReportEventCriteriaDto = new ReportEventCriteriaDto();
    reportEventCriteriaDtoTmp: ReportEventCriteriaDto | null = null;
    groups: Group[] = [];
    selectedGroups: Group[] = [];
    projectUsers: ProjectUser[] = [];
    selectedProjectUser: ProjectUser | null = null;
    reportTypeItems: ReportTypeItem[] = [];
    selectedReportTypeItems: { [key: string]: ReportTypeItem | null } = {};

    eventFilter = '';

    pageable: Pageable = new Pageable(0, ITEMS_PER_PAGE);
    totalItems: number = 0;
    reverse: boolean = false;
    links: any = {last: 0};
    predicate: string = 'id';
    eventSubscriber!: Subscription;

    public ViewType = ViewType;
    viewType: ViewType = ViewType.TABLE;

    dataSource = new MatTableDataSource<ReportEventDto>();
    displayedColumns: string[] = ['id', 'author', 'action', 'description', 'details', 'date', 'blockchain'];
    pageSizeOptions: number[] = [5, 10, 20, 50];

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild('groupsExpansionPanel') groupsExpansionPanel!: MatExpansionPanel;

    visit = true;
    isAdmin: boolean = false;
    projects = new BehaviorSubject<ProjectListItem[]>([]);
    projectSelect = new FormControl<string | null>(null);
    filteredProjects!: Observable<ProjectListItem[]>;
    projectChangeAvailable = false;
    selectedBlockchainItem: ReportEventDto | null = null;

    // Corrected FormControls to use Moment
    dateFromControl = new FormControl<Moment | null>(null);
    dateToControl = new FormControl<Moment | null>(null);

    constructor(
        private reportService: ReportService,
        private jhiAlertService: AlertService,
        private eventManager: EventManager,
        private parseLinks: JhiParseLinks,
        private translateService: TranslateService,
        private principal: Principal,
        private accountService: AccountService,
        private userService: UserService,
        public clipboardService: ClipboardService,
        private cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.dataSource.paginator = this.paginator;

        // Initialize date criteria with Moment objects
        this.reportEventCriteriaDto.dateFrom = moment().utc().subtract(1, 'year');
        this.reportEventCriteriaDto.dateTo = moment().utc();

        // Sync FormControls with reportEventCriteriaDtoTmp
        this.dateFromControl.valueChanges.subscribe(value => {
            if (this.reportEventCriteriaDtoTmp) {
                this.reportEventCriteriaDtoTmp.dateFrom = value;
            }
        });
        this.dateToControl.valueChanges.subscribe(value => {
            if (this.reportEventCriteriaDtoTmp) {
                this.reportEventCriteriaDtoTmp.dateTo = value;
            }
        });

        // Load projects
        this.accountService.getUserProjects().subscribe((res) => {
            const projectList = res.map((r: ProjectVdr) => new ProjectListItem(r));
            this.projects.next(projectList);
        });

        // Initialize report type items
        enumToKeyValue(ReportEventTypeGroup).forEach((reportEventTypeGroup: any) => {
            const reportTypeItem = new ReportTypeItem();
            reportTypeItem.key = reportEventTypeGroup.key;
            reportTypeItem.value = reportEventTypeGroup.value;
            reportTypeItem.childs = [];

            enumKeyValueFilter(enumToKeyValue(ReportEventType), getGroupTypes(ReportEventTypeGroup[reportEventTypeGroup.key]))
                .forEach((value) => {
                    const reportTypeItemChild = new ReportTypeItem();
                    reportTypeItemChild.key = value.key;
                    reportTypeItemChild.value = value.value;
                    reportTypeItemChild.parent = reportTypeItem;
                    reportTypeItem.childs.push(reportTypeItemChild);
                    this.selectedReportTypeItems[reportTypeItemChild.key] = reportTypeItemChild;
                });

            this.reportTypeItems.push(reportTypeItem);
            this.selectedReportTypeItems[reportTypeItem.key] = reportTypeItem;

        });

        // Check admin status
        this.principal.identity().then((u) => {
            if (u?.admin) {
                this.isAdmin = true;
                this.projectChangeAvailable = true;
                this.userService.findProjectUsers().subscribe((res) => this.projectUsers = res.body || []);
            }
        });

        this.registerChangeInReportEvents();
        this.filteredProjects = combineLatest([this.projects, this.projectSelect.valueChanges.pipe(startWith(''))]).pipe(
            map(([projects, value]) => value ? this._filter(projects, value) : projects)
        );

        this.loadAll();
    }

    // Added missing _filter method
    private _filter(projects: ProjectListItem[], value: string): ProjectListItem[] {
        const filterValue = value.toLowerCase();
        return projects.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    ngOnDestroy() {
        this.eventManager.destroy(this.eventSubscriber);
    }

    registerChangeInReportEvents() {
        this.eventSubscriber = this.eventManager.subscribe('reportEventListModification', () => this.reset());
    }

    sort(): PageableSort[] {
        const result = [new PageableSort(this.predicate, this.reverse ? SortDirection.ASC : SortDirection.DESC)];
        if (this.predicate !== 'id') {
            result.push(new PageableSort('id', SortDirection.ASC));
        }
        return result;
    }

    loadAll() {
        this.reportEventCriteriaDto.groupIds = [];
        this.reportEventCriteriaDto.reportEventTypes = [];
        Object.keys(this.selectedReportTypeItems).forEach((key) => {
            const selected = this.selectedReportTypeItems[key];
            if (selected && !selected.childs) {
                this.reportEventCriteriaDto.reportEventTypes.push(key as undefined as ReportEventType);
            }
        });
        this.reportEventCriteriaDto.userId = this.selectedProjectUser?.userId || null;
        if (!this.selectedProjectUser && this.selectedGroups.length) {
            this.reportEventCriteriaDto.groupIds = this.selectedGroups.map(group => group.id);
        }
        this.pageable.sorts = this.sort();
        this.reportService.findEvents(this.reportEventCriteriaDto, this.pageable, this.visit).subscribe(
            (res: HttpResponse<ReportEventDto[]>) => this.onSuccess(res.body || [], res.headers),
            (res: HttpErrorResponse) => this.onError(res.message)
        );
    }

    reset() {
        this.reportEvents = [];
        this.pageable = new Pageable(0, ITEMS_PER_PAGE);
        this.paginator.pageIndex = 0;
        this.loadAll();
    }

    pageSwitch(event: any) {
        this.pageable.page = event.pageIndex;
        this.pageable.size = event.pageSize;
        this.loadAll();
    }

    search() {
        this.reportEvents = [];
        this.reportEventCriteriaDto = {...this.reportEventCriteriaDtoTmp!};
        this.reportEventCriteriaDtoTmp = null;
        this.loadAll();
    }

    changeGroup(event: Group[]) {
        this.visit = true;
        this.selectedGroups = event;
        this.reset();
    }

    changeReportType(reportTypeItem: ReportTypeItem) {
        if (reportTypeItem.childs) {
            this.parentCheck(reportTypeItem);
        } else {
            this.childCheck(reportTypeItem);
        }
        this.visit = true;
        this.reset();
    }

    parentCheck(reportTypeItem: ReportTypeItem) {
        if (this.selectedReportTypeItems[reportTypeItem.key]) {
            reportTypeItem.childs.forEach((child) => this.selectedReportTypeItems[child.key] = null);
            this.selectedReportTypeItems[reportTypeItem.key] = null;
        } else {
            reportTypeItem.childs.forEach((child) => this.selectedReportTypeItems[child.key] = child);
            this.selectedReportTypeItems[reportTypeItem.key] = reportTypeItem;
        }
    }

    childCheck(reportTypeItem: ReportTypeItem) {
        if (this.selectedReportTypeItems[reportTypeItem.key]) {
            this.selectedReportTypeItems[reportTypeItem.key] = null;
            this.selectedReportTypeItems[reportTypeItem.parent!.key] = null;
        } else {
            this.selectedReportTypeItems[reportTypeItem.key] = reportTypeItem;
            const allSelected = reportTypeItem.parent!.childs.every(child => this.selectedReportTypeItems[child.key]);
            if (allSelected) {
                this.selectedReportTypeItems[reportTypeItem.parent!.key] = reportTypeItem.parent;
            }
        }
    }

    changeProjectUser() {
        this.visit = true;
        if (this.selectedProjectUser) {
            this.groupsExpansionPanel.close();
        }
        this.reset();
    }

    viewTypeChanged(viewType: ViewType) {
        this.viewType = viewType;
    }

    filterOpen() {
        this.reportEventCriteriaDtoTmp = {...this.reportEventCriteriaDto};
        this.dateFromControl.setValue(this.reportEventCriteriaDtoTmp.dateFrom);
        this.dateToControl.setValue(this.reportEventCriteriaDtoTmp.dateTo);
        this.cdr.detectChanges();
    }

    export() {
        this.reportService.exportEvents(this.reportEventCriteriaDto);
    }

    chipsRemoveDateFrom() {
        this.reportEventCriteriaDto.dateFrom = null;
        this.reset();
    }

    chipsRemoveDateTo() {
        this.reportEventCriteriaDto.dateTo = null;
        this.reset();
    }

    chipsRemoveAll() {
        this.reportEventCriteriaDto = new ReportEventCriteriaDto();
        this.reset();
    }

    hasFilters() {
        return !!this.reportEventCriteriaDto.dateFrom || !!this.reportEventCriteriaDto.dateTo;
    }

    lastIsDifferentDay(idx: number): boolean {
        const currentDate = new Date(this.reportEvents[idx].date);
        const lastDate = new Date(this.reportEvents[idx - 1].date);

        return currentDate.getFullYear() !== lastDate.getFullYear()
            || currentDate.getMonth() !== lastDate.getMonth()
            || currentDate.getDay() !== lastDate.getDay();
    }

    private onSuccess(data: ReportEventDto[], headers: any) {
        this.links = this.parseLinks.parse(headers.get('link'));
        this.totalItems = parseInt(headers.get('X-Total-Count'), 10);
        this.reportEvents = data;
        this.dataSource.data = this.reportEvents;
        this.visit = false;
    }

    private onError(error: string) {
        this.jhiAlertService.error(error, null, null);
    }

    getReportEventTypeGroupValue(reportEventTypeGroup) {
        return this.translateService.instant(ReportEventTypeGroup[reportEventTypeGroup]);
    }

    getReportEventTypeValue(reportEventType) {
        return this.translateService.instant(ReportEventType[reportEventType]);
    }

    isReportTypeSelected(key: string): boolean {
        return !!this.selectedReportTypeItems[key];
    }

    getFormattedTimezone(): string {
        return getFormattedTimezone();
    }

    getBlockchainIconColor(element: ReportEventDto): string {
        switch (element.blockchainStatus) {
            case BlockchainConfirmationStatus.PENDING:
            case BlockchainConfirmationStatus.CONFIRMED:
                return '#009E47';
            case BlockchainConfirmationStatus.ERROR:
                return '#F00';
            // case BlockchainConfirmationStatus.PENDING:
            default:
                return '#ffae00';
        }
    }

    getBlockchainDetailsTooltip(element: ReportEventDto): string {
        const details: string[] = [];
        details.push(this.translateService.instant(ReportBlockchain.CHECKSUM) + element.checksum);

        switch (element.blockchainStatus) {
            case BlockchainConfirmationStatus.CONFIRMED:
                details.push(this.translateService.instant(ReportBlockchain.TRANSACTION_ID) + element.blockchainTransactionId);
                details.push(this.translateService.instant(ReportBlockchain.CONFIRMED_AT) + element.blockchainCommittedAt);
                details.push(this.translateService.instant(ReportBlockchain.CONFIRMED));
                break;

            case BlockchainConfirmationStatus.PENDING:
                details.push(this.translateService.instant(ReportBlockchain.WAITING_FOR_CONFIRMATION));
                break;
        }

        return details.join('\n'); // title tag friendly new line
    }

    onCopyBlockchainDataClick(element: ReportEventDto) {
        if (element.blockchainStatus !== BlockchainConfirmationStatus.CONFIRMED) {
            return;
        }
        this.clipboardService.copy(element.blockchainTransactionId);
        this.jhiAlertService.info('report.blockchainDataCopied');
    }

    onProjectSelectionChange($value: any) {
        this.projects.subscribe((projects) => {
            this.reportEventCriteriaDto.projectId = $value.id;
            this.loadAll();
        });
    }

    getProjectName(projectId: number) {
        const projectListItems = this.projects.getValue().filter((p) => p.id === projectId);

        if (!projectListItems.length) {
            return ``;
        }

        return projectListItems[0].name;
    }

    setSelectedBlockchainItem(element) {
        this.selectedBlockchainItem = element;
    }

    onOpenBlockchainExplorer(selectedBlockchainItem: ReportEventDto) {
        const url = `http://node1.logpass.info/transactions/${selectedBlockchainItem.blockchainTransactionId}`;
        window.open(url, '_blank');
    }

    protected readonly BlockchainConfirmationStatus = BlockchainConfirmationStatus;

    isReportTypeVisible(key: any): Observable<boolean> {
        const label$: Observable<string> = this.translateService.stream(ReportEventType[key]);
        return label$.pipe(map(label => label && (!this.eventFilter || label.toLowerCase().indexOf(this.eventFilter.toLowerCase()) > -1)));
    }
}

export enum ViewType {
    FEED, TABLE
}

export class ReportTypeItem {
    public key: string;
    public value: string;
    public childs: ReportTypeItem[];
    public parent: ReportTypeItem;
}


class ProjectListItem {
    id: number;
    name: string;

    constructor(project: ProjectVdr) {
        this.id = project.id;
        this.name = project.name;
    }
}
