import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import getIconForExtension from 'font-awesome-filetypes';
import * as _ from 'lodash';
import {toNumber} from 'lodash';
import {getFileNameFromResponseContentDispositionBase64, saveFile, UploadDownloadService} from '../../entities/directory-document/upload-download.service';
import {DownloadObjectDto} from '../../entities/directory-document/dto/download-object-dto.model';
import {PdfDocumentViewerService} from './pdf-document-viewer.service';
import {Annotation, PdfDocumentViewerDialogData} from './pdf-document-viewer.model';
import {NgxSpinnerService} from 'ngx-spinner';
import {PdfPasswordProtectionDialog} from '../../entities/directory-document/pdf-password-protection-dialog/pdf-password-protection-dialog';
import {firstValueFrom, interval, Subject} from 'rxjs';
import {takeWhile} from 'rxjs/operators';
import {AnnotationLayerRenderedEvent, NgxExtendedPdfViewerService, PDFDocumentProxy, PdfLoadedEvent, ProgressBarEvent} from 'ngx-extended-pdf-viewer';
import {ConfirmationDialogService} from '../../entities/confirmation-dialog/confirmation-dialog.service';
import {MatMenuTrigger} from '@angular/material/menu';
import * as rb from 'rangeblock';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from "@shared/alert/alert.service";

const pixelValueRegexp = /[0-9]+/;

const getPixelValue = (value: string) => {
    return toNumber(pixelValueRegexp.exec(value)[0]);
};

const moveBlockingObserver = new ResizeObserver((e) => {
    (<HTMLElement>e[0].target).dataset.isResizing = 'true';
});

const findPageNodeForElement = (element: HTMLElement): Node => {
    let nodeToCheck = element;
    while (true) {
        nodeToCheck = nodeToCheck.parentElement;

        if (nodeToCheck.classList === undefined || !nodeToCheck.classList.contains('page')) {
            continue;
        }

        return nodeToCheck;
    }

    return null;
};

enum Mode {
    Viewer,
    RedactText,
    RedactRectangle,
    RemoveRedaction
}

function getPageElement(page: number) {
    return document.querySelector(`div[class="page"][data-page-number="${page}"]`) as HTMLElement;
}

function setElementPositionDataset(redactedBoxElement: HTMLDivElement, page: HTMLElement, top: number, left: number, width: number, height: number) {
    redactedBoxElement.dataset.top = String(top / page.offsetHeight);
    redactedBoxElement.dataset.left = String(left / page.offsetWidth);
    redactedBoxElement.dataset.width = String(width / page.offsetWidth);
    redactedBoxElement.dataset.height = String(height / page.offsetHeight);
}

@Component({
    selector: 'pdf-document-viewer',
    templateUrl: './pdf-document-viewer.component.html',
    styleUrls: ['pdf-document-viewer.scss', '../../entities/directory-document/directory-document.scss'],
    providers: [NgxSpinnerService]
})
export class PdfDocumentViewerComponent implements OnInit, OnDestroy {
    public mode: Mode = Mode.Viewer;
    public effectivePermissions = [];
    public documentName: string;
    public documents: any;
    public documentId: number;

    public pdfSrc: any;
    public currentPage = 1;
    public page = 1;
    public timeEnteringPage: number;

    public pagesCount: number;
    public searchPhrase: string;
    public rotation: 90 | 180 | 270 | 0 = 0;
    public zoom: string | number = 100;
    public fenceView = false;
    public originalType: string;
    public error = false;
    public loading = true;
    public searchFocus = false;
    public loadingText = 'Pobieranie dokumentu';

    private initTime;
    private debouncedPageChange = _.debounce(() => this.updateCurrentPage(), 700, {});
    private actualSearchPhrase: string;
    private fileSize: number;
    private pdf: PDFDocumentProxy;
    public progressBarMode = 'indeterminate';
    public progressBarValue: number;
    readyToDownload: boolean;
    private editModeInitialized = false;
    private pdfLoaded = new Subject<PdfLoadedEvent>();
    annotations: HTMLElement[] = [];
    pendingAnnotations: HTMLElement[] = [];
    initialAnnotationsCount = 0;
    private currentAnnotationLayerScale: number;


    constructor(private route: ActivatedRoute,
                private router: Router,
                private pdfViewerService: PdfDocumentViewerService,
                private printService: NgxExtendedPdfViewerService,
                private spinner: NgxSpinnerService,
                @Inject(MAT_DIALOG_DATA) public data: PdfDocumentViewerDialogData,
                private uploadDownloadService: UploadDownloadService,
                private alert: AlertService,
                private dialogRef: MatDialogRef<PdfDocumentViewerComponent>,
                private confirm: ConfirmationDialogService,
                private dialog: MatDialog,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.documentId = this.data.documentId;
            this.showFile(this.data.documentId);
        });

        this.dialogRef.keydownEvents().subscribe((event) => {
            event.preventDefault();
            if (event.key === 'Escape') {
                this.close();
            }
        });

        this.dialogRef.backdropClick().subscribe((event) => {
            event.preventDefault();
            this.close();
        });

    }

    download() {
        this.doDownload('ORIGINAL');
    }


    onCriteriaChange(value: string) {
        _.debounce(() => this.searchInDocument(value), 400, {});
    }

    private shouldForceFenceView() {
        return this.effectivePermissions.length === 1 && this.effectivePermissions.includes('FENCE_VIEW');
    }

    shouldEnableExcelViewer() {
        return this.effectivePermissions.includes('DOWNLOAD_ORIGINAL') && this.originalType === 'xlsx';
    }

    shouldEnableWordViewer() {
        const wordFormatType = ['doc','docx'].includes(this.originalType);
        return this.effectivePermissions.includes('DOWNLOAD_ORIGINAL') && wordFormatType ;
    }

    close() {
        if (this.pendingAnnotations.length === 0) {
            this.dialogRef.close();
            return;
        }

        this.confirm.confirm({title: 'Niezapisane zmiany', text: 'Ten dokument posiada niezapisane zmiany, czy jesteś pewien, że chcesz go zamknąć?'}).subscribe((value) => {
            if (!value) {
                return;
            }

            this.dialogRef.close();
        });
    }


    navigateExcel() {
       this.spinner.show();
        this.dialogRef.close();
     this.router.navigate(['/excel'], {
                              queryParams: {
                                  id: this.documentId,
                                  redirectUrl: this.router.url
                              }
                            });
    }

    navigateWord() {
        this.spinner.show();
        this.dialogRef.close();
        this.router.navigate(['/word'], {
            queryParams: {
                id: this.documentId,
                redirectUrl: this.router.url
            }
        });
    }

    downloadPasswordProtected() {
        this.doDownload('PDF_PASSWORD_PROTECTED');
    }

    downloadPDF() {
        this.doDownload('PDF');
    }

    onError(evt) {
        console.log('on error', evt);
        this.error = true;
        this.loading = false;
    }

    afterLoadComplete(event: PdfLoadedEvent) {
        this.loading = false;
        this.initTime = Date.now();
        this.timeEnteringPage = this.initTime;
        this.fallbackTotalPagesCount(Number(event.pagesCount));
        if (this.shouldForceFenceView()) {
            this.forceFenceView();
        }

        this.pdfLoaded.next(event);
    }

    private fallbackTotalPagesCount(totalPages: number): void {
        if (this.pagesCount === 0) {
            this.pagesCount = totalPages;
        }
    }

    getIcon(): string {
        return (this.originalType) ? 'fa ' + getIconForExtension(this.originalType) : '';
    }


    print() {
        this.printService.print();
    }

    zoomIn() {
        if (!Number.isInteger(this.zoom)) {
            this.zoom = 100;
        }
        // @ts-ignore
        this.zoom += 10;
        this.pdfViewerService.zoomInEvent(this.documentId);
    }

    zoomOut() {
        if (!Number.isInteger(this.zoom)) {
            this.zoom = 100;
        }
        // @ts-ignore
        this.zoom -= 10;
        this.pdfViewerService.zoomOutEvent(this.documentId);
    }

    rotateLeft() {
        const rotation = this.rotation -= 90;
        this.applyRotation(rotation);
        this.pdfViewerService.rotateLeftEvent(this.documentId);
    }


    rotateRight() {
        const rotation = this.rotation += 90;
        this.applyRotation(rotation);
        this.pdfViewerService.rotateRightEvent(this.documentId);
    }

    private applyRotation(rotation: number) {
        if (rotation > 270) {
            rotation = 0;
        }

        if (rotation < 0) {
            rotation = 270;
        }
        // @ts-ignore
        this.rotation = rotation;
    }

    searchInDocument(value: string) {
        this.searchPhrase = value;
        console.log(this.searchPhrase, this.actualSearchPhrase);
        this.setSearchFocus(true);
        if (!(this.searchPhrase && this.searchPhrase.length > 0)) {
            return;
        }

        if (this.actualSearchPhrase !== this.searchPhrase) {
            this.printService.find(this.searchPhrase, {
                highlightAll: true,
                wholeWords: false
            });
            this.actualSearchPhrase = this.searchPhrase;
        } else {
            this.printService.findNext();
        }
        this.pdfViewerService.searchEvent(this.documentId, this.searchPhrase);
    }


    private showFile(documentId, withWatermark = true) {
        this.loading = true;

        interval(2000)
            .pipe(() => this.pdfViewerService.getPDFResponse(documentId).pipe(), takeWhile((r, i) => {
                this.documentName = r.fileName;
                this.originalType = r.documentType;
                this.effectivePermissions = r.effectivePermissions;
                this.pagesCount = r.pagesCount;
                this.readyToDownload = r.readyToDownload;

                console.log(i, r);
                if (!r.readyToDownload) {
                    this.loadingText = 'Dokument jest jeszcze przetwarzany, prosimy zaczekać';
                    return false;
                }
                return true;
            }))
            .subscribe((r) => {
                console.log(r);

                this.fileSize = r.fileSize;
                this.pdfSrc = `${this.pdfViewerService.getPDFLocation(documentId)}?addWatermark=${withWatermark}`;
                console.log('pdf src', this.pdfSrc);
            }, () => this.onError(null));
    }

    private doDownload(type: string) {
        this.spinner.show();
        this.uploadDownloadService.downloadZip(
            [new DownloadObjectDto(this.documentId, 'document', false)], type)
            .subscribe((res) => {
                const fileName = getFileNameFromResponseContentDispositionBase64(res);
                saveFile(res.body, fileName);
                this.spinner.hide();
                this.handlePdfPassword(res);
            }, () => this.spinner.hide());
    }

    private handlePdfPassword(res) {
        if (res.headers.keys().includes('x-pdf-protection')) {
            this.dialog.open(PdfPasswordProtectionDialog, {
                width: '350px',
                data: {
                    pdfPassword: res.headers.get('x-pdf-protection'),
                    archiveMode: false,
                    onPasswordCopy: () => this.pdfViewerService.passwordCopyEvent(this.documentId)
                }
            });
        }
    }

    mousePercentageY(mouseEvent: MouseEvent): number {
        let positionY = 0;

        if (mouseEvent.pageY) {
            positionY = mouseEvent.pageY;
        } else if (mouseEvent.clientY) {
            positionY = mouseEvent.clientY + (document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop);
        }

        let percentagePositionY = Math.floor((positionY - 30) / (document.getElementsByClassName('pdf-container')[0].clientHeight) * 100);

        if (percentagePositionY > 90) {
            percentagePositionY = 90;
        }
        return percentagePositionY;
    }


    @HostListener('mousemove', ['$event'])
    onMouseMove(event: MouseEvent): void {
        if (this.fenceView) {
            this.follow(event);
        }
    }

    follow(evt?) {
        const objTopHalf = document.getElementById('top-half').style;
        const objMiddle = document.getElementById('middle').style;
        const objBottomHalf = document.getElementById('bottom-half').style;
        const percentageY = this.mousePercentageY(evt);

        objTopHalf.height = percentageY + '%';
        objMiddle.top = percentageY + '%';
        objBottomHalf.top = (10 + percentageY) + '%';
        objBottomHalf.height = (100 - (10 + percentageY)) + '%';
    }

    toggleFenceView() {
        this.fenceView = !this.fenceView;

        if (this.fenceView) {
            this.pdfViewerService.fenceOnEvent(this.documentId);
        } else {
            this.pdfViewerService.fenceOffEvent(this.documentId);
        }
    }

    forceFenceView() {
        this.fenceView = true;
    }

    ngOnDestroy(): void {
        if (!this.error) {
            this.pdfViewerService.closeEvent(this.documentId, this.initTime, this.currentPage, this.timeEnteringPage);
        }
    }

    onPageScroll() {
        console.log('on page scroll');
        if (!this.loading) {
            this.debouncedPageChange();
        }
    }

    private updateCurrentPage() {
        console.log('new current page calculation');
        const newCurrentPage = this.calculateCurrentPageNumber();
        console.log('new current page ' + newCurrentPage);
        if (this.currentPage !== newCurrentPage) {
            this.currentPage = newCurrentPage;
            this.pdfViewerService.pageViewEvent(this.documentId, this.currentPage, this.timeEnteringPage);
            this.timeEnteringPage = Date.now();
        }


    }

    private calculateCurrentPageNumber(): number {
        const pages: any[] = Array.from(document.getElementById('pdf-container-id')
            .querySelectorAll('[data-page-number]'));

        let previousPageVisible = false;
        const visiblePages = [];

        for (const page of pages) {
            const visibleArea = this.inViewport(page);
            if (visibleArea > 0) {
                visiblePages.push({
                    'pageNumber': Number(page.getAttribute('data-page-number')),
                    'visible': visibleArea
                });
                previousPageVisible = true;
            } else if (previousPageVisible) {
                break;
            }
        }

        const newCurrentPage = visiblePages.sort((one, two) => this.sortPoints(one, two))[0];
        return newCurrentPage.pageNumber;
    }

    private inViewport(element) {
        const elH = element.clientHeight,
            H = window.innerHeight,
            r = element.getBoundingClientRect(), t = r.top, b = r.bottom;
        return Math.max(0, t > 0 ? Math.min(elH, H - t) : Math.min(b, H));
    }

    private sortPoints(one, two) {
        if (one.visible > two.visible) {
            return -1;
        } else if (two.visible > one.visible) {
            return 1;
        } else {
            return 0;
        }
    }


    setSearchFocus(show: boolean) {
        this.searchFocus = show;
    }

    onPdfLoadProgress(event: ProgressBarEvent) {

        if (!event.total) {
            this.progressBarMode = 'indeterminate';
        } else {
            this.updateProgressBar();
        }

        this.progressBarValue = event.percent;
    }

    private updateProgressBar() {
        const isLargeFile = this.fileSize > 5_000_000; // 5mb
        this.progressBarMode = isLargeFile ? 'determinate' : 'indeterminate';
    }

    onAnnotationLayerRendered($event: AnnotationLayerRenderedEvent) {
        console.log('annotation layer', $event);
        this.currentAnnotationLayerScale = $event.source.scale - 0.25;
        if (!this.isInEditMode()) {
            return;
        }

        this.getAllAnnotations().forEach((e) => {
            const page = getPageElement(Number(e.dataset.pageNumber));
            e.remove();
            this.createRedactedRectangle(Number(e.dataset.width) * page.offsetWidth, Number(e.dataset.height) * page.offsetHeight, Number(e.dataset.top) * page.offsetHeight, Number(e.dataset.left) * page.offsetWidth, e.dataset.redactionType, page);
        });
    }

    onPageChange($event: number) {
        console.log('on page change', $event);
    }

    onSelectionChange($event: Event) {
        console.log('on selection change', $event);
    }

    onSelect($event: Event) {
        console.log('on select', $event);
    }

    isRedactModeSupported() {
        return this.originalType === 'pdf';
    }

    isInRedactMode() {
        return this.mode != Mode.Viewer;
    }

    onRedactFinishClick($event: MouseEvent) {
        $event.preventDefault();

        switch (this.mode) {
            case Mode.RedactText:
                this.createRedactionRectangleForText();
                break;
        }

        this.mode = Mode.Viewer;
    }

    onRedactSelectionClick() {
        this.mode = Mode.RedactText;
        // Zaznacz tekst, który chcesz zaczernić, a następnie naciśnij przycisk "Zakończ zaczernianie"
        this.alert.info('viewer.redaction.selectText');
    }

    private createRedactionRectangleForText() {
        const selection = window.getSelection();
        if (selection.type !== 'Range') {
            this.mode = Mode.Viewer;
            this.alert.warning('viewer.redaction.noTextSelected');
            return;
        }

        // const selectionBlock = rb.extractSelectedBlock(window, document);
        // console.log('selectionBlock is', selectionBlock);


        const range = selection.getRangeAt(0);

        console.log('RedactText mode for range', range);
        const pageNode = (<HTMLElement>findPageNodeForElement(range.commonAncestorContainer as HTMLElement));

        const boundingClientRect = range.getBoundingClientRect();
        const pageBoundingClientRect = pageNode.getBoundingClientRect();

        const width = boundingClientRect.width;
        const height = boundingClientRect.height;

        const top = Math.abs(pageBoundingClientRect.top - boundingClientRect.top) - height / 2;
        const left = Math.abs(pageBoundingClientRect.left - boundingClientRect.left) - height / 2;
        const appendedElement = this.createRedactedRectangle(width, height, top, left, 'Text', pageNode);
        this.pendingAnnotations.push(appendedElement);

        console.log('Created element', appendedElement, 'on page', pageNode);
        selection.removeAllRanges();
    }

    private createRedactedRectangle(width: number, height: number, top: number, left: number, redactionType: string, pageNode: HTMLElement) {
        const page = <HTMLElement>pageNode;
        const redactedBoxElement = document.createElement('div');

        redactedBoxElement.style.position = 'absolute';
        redactedBoxElement.style.width = `${width}px`;
        redactedBoxElement.style.height = `${height}px`;
        redactedBoxElement.style.top = `${top}px`;
        redactedBoxElement.style.left = `${left}px`;
        redactedBoxElement.style.backgroundColor = 'black';
        redactedBoxElement.dataset.redactionType = redactionType;
        redactedBoxElement.classList.add('redaction-box');
        redactedBoxElement.dataset.pageNumber = page.dataset.pageNumber;
        redactedBoxElement.onclick = () => {
            this.onRedactionTextRectangleClick(redactedBoxElement);
        };

        // store percentage position for later use
        setElementPositionDataset(redactedBoxElement, page, top, left, width, height);

        console.log('Created rectangle for text', redactedBoxElement);
        return pageNode.appendChild(redactedBoxElement);
    }

    private onRedactionTextRectangleClick(redactionRect: HTMLElement) {

        if (this.mode !== Mode.RemoveRedaction) {
            console.log('onRedactionRectangleClick unsupported mode', this, this.mode, Mode.RemoveRedaction);
            return;
        }

        const pageNode = findPageNodeForElement(redactionRect);
        console.log('Redaction Rectangle click', event);
        this.pendingAnnotations = this.pendingAnnotations.filter(a => a != redactionRect);
        pageNode.removeChild(redactionRect);

        this.alert.info('viewer.redaction.elementDeleted');
    }

    onRemoveRedactionClick() {
        this.mode = Mode.RemoveRedaction;
        this.alert.info('viewer.redaction.clickToDelete');
    }

    saveAnnotations() {
        this.spinner.show();

        const annotationsToSave = this.getAllAnnotations().map((a) => {
            const pageNumber = toNumber(a.dataset.pageNumber);

            return <Annotation>{
                'redactionType': a.dataset.redactionType,
                'page': pageNumber,
                'top': Number(a.dataset.top),
                'left': Number(a.dataset.left),
                'width': Number(a.dataset.width),
                'height': Number(a.dataset.height)
            };
        });

        console.log('Annotations to save', annotationsToSave);
        this.pdfViewerService.saveAnnotations(this.documentId, annotationsToSave).subscribe(() => {
            this.pendingAnnotations = [];
            // this.showFile(this.documentId);
            this.alert.success('viewer.redaction.saveSuccess');
            this.spinner.hide();
        }, () => {
            this.alert.error('viewer.redaction.saveFailed');
            this.spinner.hide();
        });
    }

    private getAllAnnotations() {
        return this.pendingAnnotations;
    }

    onAnnotateButtonClick($event: MouseEvent, trigger: MatMenuTrigger) {
        if (this.editModeInitialized) {
            return;
        }

        this.loadingText = this.translateService.instant(`viewer.redaction.loadingEditMode`);
        this.rotation = 0;
        this.pdfLoaded.subscribe(() => {
            this.editModeInitialized = true;
            this.zoom = 'auto';
            setTimeout(() => {
                trigger.openMenu();
                this.loadAnnotationsForEditMode();
            }, 150);
        });
        this.showFile(this.documentId, false);
    }

    isInEditMode() {
        return this.editModeInitialized;
    }

    private loadAnnotationsForEditMode() {
        firstValueFrom(this.pdfViewerService.getAnnotations(this.documentId))
            .then((value) => {
                this.initialAnnotationsCount = value.length;
                value.forEach((a) => {
                    const annotationElement = this.renderAnnotation(a);
                    this.pendingAnnotations.push(annotationElement);
                });
            });
    }

    private renderAnnotation(a: Annotation) {
        const page = getPageElement(a.page);
        const pageBoundingClientRect = page.getBoundingClientRect();

        const width = a.width * pageBoundingClientRect.width;
        const height = a.height * pageBoundingClientRect.height;
        const top = a.top * pageBoundingClientRect.height;
        const left = a.left * pageBoundingClientRect.width;

        return this.createRedactedRectangle(width, height, top, left, a.redactionType, page);
    }

    shouldSaveButtonBeActive() {
        if (this.loading) {
            return false;
        }
        if (this.pendingAnnotations.length === 0 && this.initialAnnotationsCount === 0) {
            return false;
        }

        return true;
    }

    // pdf.js psuje inputy z jakiegoś powodu
    onSearchKeyDown($event: KeyboardEvent, searchInput: HTMLInputElement) {
        $event.preventDefault();
        if ($event.keyCode == 13) { // Enter
            this.onCriteriaChange(searchInput.value);
            return;
        }
        const currentValue = searchInput.value;
        const selectionStart = searchInput.selectionStart;

        if ($event.key === 'Backspace') {
            if (selectionStart === 0) {
                return;
            }
            searchInput.value = [currentValue.slice(0, selectionStart - 1), currentValue.slice(selectionStart)].join('');
            searchInput.selectionStart = Math.max(selectionStart - 1, 0);
            searchInput.selectionEnd = searchInput.selectionStart;
            return;
        }

        if ($event.key.length !== 1) {
            return;
        }

        searchInput.value = [currentValue.slice(0, selectionStart), $event.key, currentValue.slice(selectionStart)].join('');
        searchInput.selectionStart = selectionStart + 1;
        searchInput.selectionEnd = searchInput.selectionStart;
    }

    onRedactRectangleClick() {
        this.mode = Mode.RedactRectangle;
        this.alert.info('viewer.redaction.startRectangle');
        const pages = document.querySelectorAll('div.page');

        const onClick = (event: MouseEvent) => {
            console.log('click', event);
            this.handleFreeformRectangleDrawing(event, findPageNodeForElement((<HTMLElement>event.target))).then((value) => {
                this.pendingAnnotations.push(value);
                pages.forEach((page) => page.removeEventListener('click', onClick));
            });
        };

        pages.forEach((value) => {
            const element = <HTMLElement>value;
            console.log('query', element);
            element.addEventListener('click', onClick);
        });
    }

    handleFreeformRectangleDrawing(event: MouseEvent, pageNode: Node): Promise<HTMLElement> {
        return new Promise((resolve, reject) => {
            const page = <HTMLElement>pageNode;

            let width = 50;
            let height = 50;
            let top = event.offsetY;
            let left = event.offsetX;

            const redactedBoxElement = document.createElement('div');
            redactedBoxElement.style.position = 'absolute';
            redactedBoxElement.style.width = `${width}px`;
            redactedBoxElement.style.height = `${height}px`;
            redactedBoxElement.style.top = `${top}px`;
            redactedBoxElement.style.left = `${left}px`;
            redactedBoxElement.style.backgroundColor = 'black';
            redactedBoxElement.dataset.redactionType = 'Rectangle';
            redactedBoxElement.classList.add('redaction-box');
            redactedBoxElement.dataset.pageNumber = page.dataset.pageNumber;
            redactedBoxElement.addEventListener('mouseup', () => {
                redactedBoxElement.dataset.isResizing = 'false';
            });

            // store percentage position for later use
            setElementPositionDataset(redactedBoxElement, page, top, left, width, height);

            const createInitialSize = (event) => {
                width = toNumber(pixelValueRegexp.exec(redactedBoxElement.style.width)[0]) + event.movementX;
                height = toNumber(pixelValueRegexp.exec(redactedBoxElement.style.height)[0]) + event.movementY;

                redactedBoxElement.style.width = `${width}px`;
                redactedBoxElement.style.height = `${height}px`;

                setElementPositionDataset(redactedBoxElement, page, top, left, width, height);
            };

            const handleResizing = (event) => {
                if (this.mode == Mode.Viewer) {
                    return;
                }

                // is left clicked
                if (event.buttons !== 1) {
                    return;
                }

                // if it's resizing we don't move
                if (redactedBoxElement.dataset.isResizing === 'true') {
                    return;
                }

                requestAnimationFrame(() => {
                    top = toNumber(pixelValueRegexp.exec(redactedBoxElement.style.top)[0]) + event.movementY;
                    left = toNumber(pixelValueRegexp.exec(redactedBoxElement.style.left)[0]) + event.movementX;

                    redactedBoxElement.style.top = `${top}px`;
                    redactedBoxElement.style.left = `${left}px`;

                    setElementPositionDataset(redactedBoxElement, page, top, left, width, height);
                });
            };

            setTimeout(() => {
                window.addEventListener('click', () => {
                    page.removeEventListener('mousemove', createInitialSize);
                    redactedBoxElement.addEventListener('mousemove', handleResizing);

                    redactedBoxElement.addEventListener('click', () => {
                        this.onRedactionTextRectangleClick(redactedBoxElement);
                    });
                }, {once: true});
            }, 100);

            page.addEventListener('mousemove', createInitialSize);

            moveBlockingObserver.observe(redactedBoxElement);
            console.log('Created freeform rectangle for text', redactedBoxElement);
            const createdBoxElement = pageNode.appendChild(redactedBoxElement);
            resolve(createdBoxElement);
        });
    }
}
