<div class="d-flex flex-column">
    <div class="modal-header">
        <h4 class="modal-title" jhiTranslate="vdrApp.category.title">Category</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                (click)="close()">x
        </button>
    </div>


    <div fxFlex class="mb-3">
        <input type="text" style="border: none; border-bottom: 1px solid var(--prime-color);" fxFlex="81" name="name" id="field_name"
               placeholder="{{ 'vdrApp.category.new' | translate }}"
               [(ngModel)]="name" maxlength=50 required/>
        <div fxFlex="20">
            <button mat-raised-button
                    class="btn-primary"
                    color="primary"
                    (click)="save()"
                    [disabled]="!name"
                    style="margin-top: 10px; margin-left: 10px;"
                    >
                <span jhiTranslate="vdrApp.category.save">Save</span>
            </button>
        </div>
    </div>
    <div fxFlex>
        <table mat-table [dataSource]="categories" style="width:100%">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <span jhiTranslate="vdrApp.question.category">Category</span> </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            <ng-container matColumnDef="active">
                <th mat-header-cell *matHeaderCellDef>
                    <span jhiTranslate="vdrApp.category.state">State</span> </th>
                <td mat-cell *matCellDef="let element">
                    {{ (element.active ? 'vdrApp.category.active' : 'vdrApp.category.deleted')  | translate }} </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="right-align">
                    <button type="button"
                            matTooltip="{{ 'vdrApp.category.edit' | translate }}"
                            class="btn btn-primary btn-sm ml-2"
                            (click)="editCategory(element)">
                        <i class="fa fa-edit"></i>
                    </button>
                    <button type="button"
                            matTooltip="{{ 'vdrApp.category.delete' | translate }}"
                            class="btn btn-primary btn-sm ml-2"
                            (click)="changeStateCategory(element)" *ngIf="element.active">
                        <i class="fa fa-trash"></i>
                    </button>

                    <button type="button"
                            matTooltip="{{ 'vdrApp.category.restore' | translate }}"
                            class="btn btn-primary btn-sm ml-2"
                            (click)="changeStateCategory(element)" *ngIf="!element.active">
                        <i class="fa fa-refresh"></i>
                    </button>

                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
