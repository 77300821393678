<div style="margin-top:-5px" class="monitoring-container">
    <mat-tab-group style="background-color: #efeff2" (selectedIndexChange)="chooseType($event)">
        <mat-tab label="{{'report.projects.title' | translate }}" class="custom-mat-tab">
            <ng-template matTabContent>
                <div *ngIf="type === 0" fxLayout="column" class="monitoring-page">
                    <jhi-project-vdr *ngIf="type === 0"></jhi-project-vdr>
                </div>
            </ng-template>

        </mat-tab>
        <mat-tab label="{{'global.leftMenu.monitoring' | translate }}" class="custom-mat-tab">
            <ng-template matTabContent>
                <div fxLayout="column" class="monitoring-page" *ngIf="type === 1">
                    <div fxFlex="row" class="mb-4">
                        <div class="file-report">
                            <jhi-report-uploaded [global]="true" style="min-height: 100vh;"></jhi-report-uploaded>
                        </div>
                        <div class="project-report">
                            <h4 class="d-flex justify-content-center" jhiTranslate="report.projects.title"></h4>
                            <p-chart [options]="options"   #projectChart #documentTypeChart type="pie" [data]="fileTypeChartData" height="400" class="content-font"></p-chart>
                        </div>
                    </div>
                </div>
            </ng-template>

        </mat-tab>
        <mat-tab label="{{'dashboard.title' | translate }}" class="custom-mat-tab" >
            <ng-template matTabContent>
                <!-- Your monitoring content -->
                <div *ngIf="type === 2" fxLayout="column" class="monitoring-page">
                    <jhi-dashboard></jhi-dashboard>
                </div>
            </ng-template>
        </mat-tab>
        <mat-tab label="{{'userManagement.home.title' | translate }}" class="custom-mat-tab" >
            <ng-template matTabContent>
                <!-- Your monitoring content -->
                <div *ngIf="type === 3">
                    <jhi-user2></jhi-user2>
                </div>
            </ng-template>

        </mat-tab>
    </mat-tab-group>
</div>
