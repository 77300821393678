<div class="d-flex flex-column">
    <div class="modal-header">
        <h4 class="modal-title"><span >Klienci</span></h4>

        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                (click)="close()">&times;
        </button>
    </div>
    <div class="d-flex justify-content-start wid100 mt-3 form-input">
        <div class="search-input" fxLayout="row">
        <div fxLayout="column" class="flex-grow-1">
            <input name="search-name"
                   type="text"
                   (keyup)="searchBy($event)"
                   placeholder="Search"
            />
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <div fxLayout="column">
            <button
                mat-icon-button
                type="submit"
                style="margin: 0;"
                [disabled]="false">
                <mat-icon>search</mat-icon>
            </button>
        </div>
    </div>
    </div>
    <div style="overflow: auto; height: calc(70vh)" class="form-group">
        <mat-table [dataSource]="dataSource" style="background-color: white !important">
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b>klient</b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font content-icon">
                    <input placeholder="Name" [value]="element.name" [(ngModel)]="element.name" (keyup.enter)="setName(element)" class="form-input">
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="active">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b>status</b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font content-icon">
                    <button class="btn btn-danger btn-sm" (click)="setActive(element, true)" *ngIf="!element.active">
                            Nieaktywny
                    </button>
                    <button class="btn btn-success btn-sm" (click)="setActive(element, false)" *ngIf="element.active">
                            Aktywny
                    </button>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="projects">
                <mat-header-cell *matHeaderCellDef style="color: black;" class="header-font content-icon">
                    <b>projekty</b></mat-header-cell>
                <mat-cell *matCellDef="let element"  class="content-font content-icon">
                    {{ element.projectNames }}
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>

    </div>
</div>
