import {Component, ViewChild, NgZone, ChangeDetectorRef, OnInit, Input, AfterViewInit} from '@angular/core';
import {DocumentEditorContainerComponent, ToolbarService} from '@syncfusion/ej2-angular-documenteditor';
import {PdfDocumentViewerService} from '../../account/pdf-document-viewer/pdf-document-viewer.service';
import {LanguageStrings, translations} from './translations';
import {L10n, loadCldr} from '@syncfusion/ej2-base';
import {polishTranslation} from './translate-pl';
import {MatDialogRef} from "@angular/material/dialog";
import {DocumentVdrService} from "@entities/document-vdr";
import {MatSnackBar} from '@angular/material/snack-bar';
import {ClickEventArgs} from "@syncfusion/ej2-angular-navigations";
import {ActivatedRoute} from "@nm/@angular/router";
import {AlertService} from "@shared/alert/alert.service";

@Component({
    selector: 'jhi-word-viewer',
    styleUrls: ['word.viewer.component.scss'],
    template: `
        <div style="height: 100vh !important; display: flex; flex-direction: column">
            <button mat-icon-button style="position: absolute; right: 0px; top: 0px; z-index: 100"
                    matTooltip="{{ 'vdrApp.document.viewer.close' | translate }}"
                    class="button-square"
                    (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
            <ejs-documenteditorcontainer
                #documenteditor_default
                [enableToolbar]=true
                [enableRtl]=false
                [toolbarItems]="toolbarOptions"
                (toolbarClick)="onToolbarClick($event)"
                [locale]="locale"
                (created)="onCreate()"
                height="100%"
                style="display:block;">
            </ejs-documenteditorcontainer>
        </div>
    `,
    providers: [ToolbarService]
})
export class WordViewerComponent implements OnInit, AfterViewInit {
    @ViewChild('documenteditor_default')
    public container: DocumentEditorContainerComponent;
    @Input() locale: string = localStorage.getItem('lang') || 'pl';
    private documentId: number = 23003;
    private isEditorReady = false;
    private strings: LanguageStrings;
    public hostUrl: string = 'http://services.syncfusion.com/angular/production/api/documenteditor/';
    public effectivePermissions = [];

    private WEB_API_ACTION = 'api/documenteditor/';
    private redirectUrl: string;

    constructor(
        private pdfViewerService: PdfDocumentViewerService,
        private zone: NgZone,
        private cdr: ChangeDetectorRef,
        private dialogRef: MatDialogRef<WordViewerComponent>,
        private documentVdrService: DocumentVdrService,
        private snackBar: MatSnackBar,
        private route: ActivatedRoute,
        private alertService: AlertService,
    ) {
        console.log('!!!!!!!' + this.locale);
        L10n.load(polishTranslation);
    }

    public toolbarOptions: any = [
        {
            prefixIcon: 'e-save',
            tooltipText: 'Save',
            text: this.locale === 'pl' ? 'Zapisz' : 'Save',
            id: 'Save',
        },
        'Separator',
        'Undo',
        'Redo',
        'Separator',
        'Separator',
        'Image',
        'Table',
        'Hyperlink',
        'Bookmark',
        'TableOfContents',
        'Separator',
        'Header',
        'Footer',
        'PageSetup',
        'PageNumber',
        'Break',
        'Separator',
        'Find',
        'Separator',
        'Comments',
        'TrackChanges',
        'Separator',
        'LocalClipboard',
        'Separator',
        'FormFields',
        'UpdateFields'
    ];


    ngOnInit() {
        this.route.queryParamMap.subscribe(params => {
            this.documentId = +params.get('id');
            this.redirectUrl = params.get('redirectUrl');
            if (this.documentId) {
                this.fetchDocument(this.documentId);
            }
        });

    }

    ngAfterViewInit() {
    }

    onCreate(): void {
        this.container.serviceUrl = this.hostUrl + this.WEB_API_ACTION;
        this.isEditorReady = true;
        if (this.effectivePermissions.length > 0) {
            this.container.documentEditor.isReadOnly = !this.shouldEnableSave();
        }
    }

    public onToolbarClick(args: ClickEventArgs): void {
        if (args.item.id === 'Save') {
            this.saveDocument();
        }
    }

    private async saveDocument(): Promise<void> {

        if (!this.container?.documentEditor) {
            console.error('Document editor is not ready');
            return;
        }

        try {
            // Get the document in SFDT format
            const sfdt = this.container.documentEditor.serialize();

            const docx = await this.convertSfdtToDocx(sfdt);
            // Send to your backend
            this.saveBlob(docx);

            // Handle success
            console.log('Document saved successfully');

        } catch (error) {
            console.error('Failed to save document:', error);
        }
    }

    saveBlob(blob: Blob): void {
        // Example: Trigger a download
        const file = new File([blob], 'test.docx', {type: blob.type});
        this.documentVdrService.pushFileToStorage(file, this.documentId).subscribe(res => {
            this.alertService.success('Document Saved');
            this.close();
        });
    }

    fetchDocument(id: number): void {
        this.pdfViewerService.getPDFResponse(id).subscribe(res => {
            this.effectivePermissions = res.effectivePermissions;

            if (this.isEditorReady && this.container?.documentEditor) {
                this.container.documentEditor.isReadOnly = !this.shouldEnableSave();
            }

            this.pdfViewerService.downloadOrigin(id).subscribe({
                next: (res: any) => {
                    if (res.body) {
                        this.loadWordDocument(res.body);
                    } else {
                        console.error(this.strings.errors.nullBlob);
                    }
                },
                error: (error) => {
                    console.error(this.strings.errors.fetchError, error);
                }
            });
        });
    }

    private async loadWordDocument(blob: Blob): Promise<void> {
        try {
            const sfdt = await this.convertDocxToSfdt(blob);
            this.zone.run(() => {
                try {
                    this.container.documentEditor.open(sfdt);
                    this.cdr.detectChanges();
                } catch (err) {
                    console.error(this.strings.errors.openError, err);
                }
            });
        } catch (error) {
            console.error(this.strings.errors.processError, error);
        }
    }

    private async convertDocxToSfdt(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('files', blob, 'document.docx');

            const url = `${window.location.protocol}//${window.location.hostname}/api/document-editor/api/wordeditor/Import`;
            fetch(url, {
                method: 'POST',
                body: formData
            })
                .then(response => response.text())
                .then(resolve)
                .catch(reject);
        });
    }


    private async convertSfdtToDocx(sfdt: string): Promise<Blob> {
        return new Promise((resolve, reject) => {
            const url = `${window.location.protocol}//${window.location.hostname}/api/document-editor/api/wordeditor/ExportSFDT`;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    content: sfdt,
                    fileName: 'document.docx'  // This will ensure DOCX format
                })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Export failed: ${response.status} ${response.statusText}`);
                    }
                    return response.blob();
                })
                .then(resolve)
                .catch(reject);
        });
    }


    shouldEnableSave() {
        return this.effectivePermissions.includes('UPLOAD');
    }

    close() {
        this.dialogRef.close({id: this.documentId, redirectUrl: this.redirectUrl});

    }
}
