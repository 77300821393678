import {AfterViewInit, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NavbarComponent} from '../../layouts';


export interface SessionExpiringDialogData {
    navbar: NavbarComponent;
}


@Component({
    selector: 'session-expiring-dialog',
    templateUrl: 'session-expiring-dialog.component.html',
    styleUrls: []
})
export class SessionExpiringDialog implements AfterViewInit {

    constructor(
        public dialogRef: MatDialogRef<SessionExpiringDialog>,
        @Inject(MAT_DIALOG_DATA) public data: SessionExpiringDialogData,
    ) {
    }

    ngAfterViewInit(): void {
    }

    close() {
        this.dialogRef.close();
    }

    refreshSession() {
        this.data.navbar.refreshSession();
        this.dialogRef.close();
    }
}
