import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';
import * as moment from 'moment';

export class TimeZoneInterceptor implements HttpInterceptor {
    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const timeZoneOffset = moment().utcOffset();
        const newRequest = request.clone({headers: request.headers.set('ClientTimeZone', timeZoneOffset.toString())});

        return next.handle(newRequest);
    }
}
