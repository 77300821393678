import { Component,forwardRef, EventEmitter, Input, Output, Self } from '@angular/core';
import { NgxMaterialIntlTelInputComponent } from 'ngx-material-intl-tel-input';
import { CommonModule } from '@angular/common'; // Im
import { FormsModule } from '@angular/forms'; // Import FormsModule for two-way binding (if needed)
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // Import animations module
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControl, NgControl } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
@Component({
    selector: 'app-phone-input',
    templateUrl: './phone-input.component.html',
    styleUrls: ['./phone-input.component.scss'],
    styles: [`
        ::ng-deep .mat-mdc-text-field-wrapper:hover {
            background-color: white !important;
        }
    `
    ],
    imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgxMaterialIntlTelInputComponent
    ],
    standalone:true,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneInputComponent),
            multi: true
        }
    ]
})
export class PhoneInputComponent implements ControlValueAccessor{
    @Input() maxLength: number | undefined;
    @Input() preferredCountries: string[] = ['pl'];
    formControl: FormControl = new FormControl();
    onChange: (value: any) => void = () => {};
    onTouched: () => void = () => {};

    textLabels = {
        mainLabel: '', codePlaceholder: 'Code', searchPlaceholderLabel: 'Search', noEntriesFoundLabel: 'No countries found', nationalNumberLabel: '', invalidNumberError: 'Number is not valid', requiredError: 'This field is required'
    };

    constructor() {
    }

    ngOnInit(): void {
        this.formControl.valueChanges.subscribe(value => {
            this.onChange(value);
        });
    }

    // Write the value to the form control
    writeValue(value: any): void {
        this.formControl.setValue(value, { emitEvent: false });
    }

    // Register the change function to be called when the value changes
    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    // Register the touched function to be called when the component is touched
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // Set disabled state for the component
    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.formControl.disable() : this.formControl.enable();
    }

    // Handle input change event
    onInputChange(event: any): void {
        this.formControl.setValue(event);
    }

    onTouchedHandler() {
        this.onTouched();
    }
}

