import {Component, OnInit} from "@angular/core";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {ReportArchiveDialog} from "./report-archive.dialog";
import {ReportArchive, ReportArchiveOption} from "./report-archive.model";
import {ReportService} from "../report.service";
import { HttpResponse } from "@angular/common/http";
import {JhiAlertService} from "@upside-cloud/ng-jhipster";
import {NgxSpinnerService} from 'ngx-spinner';
import {SERVER_API_URL} from "../../app.constants";
import {TranslateService} from "@ngx-translate/core";
import {AlertService} from "@shared/alert/alert.service";

@Component({
    selector: 'jhi-report-archive',
    templateUrl: './report-archive.component.html',
    styleUrls: ['../report.scss']
})

export class ReportArchiveComponent implements OnInit {

    dataSource = new MatTableDataSource<ReportArchive>();
    displayedColumns = ['additionDate', 'additionBy', 'fromDate', 'untilDate', 'entitlements',
        'documents', 'users', 'qa', 'history', 'uploadedData', 'overview', 'lastReportArchiveStateDate',
        'state', 'generate'];
    pageLength: number = 30;
    page: number = 0;
    reportArchiveOption = ReportArchiveOption;

    constructor(private reportService: ReportService,
                private dialog: MatDialog,
                private jhiAlertService: AlertService,
                private spinner: NgxSpinnerService,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.dataSource.data = [];
        this.loadData(false);
    }

    private loadData(created: boolean): void {
        this.spinner.show();
        if (created) {
            this.dataSource.data = [];
            this.page = 0;
        }
        this.reportService.findReportArchives(this.page, this.pageLength).subscribe((response: HttpResponse<ReportArchive[]>) => {
            if (response.body) {
                this.dataSource.data = this.dataSource.data.concat(response.body);
            }
            this.spinner.hide();
        });
    }

    onTableScroll(event) {
        if (event.target.scrollTop === event.target.scrollHeight - event.target.offsetHeight) {
            this.page += 1;
            this.loadData(false);
        }
    }

    public generate(): void {
        this.dialog.open(ReportArchiveDialog, {
            data: new ReportArchive()
        }).afterClosed().subscribe((data: ReportArchive) => {
            if (data) {
                this.reportService.addReportArchive(data).subscribe(() => {
                    this.jhiAlertService.success('report.archive.created');
                    this.loadData(true);
                });
            }
        });
    }

    public getIconBySelectedOptions(reportArchive: ReportArchive, option: ReportArchiveOption): string {
        return reportArchive.selectedOptions && reportArchive.selectedOptions.includes(option) ? 'fa fa-check-square current-color' : 'fa fa-square-o';
    }

    public getIconForDownloadButton(reportArchive: ReportArchive): string {
        return reportArchive.downloading ? 'fa fa-spinner fa-spin' : 'fa fa-download';
    }

    public getDirectoriesInfo(reportArchive: ReportArchive): string {
        if(!reportArchive.selectedOptions.includes(ReportArchiveOption.DOCUMENTS)) {
            return "";
        }
        if (reportArchive.directories && reportArchive.directories.length > 0) {
            let result = this.translateService.instant('report.archive.directories.selected');
            reportArchive.directories.forEach(directory => result += '\n' + directory.name);
            return  result;
        } else {
            return this.translateService.instant('report.archive.directories.all');
        }
    }

    public downloadArchive(reportArchive: ReportArchive) {
        reportArchive.downloading = true;
        this.jhiAlertService.success('report.archive.downloading');
        this.reportService.downloadURI(SERVER_API_URL + 'api/archive/download/' + reportArchive.id, 'archive' + reportArchive.id);
        setTimeout(() => {
            reportArchive.downloading = false;
        }, 5000);
    }

    public isNotFinishedOrDownloading(reportArchive: ReportArchive) {
        return reportArchive.reportArchiveState !== 'FINISHED' || reportArchive.downloading;
    }
}
