import {Injectable} from '@angular/core';
import {SERVER_API_URL} from '../../app.constants';
import {Observable} from 'rxjs';
import {Client} from './client.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable()
export class ClientService {

    private resourceUrl =  SERVER_API_URL + 'api/clients';

    constructor(private httpClient: HttpClient) { }

    public findAll(): Observable<HttpResponse<Client[]>> {
        return this.httpClient.get<Client[]>(this.resourceUrl, {observe: 'response'});
    }

    public findActive(): Observable<HttpResponse<Client[]>> {
        return this.httpClient.get<Client[]>(this.resourceUrl + '/active', {observe: 'response'});
    }

    public updateClient(client: Client): Observable<HttpResponse<Client>>{
        return this.httpClient.put<Client>(this.resourceUrl, client, { observe: 'response'});
    }
}
