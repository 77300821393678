import { Injectable } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';
import { filter, share } from 'rxjs/operators';

export interface EventWithContent<T> {
    name: string;
    content: T;
}

/**
 * An utility class for Angular 18 to manage RxJS events.
 * Based on the previous JhiEventManager from ng-jhipster
 */
@Injectable({
    providedIn: 'root'
})
export class EventManager {
    observable: Observable<EventWithContent<any>>;
    observer: Observer<EventWithContent<any>> | null = null;

    constructor() {
        this.observable = new Observable((observer: Observer<EventWithContent<any>>) => {
            this.observer = observer;
        }).pipe(share());
    }

    /**
     * Method to broadcast the event to observer
     */
    broadcast<T>(event: any): void {
        if (this.observer) {
            this.observer.next(event);
        }
    }

    /**
     * Method to subscribe to an event with callback
     */
    subscribe<T>(eventName: string, callback: (event: T) => void): Subscription {
        const subscriber = this.observable
            .pipe(
                filter((event: EventWithContent<any>) => {
                    return event.name === eventName;
                })
            )
            .subscribe((event) => {
                callback(event.content);
            });
        return subscriber;
    }

    /**
     * Method to unsubscribe the subscription
     */
    destroy(subscriber: Subscription): void {
        subscriber.unsubscribe();
    }
}
