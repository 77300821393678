import {AfterViewChecked, Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { ProjectUser, User, UserService} from '../shared';
import {Account, LoginModalService, Principal, IconService} from '../shared';
import {Router} from '@angular/router';
import {DashboardService} from '../admin/dashboard/dashboard.service';
import {Dashboard} from '../admin/dashboard/dashboard.model';
import {UIChart} from 'primeng/chart';
import {DocumentVdrService} from '../entities/document-vdr';
import {ReportService} from '../report';
import {DocumentSearchRowDTO} from '../entities/directory-document/dto/document-search-row-dto.model';
import {QuestionService} from '../entities/question/question.service';
import {AnswerService} from '../entities/answer/answer.service';
import {Answer} from '../entities/answer/answer.model';
import {QuestionListElement} from '../entities/question/question.model';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { SERVER_API_URL } from '../app.constants';
import {BehaviorSubject, interval, Observable, Subscription, of} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {delay} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {EventManager} from "@shared/alert/event-manager.service";
declare const $: any;

@Component({
    selector: 'jhi-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.scss']

})
export class HomeComponent implements OnInit, AfterViewInit, AfterViewChecked  {
    account: Account;
    modalRef: NgbModalRef;
    dashboards: Dashboard[];
    usersData: any;
    usersChartOptions: any;
    qaChartOptions: any;
    projectUsers: ProjectUser[];
   groupData: any;
   groupChartOptions: any;
   projectInfoHours = 24;
    @ViewChild('userChart', { static: true }) usersChart: UIChart;

    latestDocuments: DocumentSearchRowDTO[] = [];
    mostViewedDocuments: {name: string, count: number}[] = [];
    removedDocuments: DocumentSearchRowDTO[];
    questions: QuestionListElement[];
    answers: Answer[];
    projectInfo: ProjectInfo;

    constructor(private principal: Principal,
                private loginModalService: LoginModalService,
                private eventManager: EventManager,
                private router: Router,
                private dashboardService: DashboardService,
                private userService: UserService,
                private documentVdrService: DocumentVdrService,
                private reportService: ReportService,
                private questionService: QuestionService,
                private answerService: AnswerService,
                private http: HttpClient,
                private spinner: NgxSpinnerService,
                private translateService: TranslateService
    ) {



                 this.usersChartOptions = {
                            showTooltips: false,
                            plugins: {
                                legend: {
                                            display: false
                                         }
                                     }
                        };

                 this.qaChartOptions = {
                                        indexAxis: 'y',
                                        showTooltips: false,
                                          plugins: {
                                        legend: {
                                                    display: false
                                                 }
                                              }
                                    };

    }

    ngOnInit() {
        this.spinner.show();
         of(true).pipe(delay(1000)).subscribe(() =>   this.spinner.hide());
       this.dashboardService.getActive().subscribe(dashboards => this.dashboards = dashboards);
        this.initUserChart();
        this.initLastDocuments();
        this.initMostViewedDocuments();
        this.initLastRemovedDocuments();
        this.initQA();
        this.initLastQuestions();
        this.initLastAnswers();
        this.initProjectInfo();
    }

    projectInfoHoursChange() {
        this.initProjectInfo();
    }

    ngAfterViewInit() {
        this.move();
          /*setTimeout(function () {
                     alert('timeout');
                     this.usersChart.refresh();
                     alert('postInit');
                     }, 1500);
*/
    }

    registerAuthenticationSuccess() {
        this.eventManager.subscribe('authenticationSuccess', (message) => {
            this.principal.identity().then((account) => {
                this.account = account;
            });
        });
    }

    isAuthenticated() {
        return this.principal.isAuthenticated();
    }

    initProjectInfo() {
        return this.http.get<ProjectInfo>(SERVER_API_URL+'api/home/projectInfo?hours='+this.projectInfoHours).subscribe(res=>{
                   this.projectInfo = res;
                   });
    }

      private initLastDocuments(){
       this.documentVdrService.getLastUploadedDocuments().subscribe((res) => {
        this.latestDocuments = res;
       });
      }


    private initMostViewedDocuments() {
     this.documentVdrService.getMostViewedDocuments().subscribe((res) => {
            this.mostViewedDocuments = res;
           });
    }


    private initLastRemovedDocuments() {
     this.documentVdrService.getLastRemovedDocuments().subscribe((res) => {
            this.removedDocuments = res;
           });
    }

       private initLastQuestions() {
         this.questionService.getLastQuestions().subscribe((res) => {
                this.questions = res;
               });
        }

           private initLastAnswers() {
             this.answerService.getLastAnswers().subscribe((res) => {
                            this.answers = res;
                           });
            }

    private initUserChart(){
        this.userService.findProjectUsers().subscribe((res) => {
        const projectUsers = res.body;

        const allUsers = projectUsers.length;

        const loggedUsers = projectUsers.filter((pu) => pu.hasActiveSession).length;
        const invitedUsers = projectUsers.filter((pu) => pu.userFullName === 'null null').length;
        const registeredUsers = allUsers - invitedUsers;
        this.usersData = {
                        labels: [this.translateService.instant('report.overview.users-chart.invited'),
                        this.translateService.instant('entity.common.registered'),
                        this.translateService.instant('report.overview.users-chart.logged')],
                        datasets: [
                            {
                                label: '',
                                data: [invitedUsers,registeredUsers,loggedUsers],
                                backgroundColor: [
                                    "#42A5F5",
                                    "rgba(174, 234, 0, 0.5)",
                                    "darkred"
                                ],
                                hoverBackgroundColor: [
                                    "##42A5F5",
                                    "rgba(174, 234, 0, 0.5)",
                                    "#darkred"
                                ]
                            }]
                        };
        });
    }


    private initQA() {
        this.reportService.findQAReport().subscribe(groups =>{
         this.groupData = {
                                        labels: groups.map(group=>group.groupName),
                                        datasets: [
                                            {
                                                label: '',
                                                data: groups.map(group=>group.posts + group.threads),
                                                backgroundColor:
                                                    groups.map(group=>'darkred')

                                            }]
                                        };
                                                console.log(this.groupData);
                                        });
    }

    private move() {

                this.principal.identity().then((account) => {
                    this.account = account;

                   $(document).on('DOMNodeInserted', function(e) {
                                     if ( $(e.target).hasClass('showMoreAnchor') ) {
                                       $('.showMoreAnchor').click(function(){
                                           $(this).closest('.news-panel').find('.more-content').removeClass('d-none');
                                           $(this).closest('.news-panel').find('.showMoreAnchor').hide();
                                       });
                                     }
                                 });
                });

    }




       ngAfterViewChecked() {
        }

}


interface ProjectInfo {
    loggedUsers: number;
    addedDocuments: number;
    answerCount: number;
    questionCount: number;
    addedDirectory: number;
    downloadFiles: number;

}
