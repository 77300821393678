import { Routes } from '@angular/router';

import { HelpComponent } from './help.component';
import {ExcelViewerComponent} from '../excel/excel.viewer.component';
import {ExcelDialogRouteComponent} from '../excel/excel.viewer.dialog.component';

import {WordViewerComponent} from '../word/word.viewer.component';
import {WordDialogRouteComponent} from '../word/word.viewer.dialog.component';
export const helpRoute: Routes = [
    {
        path: 'help',
        component: HelpComponent,
        data: {
            authorities: [],
            pageTitle: 'help.title'
        },
    },

];
