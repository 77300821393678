export class DomainUrl {
    protocol: string;
    domainHost: string;
    private port: string;

    constructor(baseUrl) {
        const parser = document.createElement('a');
        parser.href = baseUrl;
        this.domainHost = parser.hostname;
        this.protocol = parser.protocol;
        this.port = parser.port;
    }

    findPortPostfix() {
        return (this.port ? ':' + this.port : '');
    }

    findSubdomainUrl(subdomain: string): string {
        return this.protocol + '//' + subdomain + '.' + this.domainHost + this.findPortPostfix();
    }

    findDomainUrl(): string {
        return this.protocol + '//' + this.domainHost + this.findPortPostfix();
    }

    redirectToSubdomain(subdomain: string) {
        const subdomainUrl = this.findSubdomainUrl(subdomain) + '/#/project-vdr';
        console.log('redirectToSubdomain:' + subdomainUrl);
        window.location.replace(subdomainUrl);
    }

    isSubdomain(): boolean {
        return document.URL.indexOf(this.findDomainUrl()) === -1 && document.URL.indexOf(this.domainHost) > -1;
    }

    findSubdomainFromUrl(): string {

        if (this.isSubdomain()) {
            const currentHost = new DomainUrl(document.URL).domainHost;
            const subdomain = currentHost.substr(0, currentHost.indexOf('.'));
            return subdomain;
        }

        return null;

    }
}
