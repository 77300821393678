import {Component, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';

import {JhiLanguageHelper} from '../../shared';
import {JhiAlertService}from '@upside-cloud/ng-jhipster';
import {BackendUnavailableInterceptor} from '../../blocks/interceptor/backend-unavailable.interceptor';
import {TranslateService} from '@ngx-translate/core';
import { loadCldr, setCulture } from '@syncfusion/ej2-base';

import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';
import {EventManager} from "@shared/alert/event-manager.service";
import {AlertService} from "@shared/alert/alert.service";

@Component({
    selector: 'jhi-main',
    styleUrls: ['main.component.scss'],
    templateUrl: './main.component.html'
})



export class MainComponent implements OnInit {
    connectionLost = false;

    constructor(
        private jhiLanguageHelper: JhiLanguageHelper,
        private router: Router,
        private eventManager: EventManager,
        private alertService: AlertService,
        private translateService: TranslateService,

    ) {
    }

    private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
        let title: string = (routeSnapshot.data && routeSnapshot.data['pageTitle']) ? routeSnapshot.data['pageTitle'] : 'vdrApp';
        if (routeSnapshot.firstChild) {
            title = this.getPageTitle(routeSnapshot.firstChild) || title;
        }
        return title;
    }

    ngOnInit() {
        this.translateService.setDefaultLang('pl');
        setCulture('pl');
        this.eventManager.subscribe(BackendUnavailableInterceptor.eventNameBackendUnavailable, () => this.connectionLost = true);
        this.eventManager.subscribe(BackendUnavailableInterceptor.eventNameBackendAvailable, () => {
            this.alertService.success('Połączono z VDR');
            return this.connectionLost = false;
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.jhiLanguageHelper.updateTitle(this.getPageTitle(this.router.routerState.snapshot.root));
            }
        });
    }
}
