import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable()
export class IconService {
    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer) {

    }

    init() {
        this.initSvgIcons();
        this.initNavbarIcons();
    }

    private initSvgIcons() {
        this.initSvgIcon('dealdone_icon_23x29', 'content/images/public/dealdone_icon_23x29.svg');
        this.initSvgIcon('excel', 'content/images/public/icons/excel.svg');
        this.initSvgIcon('dealdone_icon_disabled_23x29', 'content/images/public/dealdone_icon_disabled_23x29.svg');
        this.initSvgIcon('dealdone_icon_18x23', 'content/images/public/dealdone_icon_18x23.svg');
        this.initSvgIcon('dealdone_icon_disabled_18x23', 'content/images/public/dealdone_icon_disabled_18x23.svg');
    }


    private initNavbarIcons() {
        this.initSvgIcon('nav_documents', 'content/images/public/navbar/Dokumenty.svg');
        this.initSvgIcon('nav_help', 'content/images/public/navbar/Pomoc.svg');
        this.initSvgIcon('nav_reports', 'content/images/public/navbar/Raporty.svg');
        this.initSvgIcon('nav_users', 'content/images/public/navbar/Users.svg');
        this.initSvgIcon('nav_settings', 'content/images/public/navbar/Ustawienia.svg');
        this.initSvgIcon('nav_chat', 'content/images/public/navbar/question.svg');

        this.initSvgIcon('dashboard', 'content/images/public/navbar/dashboard.svg');
        this.initSvgIcon('news', 'content/images/public/navbar/news.svg');
        this.initSvgIcon('answer', 'content/images/public/navbar/answer.svg');
        this.initSvgIcon('question', 'content/images/public/navbar/question.svg');
        this.initSvgIcon('start', 'content/images/public/navbar/start.svg');
    }

    public initSvgIcon(iconName: string, value: string) {
        this.iconRegistry.addSvgIcon(iconName, this.sanitizer.bypassSecurityTrustResourceUrl(value));
    }
}




