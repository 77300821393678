import {tap} from 'rxjs/operators';
import {JhiEventManager}from '@upside-cloud/ng-jhipster';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import {Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {AlertService} from "@shared/alert/alert.service";
import {EventManager} from "@shared/alert/event-manager.service";

export class NotificationInterceptor implements HttpInterceptor {

    // tslint:disable-next-line: no-unused-variable
    constructor(private injector: Injector, private alertService: AlertService) {
        setTimeout(() => this.alertService = injector.get(AlertService));
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {

                // notify timer to restart
                const eventManager: JhiEventManager = this.injector.get(EventManager);
                console.log('interc1');
                eventManager.broadcast({
                    name: 'sessionTimeoutRefresh',
                    content: 'Session refresh'
                });
                console.log('interc2');
                const arr = event.headers.keys();
                let alert = null;
                let alertParams = null;
                arr.forEach((entry) => {
                    if (entry.endsWith('app-alert')) {
                        alert = event.headers.get(entry);
                    } else if (entry.endsWith('app-params')) {
                        alertParams = event.headers.get(entry);
                    }
                });
                if (alert) {
                    if (typeof alert === 'string') {
                        if (this.alertService) {
                            const alertParam = alertParams;
                            this.alertService.success(alert, {param: alertParam}, null);
                        }
                    }
                }
            }
        }, (err: any) => {
        }));
    }
}
