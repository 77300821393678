import {NgModule} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgJhipsterModule} from '@upside-cloud/ng-jhipster';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {CookieModule} from 'ngx-cookie';
import {faSortAmountUp, faSortAmountDown, faSort} from '@fortawesome/free-solid-svg-icons';
import {HttpCacheInterceptorModule} from '@ngneat/cashew';

@NgModule({
    imports: [
        NgJhipsterModule.forRoot({
            // set below to true to make alerts look like toast
            alertAsToast: true,
            i18nEnabled: true,
            defaultI18nLang: 'pl',
            alertTimeout: 3500,
            sortAscIcon: faSortAmountUp,
            sortDescIcon: faSortAmountDown,
            sortIcon: faSort
        }),
        InfiniteScrollModule,
        CookieModule.forRoot(),
        HttpCacheInterceptorModule.forRoot({
            ttl: 120 * 1000,

        })
    ],
    exports: [
        FormsModule,
        HttpClientModule,
        CommonModule,
        NgJhipsterModule,
        InfiniteScrollModule
    ]
})
export class VdrSharedLibsModule {
}
