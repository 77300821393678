// dialog-route.component.ts
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {ExcelViewerComponent} from './excel.viewer.component';
import { CommonModule } from '@angular/common';
import {HttpCacheManager} from '@ngneat/cashew';

@Component({
  template: '' // This component doesn't need a template
})
export class ExcelDialogRouteComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private httpCacheManager: HttpCacheManager
  ) {}

  ngOnInit() {

    const dialogRef = this.dialog.open(ExcelViewerComponent, {
                   width: '100%',
                         height: '100%',
                         minHeight: '100%',
                         maxWidth: '100vw',
                         panelClass: ['pdf-viewer-dialog','excel-viewer'],
                         autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
        if(result.redirectUrl){
            this.httpCacheManager.clear();
            const url = result.redirectUrl.split('/').slice(0, -1).join('/');
            console.log(url);
            this.router.navigateByUrl(url);
        } else {
            this.router.navigate(['/files'], {
                queryParams: {documentId: result.id}
            });
        }
    });
  }
}
