<div style="margin-top: 70px; height: 100%; margin-top:-74px !important">
    <div class="home-logo" >
        <span>Witaj ponownie23!</span>
        <div class="brightened-right"></div>
    </div>

    <div class="d-flex" style="overflow-x: hidden">
        <div class="p-3 dashboard-news">
            <h1 class="dashboard-header">  <mat-icon class="icon" svgIcon="news" style="line-height: 36px;"></mat-icon>News
            </h1>
            <mat-divider style="margin-bottom: 30px;"></mat-divider>
            <div class="news-container">
                <div *ngFor="let dashboard of dashboards" class="new-area">
                    <div class="news-panel dashboard-panel" [ngClass]="{'hide-show-more': !dashboard.moreInfo}">
                        <div id="news-content" class="news-content" [innerHTML]="dashboard.lead"></div>
                        <!--
                                        <a id="showMore" class="showMore" *ngIf="!dashboard.isShowMore && dashboard.moreInfo" (click)="dashboard.isShowMore = true">Pokaż <więcej class="."></więcej></a>
                        -->
                        <div id="more-content" class="d-none more-content" [innerHTML]="dashboard.moreInfo">abc</div>
                    </div>
                    <div class="date">{{dashboard.date | date:'yyyy-MM-dd'}}</div>
                    <mat-divider style="margin-bottom: 10px;"></mat-divider>
                </div>
            </div>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <div class="middle-panel" style="width: 60%">
            <div class="d-flex">
                <div class="d-flex flex-column" style="width:50%">
                    <div class="dashboard-panel dashboard-users">
                        <p style="font-weight:bold; text-align: center; font-size: 14px;margin-bottom: 25px"><span
                            class="fa fa-user mr-2"></span><span jhiTranslate="vdrApp.group.users"></span></p>
                        <div class="mt-2">
                            <p-chart [height]="250" *ngIf="usersData" type="bar" [data]="usersData" [options]="usersChartOptions" class="content-font"></p-chart>
                        </div>
                    </div>

                    <div class="dashboard-panel project-panel dashboard-view-files">
                        <p style="font-weight:bold; text-align: center; font-size: 14px; margin-bottom: 25px"><span
                            class="fa fa-file mr-2"></span><span jhiTranslate="dashboard.mostViewedFiles"></span></p>
                        <div class="dashboard-row" *ngFor="let document of mostViewedDocuments">
                            <div class="dashboard-key"><i class="fa fa-file-pdf-o ng-star-inserted mr-2"
                                                          style="font-size: 18px"></i>{{document.name}}
                            </div>
                            <div class="dashboard-value">{{document.count}}</div>
                        </div>

                    </div>

                </div>
                <div class="dashboard-panel project-panel dashboard-upload-files" style="width: 50%;min-height: 506px;">
                    <p style="font-weight:bold; text-align: center; font-size: 14px; margin-bottom: 25px"><span
                        class="fa fa-upload mr-2"></span><span jhiTranslate="dashboard.recentlyUploadedFiles"></span></p>
                    <div *ngFor="let document of latestDocuments" class="dashboard-row"><i class="fa fa-file-pdf-o ng-star-inserted mr-2" style="font-size: 18px"></i>
                        <div class="file-info">
                            <div class="dashboard-key">{{document.fileName}}</div>
                            <div class="small-info">{{document.directoryPath}}</div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="w-100">
                <div class="dashboard-panel dashboard-users">
                    <p style="font-weight:bold; text-align: center; font-size: 14px;margin-bottom: 25px"><span
                        class="fa fa-user mr-2"></span><span jhiTranslate="dashboard.mostActiveGroups"></span></p>
                    <div class="mt-2" *ngIf="groupData">
                        <p-chart  height="200" type="bar" [data]="groupData" [options]="qaChartOptions" class="content-font"></p-chart>
                    </div>
                </div>
            </div>

            <div class="d-flex" style="min-height: 221px;">
                <div class="dashboard-panel project-panel" style="width: 50%">
                    <p style="font-weight:bold; text-align: center; font-size: 14px; margin-bottom: 25px"><span
                        class="fa fa-trash mr-2"></span><span jhiTranslate="dashboard.recentlyDeletedFiles"></span></p>
                    <div class="d-flex flex-column" *ngIf="removedDocuments">
                           <div *ngFor="let removedDocument of removedDocuments" class="mt-1 mb-1"><i class="fa fa-file-pdf-o ng-star-inserted mr-3"
                                   style="font-size: 18px"></i>{{removedDocument.fileName}}</div>

                       </div>
                </div>
                <div class="dashboard-panel project-panel" style="width: 50%" *ngIf="projectInfo">
                    <div style="font-weight:bold; text-align: center; font-size: 14px; margin-bottom: 25px">
                        <div class="d-flex">
                            <span class="fa fa-home mr-2 pt-1"></span>
                            <span jhiTranslate="dashboard.projectActivities"></span>
                        </div>
                        <mat-button-toggle-group class="project-toggle-group" name="fontStyle" aria-label="Font Style" [(ngModel)]="projectInfoHours" (change)="projectInfoHoursChange($event)">
                            <mat-button-toggle [value]="24">24h</mat-button-toggle>
                            <mat-button-toggle [value]="24*7">7 <span jhiTranslate="entity.common.days"></span></mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div>
                        <div><span jhiTranslate="dashboard.addedDocuments"></span></div>
                        <div>{{projectInfo.addedDocuments}}</div>
                    </div>
                    <mat-divider></mat-divider>
                    <div>
                        <div><span jhiTranslate="dashboard.createdFolders"></span></div>
                        <div>{{projectInfo.addedDirectory}}</div>
                    </div>
                    <mat-divider></mat-divider>
                    <div>
                        <div><span jhiTranslate="dashboard.createdQuestionsQnA"></span></div>
                        <div>{{projectInfo.questionCount}}</div>
                    </div>
                    <mat-divider></mat-divider>
                    <div>
                        <div><span jhiTranslate="dashboard.createdAnswersQnA"></span></div>
                        <div>{{projectInfo.answerCount}}</div>
                    </div>
                    <mat-divider></mat-divider>
                    <div>
                        <div><span jhiTranslate="dashboard.systemLogins"></span></div>
                        <div>{{projectInfo.loggedUsers}}</div>
                    </div>
                    <mat-divider></mat-divider>
                    <div>
                        <div><span jhiTranslate="dashboard.downloadedFiles"></span></div>
                        <div>{{projectInfo.downloadFiles}}</div>
                    </div>
                </div>
            </div>

            <div class="d-flex" style="min-height: 321px;">
                <div class="min-width-50">
                    <div class="dashboard-panel project-panel">
                    <p style="font-weight:bold; text-align: center; font-size: 14px; margin-bottom: 25px">
                        <mat-icon class="icon" svgIcon="question" style="line-height: 24px;font-size:14px;width: 14px; color: black"></mat-icon>
                        <span style="line-height: 30px"><span jhiTranslate="dashboard.recentlyAddedQuestions"></span></span></p>
                    <div class="d-flex flex-column">
                        <div *ngFor="let question of questions">
                            <mat-divider class="mt-2"></mat-divider>
                            <div class="d-flex justify-content-between">
                                <div class="qa-row">
                                    <div class="mr-2 mt-2">
                                        <i *ngIf="!question.avatarBytes" _ngcontent-arv-c543="" class="fa fa-fw fa-user-circle ng-tns-c543-37 ng-star-inserted" style="font-size: 40px; opacity: 0.5;"></i>

                                        <div *ngIf="question.avatarBytes" class="image-cropper  mt-0"
                                             style="   width:  40px;   height:  40px;margin:inherit; margin-left:14px;">
                                            <img class="profile-pic avatar" style="width: 70px;"
                                                 [src]="'data:image/jpeg;base64,' + question.avatarBytes"
                                                 alt="file image"/>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-column" style="align-self: center">
                                        <div>{{question.authorName}}</div>
                                        <div class="qa-content">{{question.title}}</div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column" style="align-self: center">
                                    <div style="font-size: 9px">{{question.createdDate| date: 'yyyy-MM-dd HH:mm'}}</div>
                                    <div class="dashboard-forum-status dashboard-value" style="margin-top:2px"
                                         [ngClass]="{'resolved' : question.isResolved(), 'pending': question.status === 'PENDING', 'new': question.status === 'WAITING_FOR_ANSWER'}">
                                        <span
                                        jhiTranslate="{{'vdrApp.QuestionStatus.' + question.status}}">{{question.status}}</span></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                </div>
                <div class="min-width-50">
                    <div class="dashboard-panel project-panel" >
                    <p style="font-weight:bold; text-align: center; font-size: 14px; margin-bottom: 25px">
                        <mat-icon class="icon" svgIcon="answer" style="line-height: 24px;font-size:14px;width: 14px; color: black"></mat-icon>
                        <span style="line-height:30px;"><span jhiTranslate="dashboard.recentlyAddedAnswers"></span></span>
                    </p>
                    <div class="d-flex flex-column mt-1">
                        <div *ngFor="let answer of answers" >
                            <mat-divider class="mt-2 mb-2"></mat-divider>
                            <div class="d-flex justify-content-between">
                                <div class="d-flex" style="max-width: 60%; overflow: hidden;" >
                                    <div>
                                        <i *ngIf="!answer.avatarBytes" class="fa fa-fw fa-user-circle ng-tns-c543-37 ng-star-inserted" style="font-size: 40px; opacity: 0.5;"></i>

                                        <div *ngIf="answer.avatarBytes" class="image-cropper"
                                             style="   width:  40px;    height:  40px;margin:inherit; margin-right:14px;min-width:40px;">
                                            <img class="profile-pic avatar" style="width: 70px;"
                                                 [src]="'data:image/jpeg;base64,' + answer.avatarBytes"
                                                 alt="file image"/>
                                    </div>
                                    </div>
                                    <div class="d-flex flex-column" style="align-self: center">
                                        <div>{{answer.authorName}}</div>
                                        <div style="font-size: 9px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;max-height: 16px;"><span [innerHtml] = answer.content></span></div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column" style="align-self: center">
                                    <div style="font-size: 9px; width: 80px;">{{answer.createdDate| date: 'yyyy-MM-dd HH:mm'}}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                </div>
            </div>

        </div>


    </div>

</div>
