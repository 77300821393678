<div fxLayout="column" style="background-color: #efeff2; height: 100%">
    <div class="button-row" fxLayout="row" fxFlexAlign="start center">
        <button
            mat-raised-button
            style="min-width: 70px"
            [matMenuTriggerFor]="filterMenu"
            [disabled]="true">
            <mat-icon>filter_list</mat-icon>
            <span jhiTranslate="document.filter.title">Filter</span>
        </button>
        <div fxLayout="row">
            <div fxLayout="column">
                <mat-form-field class="search-input-type" *ngIf="false">
                    <mat-select [(ngModel)]="documentSearchComponent.documentSearchRowCriteriaDTO.searchIn"
                                placeholder="{{'document.filter.search-title' | translate}}" [disabled]="true">
                        <mat-option [value]="SearchIn[SearchIn.TITLES]">
                            <span jhiTranslate="document.filter.search-by-title">by title</span>
                        </mat-option>
                        <mat-option [value]="SearchIn[SearchIn.CONTENT]">
                            <span jhiTranslate="document.filter.search-by-content">by content</span>
                        </mat-option>
                        <mat-option [value]="SearchIn[SearchIn.TITLES_AND_CONTENT]">
                            <span jhiTranslate="document.filter.search-by-title-and-content">by title and content</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <mat-menu #filterMenu="matMenu" class="directory-tree-text" style="width: 220px">
            <div style="width: 220px" fxLayout="column" fxLayoutAlign="center" *ngIf="documentCriteriaDtoTmp"
                 (click)="$event.stopPropagation();">
                <div class="filter-item" fxLayout="column">
                    <mat-radio-group [(ngModel)]="documentCriteriaDtoTmp.read" class="mat-radio-group-wrap">
                        <mat-radio-button [value]="null" (click)="$event.stopPropagation()" color="primary"
                                          class="d-block">
                            <span jhiTranslate="document.filter.all">All</span>
                        </mat-radio-button>
                        <mat-radio-button [value]="false" (click)="$event.stopPropagation()" color="primary"
                                          class="d-block">
                            <span jhiTranslate="document.filter.unread">Unread</span>
                        </mat-radio-button>
                        <mat-radio-button [value]="true" (click)="$event.stopPropagation()" color="primary"
                                          class="d-block">
                            <span jhiTranslate="document.filter.read">Read</span>
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <mat-divider></mat-divider>
                <div class="filter-item mt-2" fxLayout="column">
                    <mat-form-field>
                        <input
                            name="createdFrom"
                            matInput
                            [matDatepicker]="createdFromPicker"
                            [(ngModel)]="documentCriteriaDtoTmp.createdFrom"
                            placeholder="{{'document.filter.created-from' | translate}}"
                            (click)="$event.stopPropagation();">
                        <mat-datepicker-toggle matSuffix [for]="createdFromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #createdFromPicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            name="createdTo"
                            matInput
                            [matDatepicker]="createdToPicker"
                            [(ngModel)]="documentCriteriaDtoTmp.createdTo"
                            placeholder="{{'document.filter.created-to' | translate}}"
                            (click)="$event.stopPropagation();">
                        <mat-datepicker-toggle matSuffix [for]="createdToPicker"></mat-datepicker-toggle>
                        <mat-datepicker #createdToPicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div class="filter-item" fxLayout="column">
                    <label class="form-control-label" jhiTranslate="document.filter.file-format">File format</label>
                    <mat-selection-list
                        class="mb-2"
                        #documentTypeList
                        id="documentTypeList"
                        [(ngModel)]="documentCriteriaDtoTmp.documentTypes"
                        (click)="$event.stopPropagation();">
                        <mat-list-option
                            *ngFor="let documentType of documentTypesSelect"
                            [value]="documentType.key"
                            checkboxPosition="before">
                            {{documentType.value}}
                        </mat-list-option>
                    </mat-selection-list>
                </div>
                <mat-divider></mat-divider>
                <div class="filter-item mt-2" fxLayout="column">
                    <div align="right">
                        <button
                            mat-raised-button
                            color="primary">
                            <!--(click)="search()"> -->
                            <span jhiTranslate="document.filter.search">Search</span>
                        </button>
                    </div>
                </div>
            </div>
        </mat-menu>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" class="button-row" *ngIf="false">
    </div>
    <!--<jhi-document-search #documentSearchComponent (onDocumentSearch)="search()" [filterSearch]="hasFilters()"></jhi-document-search>-->
    <jhi-document-search #documentSearchComponent [filterSearch]="hasFilters()"></jhi-document-search>
    <div *ngIf="!documentSearchComponent.hasSearchResults">
        <div fxLayout="row" class="button-row">
            <div fxFlexAlign="start" fxFlex="60%">
                <button mat-raised-button color="primary" *ngIf="infectedMode" (click)="infectedModeOff()"><span
                    jhiTranslate="document.showDocumentVersionOff">Return</span></button>
            </div>
            <div align="right" fxFlex="40%">
                <span *ngIf="nodeType==='DAMAGED'">
                    <span class="mr-3" jhiTranslate="documentManagement.documents"
                          [translateValues]="{quantity: dataSource.data.length}">
                    </span>
                    <button class="button-square"  mat-raised-button [disabled]="!uploadStorageAvailable()" (click)="uploadStorageTask()"
                            matTooltip="{{'documentManagement.action.uploadStorage' | translate}}"> <mat-icon>model_training</mat-icon></button>

                    <button *jhiHasAnyAuthority="'DOCUMENT_WRITE'"
                            mat-raised-button class="button-square"
                            (click)="infectedModeOn()"
                            [disabled]="infectedMode"
                            matTooltip="{{'documentManagement.infectedIcon' | translate}}">
                        <mat-icon>warning</mat-icon>
                    </button>


                      <button mat-raised-button class="button-square" (click)="synchronizeList()">
                    <mat-icon>sync</mat-icon>
                </button>
                </span>
                <span *ngIf="!(nodeType==='TRASH' || infectedMode)">
                        <button *jhiHasAnyAuthority="'DOCUMENT_WRITE'" mat-raised-button class="button-square"
                                (click)="deleteFiles()">
                            <i class="fa fa-trash " style="font-size: 18px"></i>
                        </button>
                    </span>

                <div style="display:inline" *ngIf="nodeType!=='DAMAGED'">
                    <button *jhiHasAnyAuthority="'DOCUMENT_WRITE'" mat-raised-button class="button-square"
                            [matMenuTriggerFor]="menu"  >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </div>
            </div>
            <mat-menu #menu="matMenu">
                <span *ngIf="nodeType==='DAMAGED' && infectedMode">
                    <button mat-menu-item [disabled]="!deleteInfectedAvailable()" (click)="deleteInfectedAction()"><span
                        jhiTranslate="documentManagement.action.deleteInfected">Usun z listy</span></button>
                </span>
                <span *ngIf="nodeType==='TRASH'">
                    <button mat-menu-item [disabled]="!restoreActionAvailable()" (click)="restoreAction()"><span
                        jhiTranslate="documentManagement.action.restore">Restore</span></button>
                    <button mat-menu-item [disabled]="!deletePermanentlyActionAvailable()"
                            (click)="deletePermanentlyDialog()"><span
                        jhiTranslate="documentManagement.action.deletePermanently">Delete permanently</span></button>
                </span>
            </mat-menu>

            <mat-menu #downloadMenu="matMenu">
                <button mat-menu-item [disabled]="!areFilesSelected()" (click)="downloadFiles('PDF')">PDF</button>
                <button mat-menu-item [disabled]="!areFilesSelected()" (click)="downloadFiles('ORIGINAL')"><span
                    jhiTranslate="document.original">Original</span></button>
                <button mat-menu-item [disabled]="!areFilesSelected()"
                        (click)="downloadFiles('PDF_PASSWORD_PROTECTED')"><span
                    jhiTranslate="document.pdfPasswordProtected">Zaszyfrowany PDF</span></button>
            </mat-menu>
        </div>
        <mat-divider></mat-divider>
        <div class="Aligner">
            <mat-table [dataSource]="dataSource" matSort style="width: 96%">

                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                                      [checked]="selection.hasValue() && isAllSelected()"
                                      [indeterminate]="selection.hasValue() && !isAllSelected()" color="primary">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                      (change)="$event ? selection.toggle(row) : null; onSelection(row)"
                                      [checked]="selection.isSelected(row)" color="primary" [disabled]="copyCutInProgress">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header style="max-width: 60px !important">
                        <span jhiTranslate="documentManagement.id">ID</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="max-width: 60px !important">
                        <span *ngIf="element.id">{{element.id}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef class="file-type-column"></mat-header-cell>
                    <mat-cell *matCellDef="let element" class="file-type-column">
                        <i *ngIf="element.type != 'directory'" class="{{getIcon(element.type)}}"
                           style="font-size: 18px"></i>
                        <i *ngIf="element.type === 'directory'"
                           [ngStyle]="{'color': getColor(element) }" [class]="'fa fa-folder'"
                           style="font-size: 18px">
                        </i>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header><span
                        jhiTranslate="documentManagement.name">NAME</span></mat-header-cell>
                    <mat-cell *matCellDef="let element" matTooltip="{{element.name | listDocumentName: true}}"
                              matTooltipPosition="below">
                        <span class="pdf-viewer-link">{{element.name | listDocumentName: false}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="fullPath">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <span jhiTranslate="documentManagement.fullPath">Full path</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" matTooltip="{{element.fullPath}}"  matTooltipClass="detail-tooltip">
                        <span>{{element.fullPath | listDocumentPath: false}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="permission">
                    <mat-header-cell *matHeaderCellDef style="justify-content:flex-end !important">
                        <span jhiTranslate="documentManagement.status">Status</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="justify-content:flex-end !important">
                        <span *ngIf="element.rowType === 'DOCUMENT'"
                              jhiTranslate="{{'status.' + element.status}}">{{element.status}}</span>
                        <span *ngIf="element.rowType === 'DIRECTORY'">{{element.status}}</span>
                        <span *ngIf="attacheDocumentEventHistory()">
                            <button
                                mat-icon-button
                                (click)="showDocumentEventHistory(element.id)"
                                class="hoverButton"
                                style="width: 20px"
                            >
                                <i class="fa fa-history " style="font-size: 18px" ></i>
                            </button>
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="createdDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header style="justify-content:flex-end !important">
                        <span jhiTranslate="documentManagement.createDate"></span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"
                              style="justify-content:flex-end !important"> {{element.createdDate | date:'dd.MM.yyyy'}}</mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;">
                    <!--(click)="selection.toggle(row); onSelection(row)"-->
                </mat-row>
            </mat-table>
        </div>
    </div>
</div>

