import {Component, Input} from '@angular/core';
import {DocumentVdrService} from '../document-vdr';
import {DocumentRowInfoDto} from './dto/document-row-info-dto.model';
import {DocumentNoteVdrService} from '../document-note-vdr/document-note-vdr.service';
import {DocumentNoteTypeEnum, DocumentNoteVdr} from '../document-note-vdr/document-note-vdr.model';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {JhiEventManager}from '@upside-cloud/ng-jhipster';
import {DocumentsRowInfoDto} from './dto/documents-row-indo-dto.model';
import {DirectoryInfoDto} from './dto/directory-info-dto.model';
import {DirectoryVdrService} from '../directory-vdr';
import {QuestionListElement, QuestionService} from '../question';
import {SelectedItem} from './document-list.component';
import {Principal} from '../../shared';
import {EventManager} from "@shared/alert/event-manager.service";

@Component({
    selector: 'jhi-document-note',
    templateUrl: 'document-note.component.html',
    styleUrls: ['directory-document.scss']
})
export class DocumentNoteComponent {
    _documentsIds: SelectedItem[];
    document: SelectedItem;
    fileDetails: DocumentRowInfoDto;
    filesDetails: DocumentsRowInfoDto;
    directiryDetails: DirectoryInfoDto;
    single = true;
    newDocumentNote: DocumentNoteVdr;
    documentQuestions: QuestionListElement[];
    noteTypePrivate = DocumentNoteTypeEnum.PRIVATE;
    noteTypePublic = DocumentNoteTypeEnum.PUBLIC;
    selectedTab: number;
    showNoteTab: boolean;

    @Input()
    set documentsIds(ids: SelectedItem[]) {
        this._documentsIds = ids;
        this.showNoteTab = false;
        if (this._documentsIds) {
            this.selectedTab = 0;
            this.filesDetails = null;
            this.fileDetails = null;
            this.directiryDetails = null;
            if (this._documentsIds.length === 1) {
                this.loadSingleDocumentDetails(this._documentsIds[0]);
                this.document = this._documentsIds[0];
                this.single = true;
                if (this.document.type === 'document' && !this.document.qaAttachment){
                    this.showNoteTab = true;
                }
            } else if (this._documentsIds.length > 1) {
                this.loadMultipleDocumentsDetails(this._documentsIds);
                this.single = false;
            }
        }
    }

    constructor(
        private documentService: DocumentVdrService,
        private documentNoteService: DocumentNoteVdrService,
        private eventManager: EventManager,
        private directoryService: DirectoryVdrService,
        private questionService: QuestionService,
        private principal: Principal
    ) {
        this.newDocumentNote = new DocumentNoteVdr();
        this.newDocumentNote.noteType = DocumentNoteTypeEnum.PRIVATE;
    }

    loadSingleDocumentDetails(document: SelectedItem) {
/*        this.filesDetails = null;
        this.fileDetails = null;
        this.directiryDetails = null;*/
        if (document.type === 'document') {
            this.processSingleDocument(document.id);
        } else {
            this.loadDirectoryDetails(document.id);
        }
    }

    processSingleDocument(id: number) {
        this.documentService.getDocumentNote(id).subscribe((value) => {
            this.fileDetails = value;
        });


        /*this.principal.hasAnyAuthority(['QA']).then((result) => {
            if (result) {
                this.questionService.findAllByDocument(id).subscribe((res) => {
                    this.documentQuestions = res;
                });
            }
        });*/

    }

    loadMultipleDocumentsDetails(documents: SelectedItem []) {
/*        this.fileDetails = null;
        this.documentQuestions = null;
        this.directiryDetails = null;*/
        const documentsIds: number[] = [];
        const directoryIds: number[] = [];
        documents.forEach((value) => {
            if (value.type === 'directory') {
                directoryIds.push(value.id);
            } else {
                documentsIds.push(value.id);
            }
        });
        if (documentsIds.length > 1) {
            this.documentService.getDocumentsNotes(documentsIds).subscribe( (res) => {
                this.filesDetails = res;
            });
        } else if (documentsIds.length === 1) {
            this.processSingleDocument(documentsIds[0]);
        }

        if (directoryIds.length > 1) {
            // ToDo pobranie szczegolow wielu zaznaczonych folderow
        } else if (directoryIds.length === 1) {
            this.loadDirectoryDetails(directoryIds[0]);
        }
    }

    loadDirectoryDetails(directoryId: number) {
        this.directoryService.directoryDetails(directoryId).subscribe((res) => {
            this.directiryDetails = res;
        });
    }

    newNoteSave() {
        this.newDocumentNote.documentId = this.document.id;
        this.documentNoteService.create(this.newDocumentNote).subscribe((res: HttpResponse<DocumentNoteVdr>) =>
            this.onSaveSuccess(res.body), (res: HttpErrorResponse) => this.onSaveError());
    }

    newNoteSaveEnabled(): boolean {
        return (this.newDocumentNote && this.newDocumentNote.noteType && this.newDocumentNote.note) ? true : false;
    }

    private onSaveSuccess(result: DocumentNoteVdr) {
        this.clearNewNote();
        this.loadSingleDocumentDetails(this.document);
    }

    private onSaveError() {
    }

    newNoteCancel() {
        this.clearNewNote();
    }

    private clearNewNote() {
        this.newDocumentNote.note = '';
        this.newDocumentNote.noteType = DocumentNoteTypeEnum.PRIVATE;
    }

    deleteNote(noteId: number) {
        this.documentNoteService.delete(noteId).subscribe((response) => {
            this.eventManager.broadcast({
                name: 'documentNoteListModification',
                content: 'Deleted an documentNote'
            });
            this.loadSingleDocumentDetails(this.document);
        });
    }

    noteTypeChange(value) {
        if (value.checked === true) {
            this.newDocumentNote.noteType = DocumentNoteTypeEnum.PRIVATE;
        } else {
            this.newDocumentNote.noteType = DocumentNoteTypeEnum.PUBLIC;
        }
    }

}
