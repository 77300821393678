import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {errorRoute, ExcelDialogRouteComponent, navbarRoute, WordDialogRouteComponent} from './layouts';

import {helpRoute} from './layouts/help/help.route';
import {Routes} from '@angular/router';

const editorRoute: Routes = [
    {
        path: 'excel',
        component: ExcelDialogRouteComponent,
        data: {
            authorities: [],
            pageTitle: 'error.title'
        },
    },
    {
        path: 'word',
        component: WordDialogRouteComponent,
        data: {
            authorities: [],
            pageTitle: 'error.title'
        },
    },
]

const LAYOUT_ROUTES = [
    navbarRoute,
    ...errorRoute,
    ...helpRoute,
    ...editorRoute,
];

@NgModule({
    imports: [
        RouterModule.forRoot(LAYOUT_ROUTES, {useHash: true, enableTracing: false})
    ],
    exports: [
        RouterModule
    ]
})
export class VdrAppRoutingModule {
}
