<form name="editForm" role="form" novalidate (ngSubmit)="save()" #editForm="ngForm" autocomplete="off">
    <div class="d-flex flex-row mt-3 new-room">


        <div class="pl-4 pr-4 new-project">
            <div>
                <h4 class="modal-title" id="myProjectLabel" jhiTranslate="vdrApp.newProject.newDataRoom">Nowy
                    pokój
                    danych</h4>


            </div>

            <mat-divider></mat-divider>

            <div class="d-flex flex-row justify-content-between">
                <div class="administrator projectColumn">
                    <h6>Administrator projektu</h6>
                    <div class="form-group">
                        <label class="form-control-label" jhiTranslate="vdrApp.newProject.email" for="field_email">E-mail</label>
                        <input type="text" class="form-control" #email="ngModel" name="description" id="field_email"
                               email required autocomplete="new-email"
                               [(ngModel)]="project.email"/>
                        <jhi-show-errors [control]="email"></jhi-show-errors>


                    </div>

                    <div class="form-group">
                        <label class="form-control-label" jhiTranslate="vdrApp.newProject.firstNameAndSurname"
                               for="field_firstname">Imię i nazwisko</label>
                        <input type="text" class="form-control" #surname="ngModel" name="surname" id="field_firstname"
                               required autocomplete="new-email"
                               [(ngModel)]="project.surname" minlength="4" maxlength="100"/>
                        <jhi-show-errors [control]="surname"></jhi-show-errors>

                    </div>

                    <!--<div class="form-group">
                        <label class="form-control-label" jhiTranslate="vdrApp.newProject.company"
                               for="field_company">Firma</label>
                        <input type="text" class="form-control" name="company" id="field_company" #company="ngModel"
                               [(ngModel)]="project.company" autocomplete="new-email2"/>
                    </div>-->

                    <div class="form-group">
                        <label class="form-control-label" jhiTranslate="vdrApp.newProject.company"
                               for="field_client">Firma</label>
                        <select class="form-control" name="client" id="field_client" [(ngModel)]="project.client">
                            <option *ngFor="let client of clients" [ngValue]="client">
                                {{client.name}}
                            </option>
                        </select>

                    </div>

                    <div class="form-group">
                        <label class="form-control-label"
                               for="field_client_name">lub dodaj nowego klienta</label>
                        <input class="form-control" type="text" id="field_client_name" [(ngModel)]="clientName"/>
                    </div>

                    <div class="form-group">
                        <label class="form-control-label" jhiTranslate="settings.form.phoneNumber">Numer
                            telefonu</label>
                        <app-phone-input
                            [disabled]="false"
                            [(ngModel)]="project.phone"
                            id="phoneNumber"
                        >
                            ></app-phone-input>
                    </div>

                </div>

                <div class="wid50 projectColumn">
                    <h6 jhiTranslate="vdrApp.newProject.basicSettings">Ustawienia podstawowe</h6>
                    <div>
                        <jhi-alert-error></jhi-alert-error>

                        <div class="form-group">
                            <label class="form-control-label" jhiTranslate="vdrApp.newProject.name"
                                   for="field_name">Nazwa projektu</label>
                            <input type="text" class="form-control" name="name" id="field_name" #name="ngModel"
                                   [(ngModel)]="project.name" required minlength="4" maxlength="100"
                                   autocomplete="new-email"/>
                            <jhi-show-errors [control]="name"></jhi-show-errors>
                        </div>

                        <div class="form-group">
                            <label class="form-control-label" jhiTranslate="vdrApp.newProject.address"
                                   for="field_subdomain">Adres projektu</label>
                            <input autocomplete="new-email" type="text" class="form-control" name="subdomain2"
                                   id="field_subdomain" required #subdomain="ngModel" pattern="[a-z0-9-]*" minlength="4"
                                   [(ngModel)]="project.www"
                            />
                            <jhi-show-errors [control]="subdomain"></jhi-show-errors>
                        </div>

                        <div class="form-group">
                            <label class="form-control-label" jhiTranslate="vdrApp.newProject.dealNo"
                                   for="field_deal">Numer umowy</label>
                            <input autocomplete="new-email" type="text" class="form-control" name="deal" id="field_deal"
                                   #deal="ngModel" required
                                   minlength="4" maxlength="100"
                                   [(ngModel)]="project.dealNo"/>
                            <jhi-show-errors [control]="deal"></jhi-show-errors>
                        </div>

                        <div class="form-group">
                            <label class="form-control-label" jhiTranslate="vdrApp.newProject.logo" for="field_bytes">Logo
                                projektu</label>
                            <div>
<!--                                <img [src]="'data:' + project.bytesContentType + ';base64,' + project.bytes"-->
<!--                                     style="max-height: 100px;" *ngIf="project.bytes" alt="file image"/>-->
                                <div class="form-text text-danger clearfix">
<!--                                    <span-->
<!--                                        class="pull-left">}</span>-->
                                    <button type="button"
                                            (click)="clearInputImage('bytes', 'bytesContentType', 'fileImage')"
                                            class="btn btn-secondary btn-xs pull-right">
                                        <span class="fa fa-times"></span>
                                    </button>
                                </div>
                                <input autocomplete="new-email" type="file" id="file_bytes"
                                       (change)="setFileData($event, project, 'bytes', true)"
                                       accept="image/*" jhiTranslate="entity.action.addimage"/>
                            </div>
<!--                            <input type="hidden" class="form-control" name="bytes" id="field_bytes"-->
<!--                                   [(ngModel)]="project.bytes"/>-->
<!--                            <input type="hidden" class="form-control" name="bytesContentType"-->
<!--                                   id="field_bytesContentType"-->
<!--                                   [(ngModel)]="project.bytesContentType"/>-->
                        </div>


                        <div class="modal-footer pr-0">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="clear()">
                                <span class="fa fa-ban"></span>&nbsp;<span
                                jhiTranslate="entity.action.cancel">Cancel</span>
                            </button>
                            <button type="submit" class="btn btn-primary" [disabled]="isSaving||editForm.form.invalid">
                                <span class="fa fa-save"></span>&nbsp;<span
                                jhiTranslate="entity.action.save">Save</span>
                            </button>
                        </div>


                    </div>
                </div>

            </div>
        </div>
    </div>
</form>
