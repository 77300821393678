import {Injectable} from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {Observable} from 'rxjs';

import {SERVER_API_URL} from '../../app.constants';

import {createRequestOption} from '../index';
import {Group} from './group.model';
import {map} from 'rxjs/operators';

@Injectable()
export class GroupService {
    private resourceUrl = SERVER_API_URL + 'api/groups';
    private projectGroupsResourceUrl = SERVER_API_URL + 'api/projectGroups';

    constructor(private http: HttpClient) {
    }

    create(userGroup: Group): Observable<HttpResponse<Group>> {
        return this.http.post<Group>(this.resourceUrl, userGroup, {observe: 'response'});
    }

    update(userGroup: Group): Observable<HttpResponse<Group>> {
        return this.http.put<Group>(this.resourceUrl, userGroup, {observe: 'response'});
    }

    find(id: string): Observable<HttpResponse<Group>> {
        return this.http.get<Group>(`${this.resourceUrl}/${id}`, {observe: 'response'});
    }

    getProjectGroups(active = true, forReports = false): Observable<HttpResponse<Group[]>>{
        const activeParam = active ? 'true' : 'false';
        const reportParam = forReports ? 'true' : 'false';
        return this.http.get<Group[]>(`${this.projectGroupsResourceUrl}/${activeParam}?qa=${reportParam}`, {
            observe: 'response'
        }).pipe(map((res: HttpResponse<Group[]>) => this.convertArrayResponse(res)));
    }

    delete(id: string): Observable<HttpResponse<any>> {
        return this.http.delete(`${this.resourceUrl}/${id}`, {observe: 'response'});
    }

    private convertArrayResponse(res: HttpResponse<Group[]>): HttpResponse<Group[]> {
        const jsonResponse: Group[] = res.body;
        const body: Group[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

    private convertItemFromServer(userGroup: Group): Group {
        return userGroup;
    }
}
